import React, { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import LazyLoad from 'react-lazy-load';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function CarouselComponent({
    images,
    showDots,
    autoPlay,
    infinite,
    height,
    currentIndex,
    isModal,
    showLoader = true,
    onChange
}) {
    const carouselRef = useRef();
    const [loadedImages, setLoadedImages] = useState([]);

    const responsive = {
        all: {
          breakpoint: { max: 4000, min: 0 },
          items: 1,
        },
    };

    useEffect(() => {
        if (carouselRef?.current && currentIndex !== undefined) {
            carouselRef?.current.goToSlide(currentIndex, true);
        }
    }, [currentIndex])

    const handleImageLoad = (index) => {
        setLoadedImages(current => {
            const loadedImagesAux = [...current];
            if (!loadedImagesAux.includes(index)) {
                loadedImagesAux.push(index);
            }

            return loadedImagesAux
        });
      };

    return (
        <div style={{position: 'relative', height: `${isModal ? 'auto' : height || '100%'}` }}>
            {isModal && <div className="image-counter">{currentIndex + 1} de {images.length}</div>}

            <LazyLoad offset={500}>
                <>
                    {showLoader && loadedImages.length < images?.length && (
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: height || '',
                            }}
                        >
                            <CircularProgress color="secondary" />
                        </Box>
                    )}
                    <Carousel
                        ref={carouselRef}
                        responsive={responsive}
                        className={`images-carousel ${loadedImages.length < images.length ? 'hidden' : ''}`}
                        infinite={infinite !== undefined ? infinite : true}
                        autoPlay={autoPlay !== undefined ? autoPlay : false}
                        autoPlaySpeed={4000}
                        partialVisbile={false}
                        showDots={showDots !== undefined ? showDots : true}
                        beforeChange={(nextSlide) => onChange && onChange(nextSlide)}
                    >
                        {images.map((image, i) => (
                            <Box
                                key={i}
                                className="carousel-item"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                style={{
                                    backgroundImage: `url('${image}')`,
                                    height: height || ''
                                }}
                            >
                                <img
                                    src={image}
                                    alt={`slider ${i}`}
                                    onLoad={() => handleImageLoad(i)}
                                    style={{ display: 'none' }}
                                />
                            </Box>
                        ))}
                    </Carousel>
                </>
            </LazyLoad>
        </div>
    );
}

export default CarouselComponent;
