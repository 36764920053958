import React from 'react';
import {  Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import IconCustom from '../common/IconCustom'
import { useLayoutContext } from '../../context/LayoutContext';
import { useClientData } from '../../context/ClientContext';
import Multibuscador from '../common/Multibuscador/Multibuscador';

function Navbar({ defaultModule, ...props }) {
  const { clientData } = useClientData();
  const location = useLocation();
  const { showModules } = useLayoutContext();

  const isSelected = (item) => {
    return (defaultModule && item.path.includes(defaultModule))
      || location.pathname.includes(item.path);
  }

  const getMultibuscador = () => {
    const selectedModule = clientData.modules.find(isSelected);
    if (selectedModule) {
      return <Grid className='search-container'>
        <Container>
          <Multibuscador module={selectedModule.module} {...props} />
        </Container>
      </Grid>
    }
  }

  return (

    <>
      {clientData?.modules && Array.isArray(clientData.modules) && clientData.modules.length > 1 && (
        <Accordion
          className='nav-bar-accordion'
          expanded={showModules}
        >
          <AccordionSummary />
          <AccordionDetails>
            <Box className='nav-bar'>
              <Container>
                {clientData.modules.map((item, index) => (
                  <Box
                    key={index}
                    component={'a'}
                    href={item.path}
                    className={isSelected(item) ? 'activeTab tab-item' : 'tab-item'}
                    target={item.target}
                  >
                    <IconCustom icon={item.icon} alt={item.moduleName} className="custom-icon" />
                    <span>{item.moduleName}</span>
                  </Box>
                ))}
              </Container>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}

      {getMultibuscador()}
    </>
  );
}

export default Navbar;
