import MultibuscadorGimmonix from './MultibuscadorGimmonix';
import MultibuscadorTrains from './MultibuscadorTrains';
import MultibuscadorFlights from './MultibuscadorFlights';
import MultibuscadorAssists from './MultibuscadorAssists';

const Multibuscador = ({ module, ...props }) => {
    if (module === 'hotels') {
      return <MultibuscadorGimmonix {...props} />
    } else if (module === 'trains') {
      return <MultibuscadorTrains {...props} />
    } else if (module === 'flights') {
      return <MultibuscadorFlights {...props} />
    } else if (module === 'assists') {
      return <MultibuscadorAssists {...props} />
    }
};

export default Multibuscador;
