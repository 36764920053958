const CONFIRMED = {
  hotels: {
    content: {
      title: 'sucessBooking',
      statusMessage: 'operationPerformed',
      icon: 'check_circle',
      status_booking_icon: 'check_circle',
      status_booking_title: 'confirmed',
      status_booking: 'ok',
      show_idTransaction: true,
      show_status_booking: true,
      show_status_payment: false
    },
    messages: [
      {
        position: 'descriptionMessage',
        message: [
          'autocancel'
        ]
      }
    ]
  },
  trains: {
    content: {
      title: 'sucessBooking',
      statusMessage: 'operationPerformed',
      icon: 'check_circle',
      status_booking_icon: 'error',
      status_booking_title: 'pendingIssue',
      status_booking: 'pending',
      status_payment_icon: 'check_circle',
      status_payment_title: 'approvedPayment',
      status_payment: 'ok',
      show_idTransaction: true,
      show_status_booking: true,
      show_status_payment: false,
      messages: []
    },
    messages: [
      {
        position: 'descriptionMessage',
        message: [
          'sucessBookingPendingIssue'
        ]
      }
    ]
  },
  flights: {
    content: {
      title: 'sucessBooking',
      statusMessage: 'operationPerformed',
      icon: 'check_circle',
      status_booking_icon: 'check_circle',
      status_booking_title: 'confirmed',
      status_booking: 'ok',
      status_payment_icon: 'check_circle',
      status_payment_title: 'pendingPayment',
      status_payment: 'pending',
      show_idTransaction: true,
      show_status_booking: true,
      show_status_payment: true
    },
    messages: [
      {
        position: 'descriptionMessage',
        message: [
          'reservationPendingPaymentFlight'
        ]
      }
    ]
  },
};

const REJECTED = {
  hotels: {
    content: {
      title: 'errorBooking',
      statusMessage: 'sorry',
      icon: 'error',
      status_booking_icon: 'error',
      status_booking_title: 'rejected',
      status_booking: 'ko',
      show_idTransaction: true,
      show_status_booking: true,
      show_status_payment: false,
      messages: [
        {
          subtitle: 'unconfirmedReservation',
          className: 'status-ko'
        }
      ]
    }
  },
  trains: {
    content: {
      statusMessage: 'failedOperation',
      title: 'rejectedBooking',
      icon: 'error',
      status_booking_icon: 'error',
      status_booking_title: 'rejected',
      status_booking: 'ko',
      show_idTransaction: true,
      show_status_booking: true,
      show_status_payment: false,
      messages: [
        {
          subtitle: 'unconfirmedReservation',
          hideInfoIcon: true,
          addNewSearchButton: true,
          newSearchButtonTitle: 'continueBooking'
        }
      ]
    }
  },
  flights: {
    content: {
      title: 'errorBooking',
      statusMessage: 'sorry',
      icon: 'error',
      status_booking_icon: 'error',
      status_booking_title: 'rejected',
      status_booking: 'ko',
      show_idTransaction: true,
      show_status_booking: true,
      show_status_payment: false,
      messages: [
        {
          subtitle: 'unconfirmedReservation',
          hideInfoIcon: true,
          addNewSearchButton: true,
          newSearchButtonTitle: 'continueBooking'
        }
      ]
    }
  },
}

const ERROR = {
  hotels: {
    content: {
      statusMessage: 'sorry',
      title: 'errorBooking',
      icon: 'error',
      status_booking_icon: 'error',
      status_booking_title: 'rejected',
      status_booking: 'ko',
      show_idTransaction: false,
      show_status_booking: false,
      show_status_payment: false,
      messages: [
        {
          subtitle: 'anErrorOcurred',
          hideInfoIcon: true,
          addNewSearchButton: true,
          newSearchButtonTitle: 'continueBooking'
        }
      ]
    }
  },
  trains: {
    content: {
      statusMessage: 'sorry',
      title: 'errorBooking',
      icon: 'error',
      status_booking_icon: 'error',
      status_booking_title: 'rejected',
      status_booking: 'ko',
      show_idTransaction: false,
      show_status_booking: false,
      show_status_payment: false,
      messages: [
        {
          subtitle: 'anErrorOcurred',
          hideInfoIcon: true,
          addNewSearchButton: true,
          newSearchButtonTitle: 'continueBooking'
        }
      ]
    }
  },
  flights: {
    content: {
      statusMessage: 'sorry',
      title: 'errorBooking',
      icon: 'error',
      status_booking_icon: 'error',
      status_booking_title: 'rejected',
      status_booking: 'ko',
      show_idTransaction: false,
      show_status_booking: false,
      show_status_payment: false,
      messages: [
        {
          subtitle: 'anErrorOcurred',
          hideInfoIcon: true,
          addNewSearchButton: true,
          newSearchButtonTitle: 'continueBooking'
        }
      ]
    }
  },
}

const PENDING = {
  hotels: {
    content: {
      title: '',
      statusMessage: 'managingBooking',
      icon: 'error',
      status_booking_icon: 'error',
      status_booking_title: 'pendingConfirmation',
      status_booking: 'pending',
      show_idTransaction: true,
      show_status_booking: true,
      show_status_payment: false,
      messages: [
        {
          title: 'bookingInProcess',
          subtitle: 'bookingStatusOnConfirm',
          show_timer: true,
          className: 'status-pending'
        }
      ]
    },
    messages: [
      {
        position: 'descriptionMessage',
        message: [
          'autocancel'
        ]
      }
    ]
  },
  trains: {
    content: {
      title: 'pendingPaymentTitle',
      statusMessage: 'pendingOperation',
      icon: 'error',
      status_booking_icon: 'error',
      status_booking_title: 'pendingIssue',
      status_booking: 'pending',
      show_idTransaction: true,
      show_status_booking: true,
      show_status_payment: true,
      messages: []
    },
    messages: []
  }
};

export {
  CONFIRMED,
  REJECTED,
  ERROR,
  PENDING
}