import React from 'react'
import DetailDialog from './DetailDialog'
import * as TrainsDetail from './utils/TrainsDetail';
import * as FlightsDetail from './utils/FlightsDetail';
import { useTranslation } from 'react-i18next';
import { usePurchaseDetailContext } from '../../../context/PurchaseDetailContext';

const TotalAPagar = ({ purchaseDetail, products, module }) => {
  const { t } = useTranslation();
  const { includeComission } = usePurchaseDetailContext();

  const getTotalPrice = () => {
    if (module === 'hotels') {
      return purchaseDetail?.totalAmount;
    } else if (module === 'trains') {
      return TrainsDetail.getTotalPrice(purchaseDetail, includeComission);
    } else if (module === 'flights') {
      return FlightsDetail.getTotalPrice(purchaseDetail);
    }
  }

  return (
    <div className='price-detail-total row'>
      <span className="text">{t('checkout.common.totalToPay')}</span>
      <span className="price">
        {purchaseDetail?.currency}&nbsp;
        <span className="amount">{getTotalPrice()}</span>
      </span>
      <DetailDialog
        icon='info_outlined'
        purchaseDetail={purchaseDetail}
        products={products}
        module={module}
      />
    </div>
  )
}

export default TotalAPagar