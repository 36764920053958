import React from 'react';
import { Container, Grid, Hidden } from '@mui/material';
import LoginForm from './LoginForm';
import { useBannersData } from '../../../context/BannersContext';

const DefaultLogin = () => {
  const { bannersData } = useBannersData();

  const getBackgroundImage = () => {
    const image = bannersData?.data?.modules?.['otros']?.flows?.login?.banners?.BackgroundLoginDefault?.slice(-1)?.[0]?.image_url;
    const logo = bannersData?.data?.modules?.['otros']?.flows?.login?.banners?.Logo?.slice(-1)?.[0]?.image_url;
    let style = {};

    if (image) {
      style = { backgroundImage: `url('${image}')` };
    }

    return <div className='bg-login' style={style}>
      {logo && (
        <img src={logo} alt='logo' className='logo' />
      )}
    </div>
  }

  return (
    <div className='wrapper-login default-login'>
      <Grid container>
        <Hidden smDown>
          <Grid item md={4}>
            {getBackgroundImage()}
          </Grid>
        </Hidden>
        <Grid item md={8} style={{ margin: 'auto' }}>
          <Container maxWidth='sm'>
            <LoginForm />
          </Container>
        </Grid>
      </Grid>
    </div>
  );
}

export default DefaultLogin;
