const initialState = {
  resultSearch: {},
  error: null,
  resultSearchThreeDays: null,
  errorThreeDays: null,
  resultSearchMinPrice: null,
  errorMinPrice: null,
  resultsAvailability: {},
  resultAvailabilityParallel: [],
  errorAvailability: null,
  filtersResult: {},
  filterSearch: {},
  errorFilter: null,
  apiCheckout: {},
  errorApiCheckout: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PRODUCT_SEARCH_SUCCESS_FLIGHTS':
      return {
        ...state,
        resultSearch: action.payload,
        error: null,
      };
    case 'GET_PRODUCT_SEARCH_ERROR_FLIGHTS':
      return {
        ...state,
        resultSearch: null,
        error: action.payload,
      };
    case 'GET_PRODUCT_SEARCH_THREE_DAYS_SUCCESS_FLIGHTS':
      return {
        ...state,
        resultSearchThreeDays: action.payload,
        errorThreeDays: null,
      };
    case 'GET_PRODUCT_SEARCH_THREE_DAYS_ERROR_FLIGHTS':
      return {
        ...state,
        resultSearchThreeDays: null,
        errorThreeDays: action.payload,
      };
    case 'GET_MIN_PRICE_SUCCESS_FLIGHTS':
      return {
        ...state,
        resultSearchMinPrice: action.payload,
        errorMinPrice: null,
      };
    case 'GET_MIN_PRICE_ERROR_FLIGHTS':
      return {
        ...state,
        resultSearchMinPrice: null,
        errorMinPrice: action.payload,
      };
    case 'GET_PRODUCT_AVAILABILITY_SUCCESS_FLIGHTS':
      return {
        ...state,
        resultsAvailability: action.payload,
        errorAvailability: null,
      };
    case 'GET_PRODUCT_AVAILABILITY_ERROR_FLIGHTS':
      return {
        ...state,
        resultsAvailability: null,
        errorAvailability: action.payload,
      };
    case 'GET_PRODUCT_AVAILABILITY_PARALLEL_SUCCESS_FLIGHTS':
      return {
        ...state,
        resultAvailabilityParallel: action.payload,
        errorAvailability: null
      };
    case 'GET_PRODUCT_AVAILABILITY_PARALLEL_ERROR_FLIGHTS':
      return {
        ...state,
        errorAvailability: action.payload,
      };
    case 'GET_PRODUCT_FILTERS_SUCCESS_FLIGHTS':
      return {
        ...state,
        filtersResult: action.payload,
        error: null,
        errorFilter: null
      };
    case 'GET_PRODUCT_FILTERS_ERROR_FLIGHTS':
      return {
        ...state,
        error: action.payload,
        errorFilter: null
      };
    case 'GET_PRODUCT_FILTER_FLIGHTS':
      return {
        ...state,
        errorFilter: null,
      };
    case 'GET_PRODUCT_FILTER_SUCCESS_FLIGHTS':
      return {
        ...state,
        filterSearch: action.payload,
        resultSearch: {},
        filtersResult: {},
        error: null,
        errorFilter: null
      };
    case 'GET_PRODUCT_FILTER_ERROR_FLIGHTS':
      return {
        ...state,
        error: null,
        errorFilter: action.payload,
      };
    case 'START_CHECKOUT':
      return {
        ...state,
        apiCheckout: {},
        errorApiCheckout: false
      };
    case 'GET_START_CHECKOUT_SUCCESS':
      return {
        ...state,
        apiCheckout: action.payload,
        errorApiCheckout: action.payload.status === false ? true : false
      };
    case 'GET_START_CHECKOUT_ERROR':
      let parsedPayload;
      try {
        parsedPayload = JSON.parse(action.payload);
      } catch (error) {
        parsedPayload = { error: 'Respuesta no válida de la API' };
      }
      return {
        ...state,
        apiCheckout: parsedPayload,
        errorApiCheckout: true
      };
    default:
      return state;
  }
};

export default reducer;
