import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { getCredentials } from './util';

function* searchActionTrains(actions) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${actions.token}`
    }
  };

  if (actions.signalAbort) {
    requestOptions.signal = actions.signalAbort
  }

  try {
    const url = actions.data.place
      ? `${getCredentials().urlTrains}search_passes`
      : `${getCredentials().urlTrains}search`
    const response = yield call(axios.post, url, actions.data, requestOptions);
    yield put({ type: 'GET_PRODUCT_SEARCH_SUCCESS_TRAINS', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_PRODUCT_SEARCH_ERROR_TRAINS', payload: error.message });
  }
}

export function* searchSagaTrains() {
  yield takeEvery('GET_PRODUCT_SEARCH_TRAINS', searchActionTrains);
}
