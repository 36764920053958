import { createContext, useContext, useState } from 'react';

const PuchaseDetailContext = createContext();

export const PuchaseDetailProvider = ({ children }) => {
  const [includeComission, setIncludeComission] = useState(false);

  return (
    <PuchaseDetailContext.Provider value={{
      includeComission,
      setIncludeComission
    }}>
      {children}
    </PuchaseDetailContext.Provider>
  );
};

export const usePurchaseDetailContext = () => {
  return useContext(PuchaseDetailContext);
};
