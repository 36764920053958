const initialState = {
  resultSearch: {},
  error: null,
  apiCheckout: {},
  errorApiCheckout: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PRODUCT_SEARCH_SUCCESS_TRAINS':
      return {
        ...state,
        resultSearch: action.payload,
        error: null,
      };
    case 'GET_PRODUCT_SEARCH_ERROR_TRAINS':
      return {
        ...state,
        resultSearch: null,
        error: action.payload,
      };
    case 'START_CHECKOUT':
      return {
        ...state,
        apiCheckout: {},
        errorApiCheckout: false
      };
    case 'GET_START_CHECKOUT_SUCCESS':
      return {
        ...state,
        apiCheckout: action.payload,
        errorApiCheckout: action.payload.status === false ? true : false
      };
    case 'GET_START_CHECKOUT_ERROR':
      let parsedPayload;
      try {
        parsedPayload = JSON.parse(action.payload);
      } catch (error) {
        parsedPayload = { error: 'Respuesta no válida de la API' };
      }
      return {
        ...state,
        apiCheckout: parsedPayload,
        errorApiCheckout: true
      };
    default:
      return state;
  }
};

export default reducer;
