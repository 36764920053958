import React from 'react';
import {Dialog, Button, DialogTitle, DialogContent, DialogActions, Fab} from '@mui/material/';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';

function DialogMessage({ open, onClose, title, message, showCloseButton, showActions }) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} className='closable-modal dialog-message'>
      {title &&
        <DialogTitle >
          {title}
          {showCloseButton && (
            <Fab size="small" onClick={onClose} className='close-button'>
              <Close fontSize='small' />
            </Fab>
          )}
        </DialogTitle>}
      <DialogContent>
        {message}
      </DialogContent>
      {showActions && (
        <DialogActions>
          <Button onClick={onClose}>{t('common.close')}</Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default DialogMessage;
