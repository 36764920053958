import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControlLabel, Grid, IconButton, Rating, Tooltip, Typography } from '@mui/material';
import { ArrowBack, AttachMoney, Check, Close, LocationOn, PictureAsPdf, Print, RoomService, Settings } from '@mui/icons-material';
import ImagesSlider from '../../common/ImagesSlider';
import { availabilityParallelActionGimmonix, cancelationPolicyActionGimmonix } from '../../../store/actions';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { PDFComparation } from './PDFComparation';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import Dots from '../../common/Dots';
import { useClientData } from '../../../context/ClientContext';

const AMENITIES_DEFAULT = [
  { id_filter: 11860, value: 'wifi' },
  { id_filter: 14260, value: 'parking' },
  { id_filter: 13530, value: 'pool' },
  { id_filter: 'map', value: 'map' }
]

const AMENITIES_TO_COMPARE = [
  { id_filter: 11860, value: 'wifi' },
  { id_filter: 14260, value: 'parking' },
  { id_filter: 13530, value: 'pool' },
  { id_filter: 12810, value: 'pets' },
  { id_filter: 10080, value: 'AC' },
  { id_filter: 13250, value: 'transfer' },
  { id_filter: 11750, value: 'gym' },
  { id_filter: 12000, value: 'spa' },
  { id_filter: 10420, value: 'beach' },
  { id_filter: 14020, value: 'wheelchair' },
  { id_filter: 12250, value: 'kitchen' },
  { id_filter: 14841, value: '24Hours' },
  { id_filter: 12290, value: 'laundry' },
  { id_filter: 14410, value: 'playroom' },
  { id_filter: 12670, value: 'nonSmokerRoom' },
  { id_filter: 'map', value: 'map' },
  { id_filter: 'attractions', value: 'distances' }
]

const ComparationComponent = ({
  dataForm,
  resultsToCompare,
  providers,
  hashFlow,
  resultAvailabilityParallel,
  hotels,
  google,
  errorAvailability,
  handleGoBack,
  handleClickHotel,
  handleClickAddHotel,
  handleClickRemoveHotel,
  ...props
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { setClientData } = useClientData();
  const [activeAmenities, setActiveAmenities] = useState(AMENITIES_DEFAULT);
  const [availabities, setAvailabilities] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [amenitiesModal, setAmenitiesModal] = useState(AMENITIES_DEFAULT);
  const [abortController, setAbortController] = useState();
  const [loadingAvailability, setLoadingAvailability] = useState(false);
  const [map, setMap] = useState();
  const [infoWindow, setInfoWindow] = useState([]);

  useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDmcY_yesxBlDAAnSaP0lWgKzXT3iQLZ0E',
    language: 'es',
    region: 'ES'
  })

  useEffect(() => {
    setInfoWindow(resultsToCompare.map(elem => elem.id));
  }, [resultsToCompare]);

  useEffect(() => {
    const hotelProduct = JSON.parse(localStorage.getItem('products') || '[]')
      ?.find(elem => elem.ref_table_detail === 'hotel_detail');
    const token = hotelProduct?.parameters?.['API-Token'];
    if (token) {
      setLoadingAvailability(true);

      if (hashFlow) {
        const currentAvailabities = availabities.map(elem => elem.details.id);
        const daysToCancel = hotelProduct?.parameters?.days_to_cancel || 0;
        const requests = resultsToCompare.filter(hotel => !currentAvailabities.includes(hotel.id)).map(hotel => ({
          hashFlow,
          hotelId: hotel.id,
          token,
          daysToCancel,
          nrfBook: true,
          detailOnly: true
        }))

        abortController && abortController.abort();
        const controller = new AbortController();
        setAbortController(controller);

        if (requests.length > 0) {
          props.getAvailabilityParallel(requests, controller.signal);
        } else {
          setLoadingAvailability(false);
        }

        if (resultsToCompare.length > 0) {
          map?.fitBounds(getBounds());
        }
      }
    } else {
      const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
      if (iframePath) {
        localStorage.clear();
        setClientData(null);
        navigate(iframePath);
      } else {
        navigate('/login', { state: { expiredSession: true } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsToCompare, hashFlow]);

  useEffect(() => {
    if (loadingAvailability && resultAvailabilityParallel.length > 0) {
      setAvailabilities(current => [
        ...current,
        ...resultAvailabilityParallel.map(elem => elem.data).filter(elem => elem)
      ]);
      setLoadingAvailability(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultAvailabilityParallel]);

  useEffect(() => {
    if (loadingAvailability && errorAvailability) {
      setLoadingAvailability(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorAvailability]);

  const getProviderLogo = (providerName) => {
    const providerNameAux = providerName.replaceAll(' ', '').toLowerCase();
    const provider = providers.find(elem => providerNameAux.includes(elem.name));

    if (provider) {
      return <Tooltip title={providerName}>
        <img className='provider-logo' src={provider?.image} alt='logo-provider' />
      </Tooltip>
    } else {
      return <Typography style={{ flex: 1 }}>{providerName}</Typography>
    }
  }

  const handleOpenModal = () => {
    setAmenitiesModal([...activeAmenities]);
    setOpenModal(true);
  }

  const handleCloseModal = (accepted) => {
    if (accepted) {
      setActiveAmenities([
        ...amenitiesModal.filter(elem => !isNaN(elem.id_filter)),
        ...amenitiesModal.filter(elem => isNaN(elem.id_filter))
      ]);
    } else {
      setAmenitiesModal([...activeAmenities]);
    }
    setOpenModal(false);
  }

  const handleChangeCheckbox = (value, checked) => {
    let amenitiesModalAux = [...amenitiesModal];

    if (checked) {
      amenitiesModalAux.push(value);
    } else {
      amenitiesModalAux = amenitiesModalAux.filter(elem => elem.id_filter !== value.id_filter);
    }

    setAmenitiesModal(amenitiesModalAux);
  }

  const getBounds = () => {
    const bounds = new window.google.maps.LatLngBounds();
    resultsToCompare.forEach((marker) => {
      bounds.extend(new window.google.maps.LatLng(marker.latitude, marker.longitude));
    });

    return bounds;
  }

  const onReadyMap = (mapAux) => {
    setMap(mapAux);
    mapAux.fitBounds(getBounds());
  }

  const getRecommendedHotels = () => {
    const selectedHotelIds = resultsToCompare.map(elem => elem.id);
    const recommendedHotels = hotels.filter(elem => elem.recommended && !selectedHotelIds.includes(elem.id)).slice(0, 2);
    return recommendedHotels;
  }

  const getHeightContainer = () => {
    const lastColumn = document.getElementsByClassName('add-hotel-column')?.[0];
    return lastColumn?.clientHeight || null;
  }

  const getPriceContainer = (hotel) => {
    const availability = availabities.find(elem => elem.details.id === hotel.id);
    const packageHotel = availability?.packages?.[0] || availability?.package;
    const refundable = packageHotel ? packageHotel.refundable : hotel?.rateType !== 'Nonrefundable'

    return (
      <Grid className='price-info column'>
        <Typography className='row'>
          {refundable ? (
            <><AttachMoney className='money-icon green' /> {t('results.hotels.noCancellationFees')}</>
          ) : (
            <><AttachMoney className='money-icon red' />  {t('results.hotels.withCancellationFees')}</>
          )}
        </Typography>

        <div className='text-center'>{getProviderLogo(hotel.providerName)}</div>
        <Typography className='price-text'>{t('results.hotels.totalPriceFrom')}</Typography>
        <Typography className='price'>
          {!Boolean(hashFlow) ? <Dots number={3} /> : <>{hotel.currency} {parseInt(hotel.totalPrice)}</>}
        </Typography>
        <Button
          variant='contained'
          fullWidth
          color='secondary'
          disabled={!Boolean(hashFlow)}
          onClick={() => handleClickHotel('room', hotel)}
        >
          {t('common.reserve')}
        </Button>
      </Grid>
    )
  }

  const getAttractions = (hotelId) => {
    const attractions = availabities.find(elem => elem.details.id === hotelId)?.details?.description
      ?.filter(elem => elem.title === 'attractions');

    if (attractions && attractions.length > 0) {
      return attractions.map((elem, k) => (
        <p key={k}>{elem.paragraph}</p>
      ))
    } else {
      return <Typography className='text-center'>-</Typography>;
    }
  }

  const handleOpenInfoWindow = (hotel) => {
    if (!infoWindow.includes(hotel.id)) {
      const infoWindowAux = [...infoWindow, hotel.id];
      setInfoWindow(infoWindowAux);
    }
  }

  const handleCloseInfoWindow = (hotel) => {
    let infoWindowAux = [...infoWindow];
    infoWindowAux = infoWindowAux.filter(elem => elem !== hotel.id);
    setInfoWindow(infoWindowAux);
  }

  const handlePrintButtonClick = (url) => {
    const printWindow = window.open(url, '_blank');
    printWindow.onload = () => {
      printWindow.print();
    };
  };

  return (
    <Grid minHeight={getHeightContainer()}>
      <Grid container columnSpacing={2} columns={24} className='first-row'>
        <Grid item xs={4} className='buttons-container'>
          <PDFDownloadLink
            document={
              <PDFComparation
                client={props.client}
                searchData={dataForm}
                resultsToCompare={resultsToCompare}
                activeAmenities={activeAmenities}
                providers={providers}
              />
            }
            fileName={`${t('results.hotels.compareHotelsInDestination', { destination: dataForm?.destination?.label, interpolation: { escapeValue: false } })}.pdf`}
            style={{ textDecoration: 'none' }}
          >
            {({ url, loading, error }) => (
              <>
                <Button className='button row' disabled={loading || error}>
                  <PictureAsPdf />{t('results.hotels.download')}
                </Button>
                <Button
                  className='button row'
                  disabled={loading || error}
                  onClick={(e) => {
                    e.preventDefault();
                    handlePrintButtonClick(url);
                  }}
                >
                  <Print />{t('results.hotels.print')}
                </Button>
              </>
            )}
          </PDFDownloadLink>
          <Button className='button row' onClick={handleOpenModal}>
            <Settings />{t('results.hotels.modifyCriteria')}
          </Button>
        </Grid>

        {resultsToCompare.map(hotel => (
          <Grid item xs={5} className={`hotel-info ${hotel.recommended ? 'recommended' : ''}`} key={hotel.id}>
            {hotel.recommended && (
              <div className='row recommended-container'>
                <RoomService /> {t('results.hotels.hotelRecommended')}
              </div>
            )}

            <ImagesSlider images={hotel.images.slice(0, 5)} height={250} />

            <Grid>
              <Typography className='property'>
                {t(`filters.hotels.properties.${hotel?.property?.toLowerCase() || 'other'}`).toUpperCase()}
              </Typography>
              <Tooltip title={hotel.name} placement='top'>
                <Typography className='name'>{hotel.name}</Typography>
              </Tooltip>
              <Typography className='row location'><LocationOn fontSize='small' />{hotel.address}</Typography>
              <Grid className='row'>
                <Rating value={parseInt(hotel.stars)} readOnly />
              </Grid>
              <Button size='small' onClick={() => handleClickHotel('details', hotel)}>
                {t('results.hotels.seeHotelDetails')}
              </Button>
            </Grid>

            <IconButton className='close-button' size='small' onClick={() => handleClickRemoveHotel(hotel)}>
              <Close fontSize='small' />
            </IconButton>
          </Grid>
        ))}

        {resultsToCompare.length < 4 && (
          <Grid item xs={5}>
            <Grid className='add-hotel-column'>
              <Typography className='title'>{t('results.hotels.addRecommendedHotels')}</Typography>
              {getRecommendedHotels().map(hotel => (
                <Grid className='hotel-card' key={hotel.id}>
                  {hotel.recommended &&
                    <Grid className='row recommended-container'>
                      <RoomService />
                      {t('results.hotels.hotelRecommended')}
                    </Grid>
                  }

                  <Grid className='hotel-info'>
                    <Grid className='row'>
                      <img
                        src={hotel.image}
                        alt={hotel.name}
                        style={{
                          height: '100px',
                          width: '100px',
                          objectFit: 'cover',
                          marginRight: '10px',
                          borderRadius: '12px',
                        }}
                      />
                      <Grid>
                        <Typography className='property'>
                          {t(`filters.hotels.properties.${hotel?.property?.toLowerCase() || 'other'}`)}
                        </Typography>
                        <Typography className='name'>{hotel.name}</Typography>
                        <Typography className='row location'>
                          <LocationOn fontSize='small' /><span>{`${hotel.address}`}</span>
                        </Typography>
                        <Rating size="small" value={parseInt(hotel.stars)} readOnly />
                      </Grid>
                    </Grid>

                    <Grid className='price-container'>
                      <Typography>{t('results.hotels.totalPriceFrom')}</Typography>
                      <Typography className='price'>{`${hotel.currency} ${parseInt(hotel.totalPrice)} `}</Typography>
                    </Grid>
                    <Button variant="outlined" color='secondary' size='small' onClick={() => handleClickAddHotel(hotel)}>
                      {t('results.hotels.addHotel')}
                    </Button>
                  </Grid>
                </Grid>
              ))}
              <Button variant='outlined' fullWidth size='small' onClick={handleGoBack}>
                <ArrowBack />
                {t('results.hotels.addHotelFromResults')}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>

      {activeAmenities.map((amenity, i) => (
        <Grid container item columnSpacing={2} columns={24} className='amenity-container' key={i}>
          <Grid item xs={4}>
            <Grid className='amenity amenity-name'>
              {t(`results.hotels.amenitiesList.${amenity.value}`)}
            </Grid>
          </Grid>

          {amenity.id_filter === 'map' && (
            <Grid item xs={resultsToCompare.length * 5}>
              <Grid className='map'>
                <GoogleMap
                  onLoad={onReadyMap}
                  mapContainerStyle={{ height: '100%' }}
                >
                  {resultsToCompare.map((hotel) => (
                    <Marker
                      key={hotel.id}
                      name={hotel.name}
                      position={{
                        lat: hotel.latitude,
                        lng: hotel.longitude,
                      }}
                      onClick={() => handleOpenInfoWindow(hotel)}
                    >
                      {infoWindow.includes(hotel.id) && (
                        <InfoWindow
                          position={{
                            lat: hotel.latitude,
                            lng: hotel.longitude
                          }}
                          onCloseClick={() => handleCloseInfoWindow(hotel)}
                        >
                          <span>{hotel.name}</span>
                        </InfoWindow>
                      )}
                    </Marker>
                  ))}
                </GoogleMap>
              </Grid>
            </Grid>
          )}

          {amenity.id_filter === 'attractions' && (
            resultsToCompare.map(hotel => (
              <Grid item xs={5} key={hotel.id}>
                <Grid className='attractions'>
                  {getAttractions(hotel.id)}
                </Grid>
              </Grid>
            ))
          )}

          {!isNaN(amenity.id_filter) && (
            resultsToCompare.map(hotel => (
              <Grid item xs={5} key={hotel.id}>
                <Grid className='amenity row'>
                  {hotel.amenities.find(elem => elem.code === amenity.id_filter) ? <Check className='green' /> : '-'}
                </Grid>
              </Grid>
            ))
          )}
        </Grid>
      ))}

      <Grid container columnSpacing={2} columns={24}>
        <Grid item xs={4} />

        {resultsToCompare.map(hotel => (
          <Grid item xs={5} key={hotel.id} style={{ display: 'flex' }}>{getPriceContainer(hotel)}</Grid>
        ))}
      </Grid>

      <Dialog className='closable-modal' open={openModal} maxWidth='md' fullWidth onClose={() => handleCloseModal(false)}>
        <Fab size="small" onClick={() => handleCloseModal(false)} className='close-button'>
            <Close fontSize='small' />
        </Fab>

        <DialogTitle>
          {t('results.hotels.selectAmenitites')}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            {AMENITIES_TO_COMPARE.map((amenity, i) => (
              <Grid item key={i} xs={12} md={4}>
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      onChange={(e, checked) => handleChangeCheckbox(amenity, checked)}
                      checked={amenitiesModal.find(elem => elem.id_filter === amenity.id_filter) ? true : false}
                    />
                  }
                  label={t(`results.hotels.amenitiesList.${amenity.value}`)}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAmenitiesModal(AMENITIES_TO_COMPARE)} style={{ marginRight: 'auto' }}>
            {t('results.hotels.selectAll')}
          </Button>
          <Button onClick={() => setAmenitiesModal(AMENITIES_DEFAULT)}>{t('results.hotels.defaultAmenities')}</Button>
          <Button variant='contained' color='primary' onClick={() => handleCloseModal(true)}>{t('results.hotels.updateComparation')}</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const mapStateToProps = reducers => {
  return reducers.gimmonixReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    getAvailabilityParallel: (requests, signalAbort) => dispatch(availabilityParallelActionGimmonix(requests, signalAbort)),
    getCancelationPolicyAction: (data) => dispatch(cancelationPolicyActionGimmonix(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ComparationComponent);
