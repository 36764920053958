import React from 'react';

export default function RawMarkup({
  customClassName = '',
  content,
  containerType = 'div'
}) {
  const Container = containerType;

  return (
    <Container
      className={`rowmarkup ${customClassName}`}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
}
