import { GimmonixHomepage, GimmonixResults, GimmonixFavorites } from './Gimmonix';
import { TrainsHomepage, TrainsResults } from './Trains';
import { FlightsHomepage, FlightsResults } from './Flights';
import { AssistsHomepage, AssistsResults } from './Assists';
import Packages from './Packages/Homepage';
import Tours from './Tours/Homepage';
import Transfers from './Transfers/Homepage';
import Rentingcarz from './Rentingcarz/Homepage';

export {
  GimmonixHomepage,
  GimmonixResults,
  GimmonixFavorites,
  TrainsHomepage,
  TrainsResults,
  FlightsHomepage,
  FlightsResults,
  AssistsHomepage,
  AssistsResults,
  Packages,
  Tours,
  Transfers,
  Rentingcarz
}