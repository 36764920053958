import React from 'react';
import { Button, Typography, Box, Link, Container, useTheme, useMediaQuery } from '@mui/material';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function BannerItem({ item, isMobile }) {
    const {image_url, image_url_mobile, title, description, button_text, link} = item;

    const getImage = () => {
        if (isMobile) {
            return image_url_mobile?.src || image_url_mobile;
        }

        return image_url?.src || image_url;
    }

    let style = {
        backgroundImage: `url('${getImage()}')`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 15
    }

    if (link && !button_text) {
        style.cursor = 'pointer';
    }

    if (!title &&  !description && button_text && link) {
        style.justifyContent = 'flex-end'
    }

    const onClick = () => {
        if (link && !button_text) {
           window.open(link, '_blank');
        }
    }

    return (
        <Box
            className="carousel-item"
            style={style}
            onClick={onClick}
        >
            <Typography component="h2" sx={{ fontSize: { xs: '2rem', md: '2.5rem', lg: '3rem' } }}>{title}</Typography>
            <Typography component="h3" sx={{ fontSize: { xs: '1rem', md: '1.5rem', lg: '2rem' } }}>{description}</Typography>
            {button_text && link && (
               <Button variant="contained" component={Link} href={link} target="_blank" rel="noopener noreferrer">{button_text}</Button>
            )}
        </Box>
    )
}

export default function BannerSlider({ banners }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const carouselResponsive = {
        all: {
          breakpoint: { max: 4000, min: 0 },
          items: 1,
        },
    };

    const sortBanners = () => {
        return banners?.sort((a, b) => (a.order || Infinity) - (b.order || Infinity)) || [];
    }
    if (banners && banners.length > 0) {
        return (
            <section className='banner-slider-section'>
                <Container>
                    {sortBanners().length > 1 ? (
                        <Carousel
                            responsive={carouselResponsive}
                            autoPlay
                            infinite
                            autoPlaySpeed={4000}
                            className='banner-slider banner-with-carousel'
                        >
                            {banners.map((item, i) => <BannerItem key={i} item={item} isMobile={isMobile} /> )}
                        </Carousel>
                    ) : (
                        <Box className='banner-slider banner-with-carousel' >
                            {banners.map((item, i) => <BannerItem key={i} item={item} isMobile={isMobile} />  )}
                        </Box>
                    )}
                </Container>
            </section>
        )
    }
}
