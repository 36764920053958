import React, { useEffect, useState } from 'react';
import { useClientData } from './context/ClientContext';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { GlobalStyles } from '@mui/system';
import createThemeFromData, { globalStyles } from './assets/Theme.js';
import Layout from './components/layout/Layout.js';
import LayoutPreHome from './components/layout/LayoutPreHome.js';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  GimmonixHomepage,
  GimmonixResults,
  GimmonixFavorites,
  TrainsHomepage,
  TrainsResults,
  FlightsResults,
  FlightsHomepage,
  AssistsHomepage,
  AssistsResults
} from './components/modules';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Login from './components/pages/Login/Login'
import dayjs from 'dayjs';
import LayoutCheckout from './components/layoutCheckout/Layout.js';
import './App.css';
import Register from './components/pages/Register/Register';
import ThankYouPage from './components/pages/Checkout/ThankYouPage';
import Error404 from './components/pages/Checkout/Error404.js';
import RecoverPassword from './components/pages/Recover/RecoverPassword.js';
import ErrorAvailability from './components/pages/Checkout/ErrorAvailability.js';
import ScrollToTop from './components/common/ScrollToTop.js';
import { autologin, getBanners, getClient, getLandings, getPayment, getProducts } from './store/services/Login.js';
import DefaultLogin from './components/pages/Login/DefaultLogin.js';
import DefaultRecoverPassword from './components/pages/Recover/DefaultRecoverPassword.js';
import { BannersProvider } from './context/BannersContext.js';
import InfoPage from './components/pages/InfoPage/InfoPage';
import CondicionesGenerales from './components/pages/CondicionesGenerales/CondicionesGenerales';
import ThanksPage from './components/pages/ThanksPage/ThanksPage';
import ZohoBot from './components/common/ZohoBot.js';
// import PreHome from './components/pages/PreHome/PreHome.js';
import { getClientIpService } from './store/services/IpServices.js';
import { MODULES } from './utils/modules.js';
import { PaymentProvider } from './context/PaymentContext.js';
import { PuchaseDetailProvider } from './context/PurchaseDetailContext.js';

//Para probar local
// import configSite from './sites/gotravelres/config/config.json' // GTR
// import configSite from './sites/ttoperadora/config/config.json' // TT Operadora
// import configSite from './sites/gotrenes/config/config.json' // GoTrenes

// const urlLocal = 'dev-gotravelres-react.tije.travel' // Dev GTR
// const urlLocal = 'stg-hoteles-react.tije.travel' // Stg GTR
// const urlLocal = 'gotravelres.com.ar' // Prod GTR
// const urlLocal = 'stg-ttoperadora-react.tije.travel' // Stg TTO
const urlLocal = 'agentes.ttoperadora.com.br' // Prod TTO
// const urlLocal = 'stg-gotrenes.tije.travel' // Stg GoTrenes

dayjs.extend(require('dayjs/plugin/isBetween'));
dayjs.extend(require('dayjs/plugin/isSameOrBefore'));
dayjs.extend(require('dayjs/plugin/isSameOrAfter'));
dayjs.extend(require('dayjs/plugin/isBetween'));
dayjs.extend(require('dayjs/plugin/isoWeek'));
dayjs.extend(require('dayjs/plugin/updateLocale'));

dayjs.updateLocale("pt", {
  weekdaysMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
});

const ALLOWED_IFRAMES = ['stg-viajoentren.tije.travel', 'trenes.viajoentren.com'];

function App() {
  const {
    clientData,
    setClientData,
    setPayment,
    setCurrentProduct,
    language,
    setLanguage
  } = useClientData();
  const [banners, setBanners] = useState();
  const [reservations, setReservations] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState();
  const [loading, setLoading] = useState(false);

  const iframePath = ALLOWED_IFRAMES.includes(window.location.host)
    ? '/:tokenIframe'
    : '';

  useEffect(() => {
    const payment = JSON.parse(localStorage.getItem('payment') || '{}');
    setPayment(payment);

    const currentProduct = JSON.parse(localStorage.getItem('currentProduct') || '{}');
    setCurrentProduct(currentProduct);

    let client = localStorage.getItem('clientData');
    client = client ? JSON.parse(client) : undefined;
    const bannersData = {
      data: client?.banners || {},
      time: null
    };
    setClientData(client);
    setBanners(bannersData);
    setLanguage(currentProduct?.config?.LOCALE || client?.client?.locale || 'es');

    getPaymentData();
    getClientData(client);

    ;(async () => {
      const ip = await getClientIpService();
      localStorage.setItem('ip', ip);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clientData === null) {
      getClientData();

      ;(async () => {
        const ip = await getClientIpService();
        localStorage.setItem('ip', ip);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData]);

  const getPaymentData = async () => {
    try {
      const token = localStorage.getItem('jwt');
      if (token) {
        const response = await getPayment(token);
        setPayment(response || null);
        localStorage.setItem('payment', JSON.stringify(response || null));
      }
    } catch (error) {
      window.location.href = '/login';
    }
  }

  const getClientData = async (clientDataAux = {}) => {
    const workUnitUrl = JSON.parse(localStorage.getItem('workUnit') || '{}')?.url;
    let client = workUnitUrl || window.location.host;

    if (iframePath) {
      client = window.location.pathname.split('/')[1];
    } else if (window.location.host.includes('localhost')) {
      client = urlLocal;
    }

    const data = { client };

    try {
      setLoading(true);
      const response = await getClient(data);

      //Descomentar para probar con json local
      // const response = configSite;
      if (response) {
        if (response.id !== client) {
          setClientData({});
          localStorage.removeItem('clientData');
          localStorage.removeItem('iframeData');
        } else {
          clientDataAux = {
            ...clientDataAux,
            ...response
          }
          localStorage.setItem('clientData', JSON.stringify(clientDataAux));

          const iframeData = JSON.parse(localStorage.getItem('iframeData') || '{}');
          let responseAutologin = null;
          if (iframePath && (iframeData?.client !== client || !iframeData?.time || dayjs().diff(dayjs.unix(iframeData?.time), 'day') >= 1)) {
            responseAutologin = await iframeAutologin(client);
          }

          const currentProduct = localStorage.getItem('currentProduct');
          if (!currentProduct) {
            setLanguage(response?.client?.locale || 'es');
          }

          if (responseAutologin) {
            clientDataAux = {
              ...clientDataAux,
              landings: responseAutologin.landings,
              modules: responseAutologin.modules
            }
            localStorage.setItem('clientData', JSON.stringify(clientDataAux));
          }

          setClientData(clientDataAux);

          const bannersAux = {
            data: clientDataAux.banners || {},
            time: null
          };
          setBanners(bannersAux);
          localStorage.setItem('banners', JSON.stringify(bannersAux));
        }
      }
      setLoading(false)
    } catch (e) {}
  };

  const getRedirectUrl = () => {
    const token = localStorage.getItem('jwt');
    let path = '';

    if (iframePath) {
      const iframeData = JSON.parse(localStorage.getItem('iframeData') || '{}');
      if (iframeData.client) {
        if (iframeData.client === clientData?.id) {
          path = `/${iframeData.client}`;
        } else if (window.location.pathname.split('/').length <= 2) {
          return `/${iframeData.client}`;
        }
      }
    }

    if (token && clientData?.modules?.[0]?.path) {
      path += clientData?.modules?.[0]?.path;
    } else {
      path += '/login';
    }

    return path;
  }

  const iframeAutologin = async (client) => {
    const dataIframe = { iframe: client };
    const res = await autologin(dataIframe);
    const iframeData = {
      client,
      time: dayjs().unix()
    }
    localStorage.setItem('iframeData', JSON.stringify(iframeData));
    localStorage.setItem('jwt', res.jwt);
    localStorage.setItem('hash_user', res.user.hashuser);

    const promises = [
      getProducts(res.jwt),
      getBanners(res.jwt),
      getLandings(res.jwt),
      getPayment(res.jwt)
    ];

    return Promise.all(promises).then(async ([products, banners, landings, payment]) => {
      setPayment(payment);
      localStorage.setItem('payment', JSON.stringify(payment));

      res.user.id = products.user_id;
      res.user.username = products.username;
      localStorage.setItem('user', JSON.stringify(res.user));

      const productsAux = products?.products || [];
      localStorage.setItem('products', JSON.stringify(productsAux));

      if (products.work_unit_relation[0]) {
        localStorage.setItem('workUnit', JSON.stringify(products.work_unit_relation[0]));
      }
      if (products.business_unit_relation[0]) {
        localStorage.setItem('businessUnit', JSON.stringify(products.business_unit_relation[0]));
      }

      const refTableDetails = new Set(productsAux.map(elem => elem.ref_table_detail));
      let modules = MODULES.filter(module => {
          let hasMatchingDetail = module.refTableDetail.some(detail => refTableDetails.has(detail));
          if (module.refTableDetail?.[0] === 'other_detail') {
            if (!productsAux.some(elem => elem.short_name.toLowerCase() === module.module)) {
              return false;
            }

            if (module.module === 'circuitos turisticos') {
              if (clientData?.client?.circuitosPath) {
                module.path = clientData.client.circuitosPath;
              } else {
                return false;
              }
            }
          }

          return hasMatchingDetail;
      });

      if (banners?.data) {
        localStorage.setItem('banners', JSON.stringify({
          ...banners,
          time: dayjs().unix()
        }));
      }

      return { landings, modules };
    });
  }

  if (!loading && clientData?.styles && banners) {
    const theme = createThemeFromData(clientData?.styles);

    const componentMapping = {
      hotels: GimmonixHomepage,
      trains: TrainsHomepage,
      flights: FlightsHomepage,
      assists: AssistsHomepage
    };

    const componentMappingResults = {
      hotels: {
        component: GimmonixResults,
        fixedElements: 2,
        path: '/resultados/:destination/:type/:pointInterest/:codes/:checkin/:checkout/0/0/:rooms/:nationality'
      },
      trains: {
        component: TrainsResults,
        fixedElements: 2,
        path: '/resultados/:ticketType/*'
      },
      flights: {
        component: FlightsResults,
        path: '/resultados/:tripType/:origins/:destinations/:datesDeparture/:datesReturn/:adults/:kids/:babys/:cabinClasses/:threeDays/:currency'
      },
      assists: {
        component: AssistsResults,
        fixedElements: 2,
        path: '/resultados/:nationality/:ages/:checkin/:checkout/:days/:destination/:resultStringAge/:type/0/:resultAge/:resultFlex/'
      }
    };

    const componentMappingFavorites = {
      hotels: GimmonixFavorites,
    };

    const getRoutes = () => {
      const components = [
        { path: '/login', element: <Login /> },
        { path: '/recover', element: <RecoverPassword client={clientData} /> }
      ];

      if (clientData?.client?.name === 'gotrenes') {
        components.push({
          path: '/trenes/register',
          fixedElements: 2,
          element: <Register client={clientData} />
        });
        components.push({
          path: '/trenes/condiciones-generales',
          fixedElements: 2,
          element: <CondicionesGenerales client={clientData} />
        });
      }

      if (clientData?.header?.showInfoTrenes) {
        components.push({ path: '/trenes/info', fixedElements: 2, element: <InfoPage /> });
      }

      if (clientData?.modules && Array.isArray(clientData?.modules)) {
        clientData.modules.forEach((item, index) => {
          const Component = componentMapping[item.module];
          const results = componentMappingResults[item.module];
          const ComponentResults = results?.component;
          const ComponentFavorites = componentMappingFavorites[item.module];

          if (Component) {
            components.push({
              key: `homePage_${index}`,
              path: iframePath + item.path,
              element: <Component selectedReservation={selectedReservation} />
            })
          }

          if (ComponentResults) {
            components.push({
              key: `results_${index}`,
              path: iframePath + `${item.path}${results.path}`,
              fixedElements: results.fixedElements,
              element: <ComponentResults client={clientData} module={item} />
            })
          }

          if (ComponentFavorites) {
            components.push({
              key: `favorites_${index}`,
              path: iframePath + `${item.path}/favoritos`,
              fixedElements: 2,
              element: <ComponentFavorites client={clientData} module={item} selectedReservation={selectedReservation} />
            })
          }
        })
      }
      return components;
    }

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles styles={globalStyles(theme, clientData?.styles)} />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
          <PuchaseDetailProvider>
            <Router>
              <BannersProvider value={banners} allowedIframes={ALLOWED_IFRAMES}>
                <ScrollToTop />
                <Routes>
                  {!clientData?.client ? (
                    <>
                      <Route path='/login' element={<DefaultLogin />} />
                      <Route path='/recover' element={<DefaultRecoverPassword />} />
                      <Route path='*' element={<Navigate to='/login' />} />
                    </>
                  ) : (
                    <>
                      <Route path={`${iframePath}/:module/prebooking/:hash`} element={
                        <PaymentProvider>
                          <LayoutCheckout
                            client={clientData}
                            reservations={reservations}
                            selectedReservation={selectedReservation}
                            isIframe={Boolean(iframePath)}
                            setReservations={setReservations}
                            handleSelectReservation={setSelectedReservation}
                          />
                        </PaymentProvider>
                      }/>
                      {[
                        `${iframePath}/checkout/:module/booking/id/:idTransaction/:reservationNumber`,
                        `${iframePath}/checkout/:module/error`
                      ].map((path, i) => (
                        <Route path={path} key={i} element={
                          <ThankYouPage
                            client={clientData}
                            reservations={reservations}
                            selectedReservation={selectedReservation}
                            isIframe={Boolean(iframePath)}
                            setReservations={setReservations}
                            handleSelectReservation={setSelectedReservation}
                          />
                        }/>
                      ))}
                      <Route path={`${iframePath}/checkout/:module/error404`} element={
                        <Error404
                          client={clientData}
                          reservations={reservations}
                          isIframe={Boolean(iframePath)}
                          setReservations={setReservations}
                          handleSelectReservation={setSelectedReservation}
                        />
                      }/>
                      {[
                        `${iframePath}/checkout/:module/error/disponibilidad`,
                        `${iframePath}/checkout/error/disponibilidad`
                      ].map((path, i) => (
                        <Route path={path} key={i} element={
                          <ErrorAvailability
                            client={clientData}
                            reservations={reservations}
                            isIframe={Boolean(iframePath)}
                            setReservations={setReservations}
                            handleSelectReservation={setSelectedReservation}
                          />
                        }/>
                      ))}
                      <Route path='/gracias' element={
                        <LayoutPreHome client={clientData}><ThanksPage /></LayoutPreHome>
                      } />
                      <Route path='/*' element={
                        <Routes>
                          {/* {clientData.client?.name === 'gotrenes' && (
                            <Route path='/' element={
                              <LayoutPreHome client={clientData}><PreHome /></LayoutPreHome>
                            } />
                          )} */}
                          <Route path='/*' element={
                            <Layout
                              client={clientData}
                              reservations={reservations}
                              selectedReservation={selectedReservation}
                              isIframe={Boolean(iframePath)}
                              routes={getRoutes().map(elem => ({ path: elem.path, fixedElements: elem.fixedElements }))}
                              redirectUrl={getRedirectUrl()}
                              setReservations={setReservations}
                              handleSelectReservation={setSelectedReservation}
                            >
                              <Routes>
                                {getRoutes().map((route, i) => (
                                  <Route key={i} {...route} />
                                ))}
                              </Routes>
                            </Layout>
                          }/>
                        </Routes>
                      }/>
                    </>
                  )}
                </Routes>
              </BannersProvider>
            </Router>
          </PuchaseDetailProvider>
        </LocalizationProvider>

        <ZohoBot showZohoBot={clientData?.footer?.showBot} />
      </ThemeProvider>
    );
  }
}

export default App;
