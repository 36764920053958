import axios from 'axios';

const getClientIp = async () => {
  let clientIp = localStorage.getItem('ip');
  if (!clientIp) {
    clientIp = await getClientIpService();
  }

  return clientIp;
}

const getClientIpService = async () => {
  try {
    const response = await axios.get('https://api.ipify.org');
    return response.data;
  } catch (error) {
    console.error('Error al obtener la dirección IP del cliente:', error);
  }
}

export {
  getClientIp,
  getClientIpService
}