import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Grid, Hidden, Pagination, Skeleton } from '@mui/material';
import { ArrowDownward, ArrowUpward, GpsFixed } from '@mui/icons-material';
import HotelCard from './HotelCard';
import { useTranslation } from 'react-i18next';

const SORTING_OPTIONS = [
  { name: 'recommended', text: 'recommended'},
  { name: 'price', text: 'price'},
  { name: 'stars', text: 'stars'},
  { name: 'distance', text: 'distance', type: 'pointInterest'}
];

const ResultsComponent = ({
  results,
  loading,
  activeSort,
  resultsToCompare,
  providers,
  favorites,
  showDistance,
  destination,
  hashFlow,
  handleChangeSort,
  handleClickHotel,
  handleClickCompareHotel,
  handleClickFavorite
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    setCurrentPage(search.get('pag') ? parseInt(search.get('pag')) : 1);
  }, [location])

  useEffect(() => {
    if (results && Math.ceil(results.length / 20) < currentPage) {
      handleChangePage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results])

  const handleChangeSortAux = (option) => {
    let newActiveSort = {};
    newActiveSort.name = option.name;

    if (option.name === 'distance') {
      newActiveSort.sort = 'shorter';
    } else if (option.name !== 'recommended') {
      newActiveSort.sort = (activeSort?.name !== option.name || activeSort?.sort === 'descending') ? 'ascending' : 'descending';
    } else if (activeSort.name === newActiveSort.name) {
      return;
    }

    handleChangeSort && handleChangeSort(newActiveSort);
  }

  const handleClickHotelAux = (view, hotel) => {
    if (hotel) {
      handleClickHotel(view, hotel);
    } else {
      const index = (currentPage - 1) * 20;
      handleClickHotel(view, results[index]);
    }
  }

  const handleChangePage = (page) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    const scrollInterval = setInterval(() => {
      if (window.scrollY === 0) {
        clearInterval(scrollInterval);
        setCurrentPage(page);
        const search = new URLSearchParams(location.search);
        if (!search.get('searchId')) {
          search.append('searchId', hashFlow);
        }
        search.delete('pag');
        search.append('pag', page)
        search.append('pag', page);
        const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
        const url = iframePath + `${location.pathname}?${search.toString()}`;
        navigate(url);
      }
    }, 50);
  }

  const getResults = () => {
    const startIndex = (currentPage - 1) * 20;
    const endIndex = currentPage * 20;
    return results?.slice(startIndex, endIndex);
  }

  const getProviderLogo = (provider) => {
    const providerName = provider.replaceAll(' ', '').toLowerCase();
    return providers.find(elem => providerName.includes(elem.name))?.image;
  }

  const isFavorite = (hotel) => {
    if (hotel) {
      const hotelId = hotel.id.toString();
      return favorites.find(elem => elem.id === hotelId) ? true : false;
    }

    return false;
  }

  return (
    <Grid className='results-component'>
      <Grid className='row' justifyContent='space-between' marginBottom={2} container spacing={2}>
        <Grid item xs={12} md={8}>
          {t('results.hotels.sortBy')}:
          {SORTING_OPTIONS.map((option, i) => (
            (option.type !== 'pointInterest' || showDistance) && (
              <Button
                key={i}
                className={`sort-button ${activeSort?.name === option.name ? 'active' : ''}`}
                disabled={loading}
                onClick={() => handleChangeSortAux(option)}
              >
                {t(`results.hotels.${option.text}`)}
                {option.name !== 'recommended' && option.name !== 'distance' && activeSort?.name === option.name && (
                  activeSort.sort === 'ascending' ? <ArrowUpward /> : <ArrowDownward />
                )}
              </Button>
            )
          ))}
        </Grid>
        <Grid item xs={12} md={4} textAlign='right'>
          <Button
            variant='outlined'
            size='small'
            className='map-button'
            disabled={loading}
            onClick={() => handleClickHotelAux('map', null)}
          >
            <GpsFixed fontSize='small' /> {t('results.hotels.seeHotelsInMap')}
          </Button>
        </Grid>
      </Grid>

      <Grid>
        {loading ? (
          Array.from(Array(3).keys()).map(elem => (
            <Grid className='hotel-card skeleton' container key={elem}>
              <Grid item xs={4} lg={3} xl={4}>
                <Skeleton height='100%' width='100%' className='image-skeleton' />
              </Grid>
              <Grid
                container
                item
                className='row info-container'
                xs={8}
                lg={9}
                xl={8}
                sx={{ padding: '0px 24px' }}
              >
                <Grid className='left-side' item xs={12} md={8} lg={8}>
                  <Skeleton width={300} height={35} variant='rounded' />
                  <Skeleton width={150} height={25} variant='rounded' />
                  <Skeleton width={150} height={25} variant='rounded' />
                  <Skeleton width={150} height={25} variant='rounded' />
                  <Skeleton width={150} height={25} variant='rounded' />
                  <Skeleton width={150} height={25} variant='rounded' />
                </Grid>
                <Hidden mdDown>
                  <Grid className='right-side' item md={4} lg={4}>
                    <Skeleton width={150} height={25} variant='rounded' />
                    <Skeleton width={150} height={25} variant='rounded' />
                    <Skeleton width={150} height={25} variant='rounded' />
                    <Skeleton width={150} height={25} variant='rounded' />
                    <Skeleton width={150} height={25} variant='rounded' />
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          ))
        ) : (
          getResults()?.map((hotel, i) => (
            <HotelCard
              key={i}
              hotel={hotel}
              disabledCompare={resultsToCompare.length >= 4}
              selectedToCompare={Boolean(resultsToCompare.find(elem => elem.id === hotel.id))}
              provider={getProviderLogo(hotel.providerName)}
              isFavorite={isFavorite(hotel)}
              showDistance={showDistance}
              destination={destination}
              hashFlow={hashFlow}
              handleClickCompare={checked => handleClickCompareHotel(hotel, checked)}
              handleClickShowMap={() => handleClickHotelAux('map', hotel)}
              handleClickShowDetail={() => handleClickHotelAux('details', hotel)}
              handleClickShowRooms={() => handleClickHotelAux('room', hotel)}
              handleClickFavorite={handleClickFavorite}
            />
          ))
        )}
      </Grid>

      {!loading && results && results.length > 0 && (
        <Grid className='pagination-container'>
          <Pagination
            page={currentPage}
            onChange={(e, value) => handleChangePage(value)}
            count={Math.ceil(results.length / 20)}
            color="primary"
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ResultsComponent;
