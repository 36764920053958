import { createContext, useContext, useState } from 'react';

const LayoutContext = createContext();

export const LayoutProvider = ({ children }) => {
  const [showModules, setShowModules] = useState(true);

  return (
    <LayoutContext.Provider value={{ showModules, setShowModules }}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => {
  const context = useContext(LayoutContext);

  if (context === undefined) {
    throw new Error('useLayoutContext debe ser usado dentro de un LayoutProvider');
  }

  return context;
};
