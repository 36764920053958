import React from 'react';
import { Button, Grid, Hidden, Icon, IconButton, Rating, Tooltip, Typography } from '@mui/material';
import ImagesSlider from '../../../common/ImagesSlider';
import { Favorite, LocalCafe, LocalHotel, LocationOn, RoomService } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const AMENITIES = [
  { code: 14040, icon: 'wifi' },
  { code: 11890, icon: 'local_parking' },
  { code: 13530, icon: 'pool' },
  { code: 10080, icon: 'ac_unit' },
  { code: 12810, icon: 'pets' },
  { code: 13840, icon: 'tv' }
];

const BOARDBASES = {
  'Todo Incluido': 'allInclusive',
  'Solo Habitación': 'onlyRoom',
  'Desayuno': 'breakfast',
  'Media Pension': 'halfPension'
};

const HotelCard = ({ hotel, handleDeleteFavorite, ...props }) => {
  const { t } = useTranslation();

  return (
    <Grid className={`hotel-card ${hotel.recommended ? 'recommended' : ''}`}>
      {hotel.recommended && (
        <Grid className='row recommended-container'>
          <RoomService /> {t('results.hotels.hotelRecommended')}
        </Grid>
      )}

      <Grid container className='hotel-content'>
        <Grid item className='image-container' xs={4} lg={3} xl={4}>
          <Hidden smDown>
            <ImagesSlider images={hotel.images?.slice(0, 5)} height='100%' />
          </Hidden>
          <Hidden smUp>
            <img className='image-small' alt='hotel' src={hotel.image} />
          </Hidden>

          <IconButton className='favorite-button' onClick={() => handleDeleteFavorite && handleDeleteFavorite(hotel)}>
            <Favorite className='favorite-icon' />
          </IconButton>
        </Grid>

        <Grid container item className='row info-container' xs={8} lg={9} xl={8}>
          <Grid item className='left-side' sm={12} md={8}>
          <Typography className='destination'>{hotel.destination}</Typography>
            <Tooltip title={hotel.name}>
              <Typography className='name'>{hotel.name}</Typography>
            </Tooltip>
            <Grid className='row'>
              <Typography className='row location'><LocationOn fontSize='small' />{hotel.address}</Typography>
              <Rating value={parseInt(hotel.stars)} readOnly />
            </Grid>
            <Grid className='row'>
              <Button size='small' onClick={props.handleClickShowDetail}>
                {t('results.hotels.seeHotelDetails')}
              </Button>
              <Button size='small' onClick={props.handleClickShowMap}>
                {t('results.hotels.seeHotelInMap')}
              </Button>
            </Grid>
            <Grid container className='icons'>
              <Grid item md={12} className='row amenities'>
                {AMENITIES
                  .filter(amenity => hotel.amenities.find(elem => elem.code === amenity.code))
                  .map((amenity, i) => (
                    <Icon key={i}>{amenity.icon}</Icon>
                  ))
                }
                <Typography className='row boardbases'>
                  {hotel.boardbases.includes('Solo Habitacion') ? (
                    <><LocalHotel /> {t('results.hotels.onlyRoom')}</>
                  ) : <><LocalCafe /> {BOARDBASES[hotel.boardbases[0]]
                    ? t(`results.hotels.${BOARDBASES[hotel.boardbases[0]]}`)
                    : hotel.boardbases[0]
                  }</>}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className='right-side' md={4}>
            <Button size='small' variant='contained' color='secondary' onClick={() => props.handleSelectHotel(hotel)}>
              {t('results.hotels.selectDates')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HotelCard;
