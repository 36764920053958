import React, { Fragment, useEffect, useState } from 'react'
import { CardContent, Container, Grid, Typography } from '@mui/material';
import StatusMessage from '../StatusMessage';
import CardStatusTransaction from '../CardStatusTransaction';
import { useNavigate, useParams } from 'react-router-dom';
import { REJECTED } from './utils';
import { connect } from 'react-redux';
import { resetReservationAction } from '../../../../store/actions';
import { useTranslation } from 'react-i18next';
import { useClientData } from '../../../../context/ClientContext';
import PurchaseDetail from '../PurchaseDetail';
import SecureSite from '../SecureSite';

const Rejected = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const { clientData } = useClientData();
  const [content, setContent] = useState({});

  useEffect(() => {
    const { content } = REJECTED[params.module] || { content: {} };

    if (props.paymentData?.name === 'paypal' || props.paymentData?.name === 'braspag') {
      content.title = 'rejectedPayment';
      content.status_booking_title = 'pendingIssue';
      content.status_booking = 'pending';
      content.show_status_payment = true;
      content.status_booking_icon = 'error';
      content.status_payment_title = 'rejectedPayment';
      content.status_payment = 'ko';
      content.status_payment_icon = 'cancel';
      content.messages = [
        {
          subtitle: 'rejectedPaymentMessage',
          hideInfoIcon: true,
          addNewSearchButton: true,
          newSearchButtonTitle: 'continueBooking'
        }
      ]
    }

    content.show_booking_detail = props.bookingDuplicated;
    content.booking_detail_message = props.bookingDetail;

    setContent(content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CardContentStatusTransaction = () => (
    <CardStatusTransaction
      reservationNumber={params.reservationNumber}
      content={content}
      handleSelectReservation={handleSelectReservation}
    />
  )

  const CardContentMessage = () => {
    return (
      <Grid>
        {props.email && <CardContent className="text-center card-divider">
          <div className="content card-content-booking">
            <Typography>{t('checkout.thankYouPage.receiveReservationTo')} <strong>{props.email}</strong></Typography>
            <Typography>{t('checkout.thankYouPage.spam')}</Typography>
          </div>
        </CardContent>}
      </Grid>
    )
  }

  const getRedirectUrl = () => {
    const module = clientData?.modules && Array.isArray(clientData?.modules) && clientData?.modules?.filter(elem => elem.module === params.module)?.slice(-1)?.[0];
    return module?.path || '/';
  }

  const handleSelectReservation = (reservationNumber = null) => {
    const reservation = props.reservations?.find(elem => elem.number === reservationNumber);
    props.handleSelectReservation && props.handleSelectReservation(reservation || null);
    if (reservationNumber && reservation) {
      localStorage.setItem('selectedReservation', JSON.stringify(reservation));
    } else {
      localStorage.removeItem('selectedReservation');
    }
    props.resetReservation();
    navigate(getRedirectUrl());
  }

  return (
    <Container className='booking-status-ko'>
      <Grid container spacing={2}>
        <Grid item md={8} className='col-content'>
          <StatusMessage
            title={content.title}
            icon={content.icon}
            statusMessage={content.statusMessage}
            cardContentTop={<Fragment />}
            cardContentStatusTransaction={<CardContentStatusTransaction />}
            cardContentMessage={<CardContentMessage />}
          />
        </Grid>

        {props.purchaseDetail && (
          <Grid item md={4} className="col-sidebar">
            <PurchaseDetail
              purchaseDetail={props.purchaseDetail}
              products={props.products}
              module={params.module}
            />
            <SecureSite client={props.client?.client} />
          </Grid>
        )}
      </Grid>
    </Container>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    resetReservation: () => dispatch(resetReservationAction())
  };
}

export default connect(null, mapDispatchToProps)(Rejected);
