import { combineReducers } from 'redux';
import gimmonixReducer from './GimmonixReducer';
import trainsReducer from './TrainsReducer';
import flightsReducer from './FlightsReducer';
import checkoutReducer from './CheckoutReducer';
import pixReducer from './PixReducer';
import reservationReducer from './ReservationReducer';

export default combineReducers({
  gimmonixReducer,
  trainsReducer,
  flightsReducer,
  checkoutReducer,
  pixReducer,
  reservationReducer
});
