import React, { useEffect, useMemo } from 'react';
import { Button, Container, Typography } from '@mui/material';
import AllBanners from '../../common/Banners/AllBanners';
import RawMarkup from '../../common/RawMarkup';
import Navbar from '../../layout/Navbar';

const Landing = ({ landing }) => {

  const token = localStorage.getItem('jwt');

  useEffect(() => {
    if (landing) {
      const meta = {
        ...(landing.meta && typeof landing.meta === 'object' ? landing.meta : {}),
        description: landing.meta?.description || landing.description,
      };
      const title = meta.title || landing.title;

      if (title) {
        document.title = title;
      }

      Object.entries(meta).forEach(([key, value]) => {
        if (key && value) {
          const metaTag = document.querySelector(`meta[name='${key}']`);
          if (metaTag) {
            metaTag.setAttribute('content', value);
          } else {
            const newMetaTag = document.createElement('meta');
            newMetaTag.setAttribute('name', key);
            newMetaTag.setAttribute('content', value);
            document.head.appendChild(newMetaTag);
          }
        }
      })
    }
  }, [landing])

  const getBanners = useMemo(() => (banners) => {
    const bannersRelation = banners.map((elem) => elem.banner_relation);
    const bannersAux = {
      Slider: bannersRelation.filter((elem) => elem.banner_type_relation.short_name === 'Slider'),
      ImageCards: bannersRelation.filter((elem) => elem.banner_type_relation.short_name === 'ImageCards'),
      TextImageCards: bannersRelation.filter((elem) => elem.banner_type_relation.short_name === 'TextImageCards'),
      ProductCards: bannersRelation.filter((elem) => elem.banner_type_relation.short_name === 'ProductCards'),
      HorizontalCardSlider: bannersRelation.filter(
        (elem) => elem.banner_type_relation.short_name === 'HorizontalCardSlider'
      ),
      FullWidth: bannersRelation.filter((elem) => elem.banner_type_relation.short_name === 'FullWidth'),
    };

    return <AllBanners banners={bannersAux} />;
  }, []);

  return (
    <div className='landing-page'>
      {token && landing?.form_visible && (
        <Navbar defaultModule={landing.default_form} />
      )}

      <Container className='landing-container'>
        <div className='content'>
          {landing?.sectors?.sort((a, b) => a.order - b.order).map((sector, i) => (
            (sector.html_content || (sector.banners && sector.banners.length > 0)) && (
              <div key={i}>
                {sector.title && (
                  <Typography variant='h3' component='h2' className='sector-title' color='primary'>
                    {sector.title}
                    {sector.text_link && sector.link && (
                      <Button
                        variant='contained'
                        href={sector.link}
                        target='_blank'
                        style={{ marginLeft: 10 }}
                      >
                        {sector.text_link}
                      </Button>
                    )}
                  </Typography>
                )}
                {sector.html_content && (
                  <div className='se-container'>
                    <RawMarkup customClassName='sun-editor-editable' content={sector.html_content} />
                  </div>
                )}
                {sector.banners && sector.banners.length > 0 && getBanners(sector.banners)}
              </div>
            )
          ))}
        </div>
      </Container>
    </div>
  );
}

export default Landing;
