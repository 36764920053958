import React, { useEffect } from 'react';
import Navbar from '../../layout/Navbar';
import { checkToken } from '../../../store/services/Login';
import { useNavigate } from 'react-router';
import { useBannersData } from '../../../context/BannersContext';
import AllBanners from '../../common/Banners/AllBanners';
import { useClientData } from '../../../context/ClientContext';

function HomePage({ selectedReservation }) {
  const navigate = useNavigate();
  const { bannersData } = useBannersData();
  const { updateProductToken } = useClientData();

  useEffect(() => {
    ;(async () => {
      try {
        const token = localStorage.getItem('jwt');
        await checkToken(token);
        updateProductToken(['assistance_detail']);
      } catch (error) {
        navigate('/login', { state: { expiredSession: true } });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='assists-home homepage'>
      <Navbar selectedReservation={selectedReservation} />

      <AllBanners
        banners={bannersData?.data?.modules?.['ASISTENCIA']?.flows?.home?.banners || {}}
        titlePromotions='Las últimas novedades de asistencias'
        // titleOffers='Las mejores ofertas hoteleras por el mundo'
        // titleFeatured='Las mejores ofertas hoteleras por el mundo'
      />
    </div>
  );
}

export default HomePage;