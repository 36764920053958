import { all } from 'redux-saga/effects';
import { searchSagaGimmonix, availabilitySagaGimmonix, favoritesSagaGimmonix } from './sagaGimmonix';
import { checkoutSaga } from './sagaCheckout';
import { pixSaga } from './sagaPix';
import { reservationSaga } from './sagaReservation';
import { searchSagaTrains } from './sagaTrains';
import { searchSagaFlights } from './sagaFlights';

export default function* rootSaga() {
    yield all([
        searchSagaGimmonix(),
        availabilitySagaGimmonix(),
        favoritesSagaGimmonix(),
        checkoutSaga(),
        pixSaga(),
        reservationSaga(),
        searchSagaTrains(),
        searchSagaFlights()
    ])
}