import React from 'react';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';

const InputWrapper = ({
  children,
  label,
  error,
  helperText,
  required = false,
  direction = 'column',
  className = ''
}) => (
  <FormControl fullWidth className={`input-wrapper direction-${direction} ${className}`}>
    <div>
      {label && (
        <FormLabel>
          {label} {required && <span className='required'>*</span>}
        </FormLabel>
      )}
      <div>
        {children}
        {helperText &&
          <FormHelperText error={error}>{helperText}</FormHelperText>
        }
      </div>
    </div>
  </FormControl>
)

export default InputWrapper;
