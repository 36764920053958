import { all, call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { getCredentials } from './util';
import dayjs from 'dayjs';

function* searchActionGimmonix(actions) {
  const requestOptionsRule = {
    headers: {
      'Content-Type': "application/json",
      'authentication-token': actions.token
    }
  }

  const requestOptions = {
    headers: {
      'Content-Type': "application/json"
    }
  };

  if (actions.signalAbort) {
    requestOptions.signal = actions.signalAbort
  }

  try {
    const responseRule = yield call(axios.post, `${getCredentials().urlRules}business-rule/applyRule`, actions.queryRule, requestOptionsRule);
    const startTime = dayjs().unix();
    try {
      const query = {
        ...actions.query,
        business: responseRule?.data?.data || {}
      }
      const response = yield call(axios.post, `${getCredentials().urlSearch}search`, query, requestOptions);
      const endTime = dayjs().unix()
      response.data.responseTime = endTime - startTime;
      yield put({ type: 'GET_PRODUCT_SEARCH_SUCCESS_GIMMONIX', payload: response.data });
    } catch (error) {
      const endTime = dayjs().unix()
      error.responseTime = endTime - startTime;
      yield put({ type: 'GET_PRODUCT_SEARCH_ERROR_GIMMONIX', payload: error });
    }
  } catch (error) {
    yield put({ type: 'GET_RULE_ERROR_GIMMONIX_GIMMONIX', payload: error.message });
  }
}

function* cacheActionGimmonix(actions) {
  const requestOptions = {
    headers: {
      'Content-Type': "application/json"
    }
  };

  if (actions.signalAbort) {
    requestOptions.signal = actions.signalAbort
  }

  try {
    const response = yield call(axios.post, `${getCredentials().url}hotels/search_cache`, actions.dataToSend, requestOptions);
    yield put({ type: 'GET_CACHE_SEARCH_SUCCESS_GIMMONIX', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_CACHE_SEARCH_ERROR_GIMMONIX', payload: error.message });
  }
}

function* getFiltersActionGimmonix(actions) {
  const headers = {
    'Content-Type': "application/json"
  };

  const requestOptions = {
    headers: headers
  };

  if (actions.signalAbort) {
    requestOptions.signal = actions.signalAbort
  }

  try {
    const response = yield call(axios.get, `${getCredentials().url}hotels/info${actions.query}`, requestOptions);
    yield put({ type: 'GET_PRODUCT_FILTERS_SUCCESS_GIMMONIX', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_PRODUCT_FILTERS_ERROR_GIMMONIX', payload: error.message });
  }
}

function* filterActionGimmonix(actions) {
  const headers = {
    'Content-Type': "application/json"
  };

  const requestOptions = {
    headers: headers
  };

  if (actions.signalAbort) {
    requestOptions.signal = actions.signalAbort
  }

  try {
    const response = yield call(axios.get, `${getCredentials().url}hotels/filter${actions.query}`, requestOptions);
    yield put({ type: 'GET_PRODUCT_FILTER_SUCCESS_GIMMONIX', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_PRODUCT_FILTER_ERROR_GIMMONIX', payload: error?.response?.data?.error || error?.message });
  }
}

function* sendMetricSearchActionGimmonix(actions) {
  const headers = {
    'Content-Type': "application/json"
  };

  const requestOptions = {
    headers: headers
  };

  if (actions.signalAbort) {
    requestOptions.signal = actions.signalAbort
  }

  try {
    const response = yield call(axios.post, `${getCredentials().urlMetrics}hotel_metrics/search`, actions.data, requestOptions);
    yield put({ type: 'POST_HOTEL_METRICS_SEARCH_SUCCESS_GIMMONIX', payload: response.data });
  } catch (error) {
    yield put({ type: 'POST_HOTEL_METRICS_SEARCH_ERROR_GIMMONIX', payload: error?.message });
  }
}

function* availabilityActionGimmonix(actions) {
  const headers = {
    'Content-Type': "application/json"
  };

  const requestOptions = {
    headers: headers
  };

  if (actions.signalAbort) {
    requestOptions.signal = actions.signalAbort
  }

  const startTime = dayjs().unix();
  try {
    const response = yield call(axios.post, `${getCredentials().url}hotels/availabilities`, actions.data, requestOptions);
    const endTime = dayjs().unix()
    response.data.responseTime = endTime - startTime;
    if (response?.data?.error) {
      yield put({ type: 'GET_PRODUCT_AVAILABILITY_ERROR_GIMMONIX', payload: response.data });
    } else {
      yield put({ type: 'GET_PRODUCT_AVAILABILITY_SUCCESS_GIMMONIX', payload: response.data });
    }
  } catch (error) {
    const endTime = dayjs().unix()
    error.responseTime = endTime - startTime;
    yield put({ type: 'GET_PRODUCT_AVAILABILITY_ERROR_GIMMONIX', payload: error });
  }
}

function* availabilityParallelActionGimmonix(actions) {
  const headers = {
    'Content-Type': "application/json"
  };

  const requestOptions = {
    headers: headers
  };

  if (actions.signalAbort) {
    requestOptions.signal = actions.signalAbort
  }

  try {
    const responses = yield all(actions.requests.map(request => (
      call(axios.post, `${getCredentials().url}hotels/availability`, request, requestOptions)
    )))
    yield put({ type: 'GET_PRODUCT_AVAILABILITY_PARALLEL_SUCCESS_GIMMONIX', payload: responses.map(elem => elem.data) });
  } catch (error) {
    yield put({ type: 'GET_PRODUCT_AVAILABILITY_PARALLEL_ERROR_GIMMONIX', payload: error.message });
  }
}

function* availabilityDetailOnlyActionGimmonix(actions) {
  const headers = {
    'Content-Type': "application/json"
  };

  const requestOptions = {
    headers: headers
  };

  if (actions.signalAbort) {
    requestOptions.signal = actions.signalAbort
  }

  try {
    const response = yield call(axios.post, `${getCredentials().url}hotels/availabilities`, actions.data, requestOptions);
    if (response?.data?.error) {
      yield put({ type: 'GET_PRODUCT_AVAILABILITY_DETAIL_ONLY_ERROR_GIMMONIX', payload: response.data });
    } else {
      yield put({ type: 'GET_PRODUCT_AVAILABILITY_DETAIL_ONLY_SUCCESS_GIMMONIX', payload: response.data });
    }
  } catch (error) {
    yield put({ type: 'GET_PRODUCT_AVAILABILITY_DETAIL_ONLY_ERROR_GIMMONIX', payload: error.message });
  }
}

function* cancelationPolicyActionGimmonix(actions) {
  const headers = {
    'Content-Type': "application/json"
  };

  const requestOptions = {
    headers: headers
  };

  try {
    const response = yield call(axios.post, `${getCredentials().url}hotels/cancel_policy`, actions.data, requestOptions);
    yield put({ type: 'GET_CANCELATION_POLICY_SUCCESS_GIMMONIX', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_CANCELATION_POLICY_ERROR' });
  }
}

function* moreAvailabilityActionGimmonix(actions) {
  const headers = {
    'Content-Type': "application/json"
  };

  const requestOptions = {
    headers: headers
  };

  if (actions.signalAbort) {
    requestOptions.signal = actions.signalAbort
  }

  try {
    const response = yield call(axios.post, `${getCredentials().url}hotels/get_availabilities`, actions.data, requestOptions);
    yield put({ type: 'GET_MORE_PRODUCT_AVAILABILITY_SUCCESS_GIMMONIX', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_MORE_PRODUCT_AVAILABILITY_ERROR_GIMMONIX', payload: error.message });
  }
}

function* sendMetricAvailabilityActionGimmonix(actions) {
  const headers = {
    'Content-Type': "application/json"
  };

  const requestOptions = {
    headers: headers
  };

  if (actions.signalAbort) {
    requestOptions.signal = actions.signalAbort
  }

  try {
    const response = yield call(axios.post, `${getCredentials().urlMetrics}hotel_metrics/availability`, actions.data, requestOptions);
    yield put({ type: 'POST_HOTEL_METRICS_AVAILABILITY_SUCCESS_GIMMONIX', payload: response.data });
  } catch (error) {
    yield put({ type: 'POST_HOTEL_METRICS_AVAILABILITY_ERROR_GIMMONIX', payload: error?.message });
  }
}

function* getFavoritesActionGimmonix(actions) {
  const headers = {
    'Content-Type': "application/json"
  };

  const requestOptions = {
    headers: headers
  };

  if (actions.signalAbort) {
    requestOptions.signal = actions.signalAbort
  }

  try {
    const response = yield call(axios.get, `${getCredentials().urlFavorite}favorite/${actions.user}`, requestOptions);
    yield put({ type: 'GET_FAVORITES_SUCCESS_GIMMONIX', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_FAVORITES_ERROR_GIMMONIX', payload: error.message });
  }
}

function* addFavoriteActionGimmonix(actions) {
  const headers = {
    'Content-Type': "application/json"
  };

  const requestOptions = {
    headers: headers
  };

  try {
    const response = yield call(axios.post, `${getCredentials().urlFavorite}favorite`, actions.data, requestOptions);
    yield put({ type: 'ADD_FAVORITE_SUCCESS', payload: response.data });
  } catch (error) {
    yield put({ type: 'ADD_FAVORITE_ERROR', payload: error.message });
  }
}

function* deleteFavoriteActionGimmonix(actions) {
  const headers = {
    'Content-Type': "application/json"
  };

  const requestOptions = {
    headers: headers
  };

  try {
    const response = yield call(axios.delete, `${getCredentials().urlFavorite}favorite/${actions.user}/hotels/${actions.hotelId}`, requestOptions);
    yield put({ type: 'DELETE_FAVORITE_SUCCESS', payload: response.data });
  } catch (error) {
    yield put({ type: 'DELETE_FAVORITE_ERROR', payload: error.message });
  }
}

export function* searchSagaGimmonix() {
  yield takeEvery('GET_PRODUCT_SEARCH_GIMMONIX', searchActionGimmonix);
  yield takeEvery('GET_CACHE_SEARCH_GIMMONIX', cacheActionGimmonix);
  yield takeEvery('GET_PRODUCT_FILTERS_GIMMONIX', getFiltersActionGimmonix);
  yield takeEvery('GET_PRODUCT_FILTER_GIMMONIX', filterActionGimmonix);
  yield takeEvery('POST_HOTEL_METRICS_SEARCH_GIMMONIX', sendMetricSearchActionGimmonix);
}

export function* availabilitySagaGimmonix() {
  yield takeEvery('GET_PRODUCT_AVAILABILITY_GIMMONIX', availabilityActionGimmonix);
  yield takeEvery('GET_PRODUCT_AVAILABILITY_PARALLEL_GIMMONIX', availabilityParallelActionGimmonix);
  yield takeEvery('GET_PRODUCT_AVAILABILITY_DETAIL_ONLY_GIMMONIX', availabilityDetailOnlyActionGimmonix);
  yield takeEvery('GET_CANCELATION_POLICY_GIMMONIX', cancelationPolicyActionGimmonix);
  yield takeEvery('GET_MORE_PRODUCT_AVAILABILITY_GIMMONIX', moreAvailabilityActionGimmonix);
  yield takeEvery('POST_HOTEL_METRICS_AVAILABILITY_GIMMONIX', sendMetricAvailabilityActionGimmonix);
}

export function* favoritesSagaGimmonix() {
  yield takeEvery('GET_FAVORITES_GIMMONIX', getFavoritesActionGimmonix);
  yield takeEvery('ADD_FAVORITE_GIMMONIX', addFavoriteActionGimmonix);
  yield takeEvery('DELETE_FAVORITE_GIMMONIX', deleteFavoriteActionGimmonix);
}
