import React from 'react'
import { Grid, Typography } from '@mui/material';
import Image from '../../common/Image';
import { MedicalServices, PanToolAltOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const AssistComponent = () => {
  const { t } = useTranslation();
  const baseUrl = window.location.origin;
  
  const handleClickAssist = () => {
    window.open(`${baseUrl}/asistencia/`, '_blank');
  }

  return (
    <Grid className='assist-container' onClick={handleClickAssist}>
      <Grid className='left-side row'>
        <PanToolAltOutlined className='hand-icon' />
        <MedicalServices />
        <Typography>{t('checkout.common.addAssitance')}</Typography>
      </Grid>
      <Grid className='right-side'>
        <Image img='assist-logo-blanco.png' alt='assist-logo' />
      </Grid>
    </Grid>
  )
}

export default AssistComponent;
