import { createContext, useContext, useEffect, useState } from 'react';
import { getCurrencies } from '../store/services/Login';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import 'dayjs/locale/pt';

const ClientContext = createContext();

export const ClientProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [clientData, setClientData] = useState();
  const [payment, setPayment] = useState();
  const [currentProduct, setCurrentProduct] = useState();
  const [language, setLanguage] = useState('es');

  useEffect(() => {
    i18n.changeLanguage(language || 'es');
    dayjs.locale(language || 'es');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const updateProductToken = async (products) => {
    const accessToken = localStorage.getItem('jwt');
    const allProducts = JSON.parse(localStorage.getItem('products') || '[]');
    const workUnit = JSON.parse(localStorage.getItem('workUnit') || '{}');
    const product = allProducts?.find(elem => products?.includes(elem.ref_table_detail));
    if (accessToken && product?.token_id) {
      try {
        const response = await getCurrencies(accessToken, product.token_id);
        if (!product.config_work_unit && workUnit.config) {
          product.config_work_unit = workUnit.config;
        }
        product.currencies = response?.sections?.[0]?.changes || [];
        setCurrentProduct(product);
        setLanguage(product?.config?.LOCALE || 'es')
        localStorage.setItem('currentProduct', JSON.stringify(product));
        return product.config?.CURRENCY_TO || product.config?.currency_to || clientData?.client?.defaultCurrency || 'USD';
      } catch (e) {
        setCurrentProduct({});
        localStorage.setItem('currentProduct', JSON.stringify({}));
        return clientData?.client?.defaultCurrency || 'USD';
      }
    }
  }

  return (
    <ClientContext.Provider value={{
        clientData,
        setClientData,
        payment,
        setPayment,
        currentProduct,
        setCurrentProduct,
        language,
        setLanguage,
        updateProductToken
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

// Hook personalizado para acceder al contexto fácilmente
export const useClientData = () => {
  const context = useContext(ClientContext);

  if (context === undefined) {
    throw new Error('useClientData debe ser usado dentro de un ClientProvider');
  }

  return context;
};
