import { Grid } from '@mui/material'

const getProductsDetails = (purchaseDetail) => {
  return purchaseDetail?.priceDetail?.map((item, i) => (
    <Grid
      key={i}
      container
      className='item-resume'
    >
      <Grid item md={8} xl={9}>
        <div className='container-item-resume row'>
          <span>{item.title}</span>
        </div>
      </Grid>
      <Grid item md={4} xl={3}>
        <div className={`text-right`}>
          <span>
            {item.currency} {item.amount}
          </span>
        </div>
      </Grid>
    </Grid>
  ))
}

export {
  getProductsDetails
};
