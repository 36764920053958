import React from 'react';
import { Document, Font, Image, Page, Path, Svg, Text, View } from '@react-pdf/renderer';
import { faCheck, faDollarSign, faLocationDot, faStar } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const FontAwesomeIcon = ({ faIcon: { icon }, style = {} }) => {
  const duotone = Array.isArray(icon[4]);
  const paths = Array.isArray(icon[4]) ? icon[4] : [icon[4]];
  const color = style.color || 'black';
  return (
    <Svg viewBox={`0 0 ${icon[0]} ${icon[1]}`} style={style}>
      {paths &&
        paths.map((d, index) => (
          <Path
            d={d}
            key={index}
            fill={color}
            fillOpacity={duotone && index === 0 ? 0.4 : 1.0}
          />
        ))}
    </Svg>
  );
};

export const PDFComparation = ({ client, searchData, resultsToCompare, activeAmenities, providers }) => {
  const { t } = useTranslation();

  const styles = {
    page: {
      margin: '15px 20px'
    },
    title: {
      fontSize: 19,
      color: client.styles.brandPrimary.value
    },
    searchDataContainer: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      marginTop: 10,
      marginBottom: 20,
      fontSize: 10
    },
    searchData: {
      flexDirection: 'row',
      marginRight: 20,
      color: '#606060'
    },
    searchField: {
      color: '#000'
    },
    hotelsContainer: {
      flexDirection: 'row'
    },
    amenitiesColumn: {
      width: 80
    },
    amenityContainer: {
      backgroundColor: '#e1eaff',
      padding: 8,
      color: client.styles.brandPrimary.value,
      marginBottom: 3,
      fontSize: 8
    },
    hotelColumn: {
      width: 108,
      marginLeft: 10,
      flexDirection: 'column'
    },
    recommendedHotel: {
      color: '#155DFF',
      fontSize: 8,
      marginBottom: 10,
      height: 9
    },
    hotelData: {
      padding: '0 5px',
      marginBottom: 5,
      flexGrow: 1
    },
    property: {
      fontSize: 8,
      color: '#606060'
    },
    hotelNameContainer: {
      flexGrow: 1
    },
    hotelName: {
      fontSize: 10,
      color: client.styles.brandPrimary.value,
      margin: '5px 0'
    },
    addressContainer: {
      flexDirection: 'row'
    },
    addressIcon: {
      color: client.styles.brandPrimary.value,
      width: 8,
      marginRight: 3
    },
    address: {
      fontSize: 8,
      color: '#606060'
    },
    starsContainer: {
      flexDirection: 'row',
      marginTop: 5
    },
    star: {
      width: 12,
      marginRight: 2,
      color: '#606060'
    },
    activedStar: {
      color: '#faaf00'
    },
    amenityValue: {
      fontSize: 8,
      justifyContent: 'center',
      textAlign: 'center',
      marginBottom: 3,
      borderBottom: '2px solid #f0f0f0'
    },
    includedAmenity: {
      color: '#7cbe4c',
      width: 10,
      margin: 'auto'
    },
    priceContainer: {
      alignItems: 'center'
    },
    refundableContainer: {
      flexDirection: 'row',
      margin: '5px 0',
      alignItems: 'center'
    },
    refundable: {
      color: '#7cbe4c',
      marginRight: 3,
      width: 6
    },
    nonRefundable: {
      color: '#f64c4c',
      marginRight: 3,
      width: 6
    },
    refundableText: {
      fontSize: 8,
      color: '#606060',
    },
    providerLogo: {
      maxWidth: 80,
      marginBottom: 5
    },
    priceText: {
      color: '#606060',
      fontSize: 10
    },
    price: {
      color: client.styles.brandPrimary.value,
      fontSize: 16,
      marginTop: 5
    }
  };

  const adults = searchData.guests.roomInfo.reduce((acc, current) => acc += current.adults, 0);
  const minors = searchData.guests.roomInfo.reduce((acc, current) => acc += current.minors, 0);
  const roomsInfo = [];
  if (adults) {
    roomsInfo.push(`${adults} ${t('multiSearch.hotels.adults')}`);
  }
  if (minors) {
    roomsInfo.push(`${minors} ${t('multiSearch.hotels.minors')}`);
  }

  const getStars = (stars = 0) => {
    return Array.from(Array(5).keys()).map(i => {
      const style = i < stars ? styles.activedStar : {};
      return <FontAwesomeIcon faIcon={faStar} key={i} style={{...styles.star, ...style}} />
    })
  }

  const getCancelationPolicy = (hotel) => {
    const refundable = hotel?.rateType !== 'Nonrefundable';
    return <View style={styles.refundableContainer}>
      <FontAwesomeIcon faIcon={faDollarSign} style={refundable ? styles.refundable : styles.nonRefundable} />
      <Text style={styles.refundableText}>
        {refundable ? t('results.hotels.refundable') : t('results.hotels.nonRefundable')}
      </Text>
    </View>
  }

  const getProviderLogo = (providerName) => {
    const providerNameAux = providerName.replaceAll(' ', '').toLowerCase();
    const provider = providers.find(elem => providerNameAux.includes(elem.name));

    if (provider) {
      return <Image src={provider?.image} source='logo-provider' />
    } else {
      return <Text>{providerName}</Text>
    }
  }

  const chunkSubstr = (str, size) => {
    const numChunks = Math.ceil(str.length / size);
    const chunks = new Array(numChunks);

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      chunks[i] = str.substr(o, size);
    }

    return chunks;
  };

  Font.registerHyphenationCallback((word) => {
    if (word.length > 16) {
      return chunkSubstr(word, 14);
    } else {
      return [word];
    }
  });

  return (
    <Document>
        <Page size='A4' style={styles.page}>
          <View>
            <Text style={styles.title}>
              {t('results.hotels.compareHotelsInDestination', { destination: searchData?.destination?.label, interpolation: { escapeValue: false } })}
            </Text>
          </View>
          <View style={styles.searchDataContainer}>
            <View style={styles.searchData}>
              <Text style={styles.searchField}>{t('multiSearch.hotels.checkIn')}: </Text>
              <Text>{searchData.checkin}</Text>
            </View>
            <View style={styles.searchData}>
              <Text style={styles.searchField}>{t('multiSearch.hotels.checkOut')}: </Text>
              <Text>{searchData.checkout}</Text>
            </View>
            <View style={styles.searchData}>
              <Text style={styles.searchField}>{t('multiSearch.hotels.nights')}: </Text>
              <Text>{searchData.nights}</Text>
            </View>
            <View style={styles.searchData}>
              <Text style={styles.searchField}>{t('multiSearch.hotels.rooms')}: </Text>
              <Text>{searchData.rooms} ({roomsInfo.join(', ')})</Text>
            </View>
          </View>
          <View style={styles.hotelsContainer}>
            <View style={styles.amenitiesColumn} />
            {resultsToCompare.map((hotel, i) => {
              return <View key={i} style={styles.hotelColumn}>
                <View style={styles.recommendedHotel}>
                  {hotel.recommended && <Text>{t('results.hotels.hotelRecommended')}</Text>}
                </View>
                <View style={styles.hotelData}>
                  <Text style={styles.property}>
                    {t(`filters.hotels.properties.${hotel?.property?.toLowerCase() || 'other'}`).toUpperCase()}
                  </Text>
                  <View style={styles.hotelNameContainer}>
                    <Text style={styles.hotelName}>{hotel.name}</Text>
                  </View>
                  <View style={styles.addressContainer}>
                    <FontAwesomeIcon faIcon={faLocationDot} style={styles.addressIcon} />
                    <Text style={styles.address}>{hotel.address}</Text>
                  </View>
                  <View style={styles.starsContainer}>
                    {getStars(parseInt(hotel.stars))}
                  </View>
                </View>
              </View>
            })}
          </View>
          {activeAmenities.filter(elem => !isNaN(elem.id_filter)).map((amenity, i) => (
            <View style={styles.hotelsContainer} key={i}>
              <View style={styles.amenitiesColumn}>
                <View style={styles.amenityContainer}>
                  <Text>{amenity.value}</Text>
                </View>
              </View>
              {resultsToCompare.map((hotel, j) => {
                return <View key={j} style={{...styles.hotelColumn, ...styles.amenityValue}}>
                  {hotel.amenities.find(elem => elem.id_filter === amenity.code) ? (
                    <FontAwesomeIcon faIcon={faCheck} style={styles.includedAmenity} />
                  ) : (
                    <Text>-</Text>
                  )}
                </View>
              })}
            </View>
          ))}
          <View style={styles.hotelsContainer}>
            <View style={styles.amenitiesColumn} />
            {resultsToCompare.map((hotel, i) => {
              return <View key={i} style={{...styles.hotelColumn, ...styles.priceContainer}}>
                {getCancelationPolicy(hotel)}
                <View style={styles.providerLogo}>{getProviderLogo(hotel.providerName)}</View>
                <Text style={styles.priceText}>{t('results.hotels.totalPriceFrom')}</Text>
                <Text style={styles.price}>{`${hotel.currency} ${parseInt(hotel.totalPrice)} `}</Text>
              </View>
            })}
          </View>
        </Page>
    </Document>
  );
};
