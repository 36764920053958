import React from 'react';

export default function Image({ img, alt, className, hideBrokenImage, isFullPath, ...props }) {

    try {
        const imgUrl = isFullPath
            ? img
            : require(`./../../assets/images/${img}`);
        return <img
            src={imgUrl}
            alt={alt}
            className={className}
        />
    } catch(e) {
        console.error(e);
        if (!hideBrokenImage) {
            const imgUrl = require(`./../../assets/images/image-broken.png`)
            return <img
                src={imgUrl}
                alt={alt}
                className={className}
            />
        }
    }

}

