const initialState = {
  purchaseDetail: null,
  products: null,
  responsePrebooking: null,
	preloadBooking: true,
	dataFinal: {},
	startRenderProp: true,
  responseRetrieve: null,
	transactionCompleted: null,
  preloadStep: true,
  dataStep: null,
  errorStep: false,
  responseQrCodePix: null,
  errorQrCodePix: false
};

const camelToSnakeCase = (str) => {
  return str.replace(/([A-Z])/g, "_$1").toUpperCase();
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PREBOOKING_SUCCESS':
      if (action.payload?.data?.idTransaction) {
        return {
          ...state,
          dataFinal: action.payload,
          preload: false
        };
      }

      const activeStep = action.payload?.step?.find(elem => elem.data !== null);
      let dataStep = null;
      if (activeStep) {
        const actionName = camelToSnakeCase(activeStep.action);
        dataStep = { [actionName]: activeStep };
      }

			return {
        ...state,
        responsePrebooking: action.payload,
        purchaseDetail: action.payload.purchaseDetail,
        products: action.payload.products,
        dataStep,
        preloadBooking: false
      };
    case 'GET_PREBOOKING_ERROR':
			return {
        ...state,
        preloadBooking: false,
        startRenderProp: false,
        errorAvailabilityProp: Boolean(action.payload?.message) && action.payload?.status === 500,
        responsePrebooking: action.payload?.message
          ? {
              error: true,
              step: {
                data: {
                  messages: [
                    {
                      position: 'titleError',
                      message: [{
                        type: 'p',
                        value: action.payload?.message
                      }]
                    }
                  ]
                }
              }
            }
          : null
      };
    case 'GET_PASSENGER_SUCCESS':
      return {
        ...state,
        preloadStep: true,
        dataStep: { 'GET_PASSENGER': action.payload?.response.step },
        purchaseDetail: action.payload.response.purchaseDetail,
        products: action.payload.products
      };
    case 'GET_PASSENGER_ERROR':
      return {
        ...state,
        preloadStep: false,
        dataStep: { 'GET_PASSENGER': action.payload }
      };
    case 'GET_PAYMENT_SUCCESS':
      return {
        ...state,
        preloadStep: true,
        dataStep: { 'GET_PAYMENT': action.payload?.response?.step },
        purchaseDetail: action.payload?.response?.purchaseDetail,
        products: action.payload?.response?.products
      };
    case 'GET_PAYMENT_ERROR':
      return {
        ...state,
        errorStep: true,
        preloadStep: false,
        dataStep: { 'GET_PAYMENT': action.payload }
      };
    case 'GET_BOOKING':
      return {
        ...state,
        preloadBooking: true
      };
    case 'GET_BOOKING_SUCCESS':
      return {
        ...state,
        dataFinal: action.payload,
        preloadBooking: false
      };
    case 'GET_BOOKING_ERROR':
      return {
        ...state,
        startRenderProp: false,
        preloadBooking: false,
      };
    case 'GET_RETRIEVE_SUCCESS':
      return {
        ...state,
        responseRetrieve: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
