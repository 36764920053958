import React from 'react'
import { Card, CardMedia,CardContent, Typography , Link, Container, Rating, useTheme, useMediaQuery } from '@mui/material';
import RawMarkup from '../RawMarkup'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function BannerItem({ item, isMobile }) {
  const { image_url, image_url_mobile, title, description, link, caption, amount, currency_relation, label, category } = item;

  const getImage = () => {
    if (isMobile) {
        return image_url_mobile?.src || image_url_mobile;
    }

    return image_url?.src || image_url;
  }

  return (
    <Card className='banner-offer'>
      <Link href={link}>
        <CardMedia image={getImage()} title={title} sx={{ paddingTop: '56.25%' }} />
      </Link>
      <CardContent>
        {label && <Typography className='discount-label' variant='span'>{label}</Typography>}
        {title && <Typography className='name-offer' variant='h6' component={Link} href={link} gutterBottom>{title}</Typography>}
        {category && <Rating value={parseInt(category)} readOnly />}
        {caption && <Typography><RawMarkup content={caption} containerType='span' /></Typography>}
        {amount && <Typography variant='h6' className='price' style={{ display: 'flex' }}>
          {currency_relation && <span>{currency_relation.short_name}&nbsp;</span>}
          <RawMarkup content={amount} containerType='span' />
        </Typography>}
        {description && <Typography className='priceTextSecondary'>
          <RawMarkup content={description} containerType='span' />
        </Typography>}
      </CardContent>
    </Card>
  )
}

export default function BannerOffers({ title, banners }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const carouselResponsive = {
    breakpoint4: {
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
    },
    breakpoint3: {
      breakpoint: { max: 1023, min: 768 },
      items: 3,
    },
    breakpoint2: {
      breakpoint: { max: 765, min: 585 },
      items: 2,
    },
    breakpoint1: {
      breakpoint: { max: 584, min: 0 },
      items: 1,
    }
  };

  const sortBanners = () => {
    return banners?.sort((a, b) => (a.order || Infinity) - (b.order || Infinity)) || [];
  }

  if (banners && banners.length > 0) {
    return (
      <section className='banner-offers-section'>
        <Container>
          <Typography variant='h4' component='h2' sx={{ mb: 2 }}>{title}</Typography>
        </Container>

        <Container className='container-slider'>
          <Carousel responsive={carouselResponsive}>
            {sortBanners().map((banner, index) => (
              <BannerItem key={index} item={banner} isMobile={isMobile} />
            ))}
          </Carousel>
        </Container>
      </section>
    )
  }
}
