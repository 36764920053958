import React, { useEffect, useRef, useState } from 'react';
import { translateCondition } from '../../store/services/Translate';

const useTranslationCache = (text, language) => {
  const [translatedText, setTranslatedText] = useState('');
  const cacheRef = useRef({});

  useEffect(() => {
    const fetchTranslation = async () => {
      if (cacheRef.current[text] && cacheRef.current[text][language]) {
        setTranslatedText(cacheRef.current[text][language]);
      } else {
        try {
          let translated = await translateCondition(language, text);
          translated = translated?.replace(/<\s(\w)/g, '<$1')

          cacheRef.current[text] = {
            ...cacheRef.current[text],
            [language]: translated
          };

          setTranslatedText(translated);
        } catch (error) {
          setTranslatedText(text);
        }
      }
    };

    fetchTranslation();
  }, [text, language]);

  return translatedText;
};

const TranslatedText = ({ text, language, isHtml = true }) => {
  const translatedCondition = useTranslationCache(text, language);

  const parseHTML = (html) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = html;
    return <div dangerouslySetInnerHTML={{ __html: textArea.value }} />
  };

  return isHtml ? parseHTML(translatedCondition) : translatedCondition;
}

export default TranslatedText;
