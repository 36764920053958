import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Grid, Hidden, Pagination, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FlightCard from './FlightCard';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

const SORTING_OPTIONS = [
  { name: 'price', text: 'price'},
  { name: 'duration', text: 'duration'},
  { name: 'stopover', text: 'stopover'}
];

const ResultsComponent = ({
  client,
  results,
  loading,
  activeSort,
  resultsToCompare,
  handleClickCompareFlight,
  handleChangeSort
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    setCurrentPage(search.get('pag') ? parseInt(search.get('pag')) : 1);
  }, [location])

  useEffect(() => {
    if (results?.Fares && Math.ceil(results.Fares.length / 10) < currentPage) {
      handleChangePage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results])

  const handleChangePage = (page) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    const scrollInterval = setInterval(() => {
      if (window.scrollY === 0) {
        clearInterval(scrollInterval);
        setCurrentPage(page);
      }
    }, 50);
  }

  const getResults = () => {
    const startIndex = (currentPage - 1) * 10;
    const endIndex = currentPage * 10;
    return results?.Fares?.slice(startIndex, endIndex) || [];
  }

  const handleChangeSortAux = (name) => {
    const newActiveSort = {
      name: name,
      sort: (activeSort?.name !== name || activeSort?.sort === 'desc') ? 'asc' : 'desc'
    };

    handleChangeSort && handleChangeSort(newActiveSort);
  }

  return (
    <Grid className='results-component'>
      <Grid style={{ marginBottom: 16 }}>
        {t('results.flights.sortBy')}:
        {SORTING_OPTIONS.map((option, i) => (
          <Button
            key={i}
            className={`sort-button ${activeSort?.name === option.name ? 'active' : ''}`}
            disabled={loading}
            onClick={() => handleChangeSortAux(option.name)}
          >
            <span className='title'>{t(`results.flights.${option.text}`)}</span>
            {activeSort?.name === option.name && (
              activeSort.sort === 'asc' ? <ArrowUpward /> : <ArrowDownward />
            )}
          </Button>
        ))}
      </Grid>

      <Grid>
        {loading ? (
          Array.from(Array(3).keys()).map(elem => (
            <Grid className='flight-card skeleton' container key={elem} columnSpacing={3}>
              <Grid item xs={4} lg={3} xl={4}>
                <Skeleton height='100%' width='100%' />
              </Grid>
              <Grid container item className='row info-container' xs={8} lg={9} xl={8}>
                <Grid className='left-side' item sm={12} md={8} lg={8}>
                  <Skeleton width={300} height={35} variant='rounded' />
                  <Skeleton width={150} height={25} variant='rounded' />
                  <Skeleton width={150} height={25} variant='rounded' />
                  <Skeleton width={150} height={25} variant='rounded' />
                  <Skeleton width={150} height={25} variant='rounded' />
                  <Skeleton width={150} height={25} variant='rounded' />
                </Grid>
                <Hidden mdDown>
                  <Grid className='right-side' item md={4} lg={4}>
                    <Skeleton width={150} height={25} variant='rounded' />
                    <Skeleton width={150} height={25} variant='rounded' />
                    <Skeleton width={150} height={25} variant='rounded' />
                    <Skeleton width={150} height={25} variant='rounded' />
                    <Skeleton width={150} height={25} variant='rounded' />
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          ))
        ) : (
          getResults().map(flight => (
            <FlightCard
              key={flight.FareID}
              client={client}
              recommendationID={results?.RecommendationID}
              flight={flight}
              disabledCompare={resultsToCompare.length >= 4}
              selectedToCompare={Boolean(resultsToCompare.find(elem => elem.FareID === flight.FareID))}
              handleClickCompare={(flightToCompare, checked) => handleClickCompareFlight(flightToCompare, checked)}
            />
          ))
        )}
      </Grid>

      {!loading && results && results?.Fares?.length > 0 && (
        <Grid className='pagination-container'>
          <Pagination
            page={currentPage}
            onChange={(e, value) => handleChangePage(value)}
            count={Math.ceil(results?.Fares?.length / 10)}
            color="primary"
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ResultsComponent;
