import React, { useEffect, useRef, useState } from 'react';
import { Box, TextField, Button, InputAdornment, FormLabel, Select, MenuItem, Typography, Grid, Tooltip, Popover, IconButton, Divider, FormHelperText } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import dayjs from 'dayjs';
import { DesktopDatePicker, PickersDay } from '@mui/x-date-pickers';
import { Close, Search, Remove, Add, Person, ArrowBackOutlined} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useClientData } from '../../../context/ClientContext';
import { getCountry } from '../../../store/services/InfoFormServices';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InfoIcon from '@mui/icons-material/Info';
import 'dayjs/locale/es';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const DATE_FORMAT = 'DD/MM/YYYY';

const MultibuscadorAssists = ({ defaultData, isResultsView, defaultDestination, selectedReservation, ...props }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { setClientData } = useClientData();
    const fieldPassengersRef = useRef();
    const [formData, setFormData] = useState({
        checkin: null,
        checkout: null,
        days: '',
        type: '0',
        destination: '2',
        flexibleDates: false,
        range1: 1,
        range2: 0,
        range3: 0
    });
    const optionsSearch = {
        '1': 'Dentro de mi país',
        '2': 'Europa',
        '3': 'Estados Unidos y Canadá',
        '4': 'México, Caribe y Centroamérica',
        '5': 'Sudamérica',
        '6': 'Asia',
        '7': 'Oceanía',
        '8': 'África',
        '9': 'Multidestino'
    };

    const [errors, setErrors] = useState({});
    const [openCheckin, setOpenCheckin] = useState(false);
    const [openCheckout, setOpenCheckout] = useState(false);
    const [dayHover, setDayHover] = useState();
    const [passengers, setPassengers] = useState({
        adultos: 1,
        jovenes: 0,
        seniors: 0,
        olderAdult: 0,
    });
    const [expanded, setExpanded] = useState(true);
    const [anchorEl, setAnchorEl] = useState();
    const totalPassengers =
        passengers.adultos + passengers.jovenes + passengers.seniors + passengers.olderAdult;

    const passengerLabel = totalPassengers === 1
        ? t('multiSearch.assists.fieldPassenger') // "Pasajero"
        : t('multiSearch.assists.fieldPassengers'); // "Pasajeros"

    useEffect(() => {
        if (isResultsView) {
            setExpanded(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (defaultData) {
            setFormData(defaultData);
        }

        const countries = getCountry();
        const countryBusinessUnit = JSON.parse(localStorage.getItem('businessUnit') || '{}').country || { short_name: 'AR' };
        const nationality = countries.find(
            elem =>
                elem.code_2 === countryBusinessUnit?.short_name ||
                elem.description === countryBusinessUnit?.long_name
        )?.code_2 || '';

        setFormData((current) => ({
            ...current,
            nationality,
        }));

        if (defaultData?.passengers) {
            setPassengers(defaultData.passengers);
        }
    }, [defaultData]);

    useEffect(() => {
        if (fieldPassengersRef?.current) {
            fieldPassengersRef.current.click();
        }
    }, [fieldPassengersRef]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const totalPassengers = passengers?.adultos + passengers?.jovenes +
            passengers?.seniors + passengers?.olderAdult;

        const user = localStorage.getItem('user');
        setFormData((prevFormData) => ({
            ...prevFormData,
            passengers,
        }));

        if (user) {
            const form = {
            };
            const errorsAux = {};
            let errorsCount = 0;
            const ages = false;

            if (formData.type) {
                form.type = formData.type
            }

            if (formData.destination) {
                form.destination = formData.destination;
                // formData.destination = optionsSearch[form.destination] || optionsSearch[form.destination];
            } else {
                errorsCount += 1;
                errorsAux.destination = true;
            }

            if (formData.checkin) {
                form.checkin = dayjs(formData.checkin, DATE_FORMAT).format('DDMMYYYY');
            } else {
                errorsCount += 1;
                errorsAux.checkin = true;
            }

            if (formData.checkout) {
                form.checkout = dayjs(formData.checkout, DATE_FORMAT).format('DDMMYYYY');
            } else {
                errorsCount += 1;
                errorsAux.checkout = true;
            }

            if (formData.days) {
                form.daysparam = formData.days;
            }

            if (totalPassengers > 0) {
                formData.passengers = passengers;
            }else {
                errorsCount += 1;
                errorsAux.passengers = true;
            }

            setErrors(errorsAux);

            if (errorsCount === 0) {
                const resultStringAge = `${passengers.jovenes}-${passengers.adultos}-${passengers.seniors}-${passengers.olderAdult}`;
                const url = `/asistencia/resultados/${formData.nationality}/${ages}/${form.checkin}/${form.checkout}/${form.daysparam}/${form.destination}/${resultStringAge}/${formData.type}/0/0/0/`;
                navigate(url, { state: { formData } });
            }
        } else {
            const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
            if (iframePath) {
                setClientData(null);
                navigate(iframePath);
            } else {
                navigate('/login', { state: { expiredSession: true } });
            }
        }
    };

    const isSelected = (day) => {
        const startDate = formData.checkin && dayjs(formData.checkin, DATE_FORMAT);
        const endDate = formData.checkout && dayjs(formData.checkout, DATE_FORMAT);

        return (startDate ? startDate.isSame(day, 'date') : false)
            || (endDate ? endDate.isSame(day, 'date') : false);
    };

    const isBetween = (day) => {
        if (formData.checkin && formData.checkout) {
            return day.isBetween(dayjs(formData.checkin, DATE_FORMAT), dayjs(formData.checkout, DATE_FORMAT), 'date', '[]');
        }

        return false
    };

    const isDisabled = (day) => {
        let flag = false;

        if (day.isBefore(dayjs(), 'date')) {
            flag = flag || true;
        }
        if (openCheckin) {
            flag = flag || day.isAfter(dayjs().add(18, 'month').subtract(1, 'day'), 'date')
        }
        if (openCheckout) {
            if (formData.checkin) {
                const startDate = dayjs(formData.checkin, DATE_FORMAT);
                flag = flag || day.isSameOrBefore(startDate, 'date');
            }

            flag = flag || day.isAfter(dayjs().add(18, 'month'), 'date')
        }

        return flag;
    };

    const getPickerDay = (day, _value, DayComponentProps) => {
        const date = dayjs(day.$d);
        const isBetweenAux = isBetween(date);
        const isStartDate = formData.checkin === date.format(DATE_FORMAT);
        const isEndDate = formData.checkout === date.format(DATE_FORMAT);

        const classNames = [];
        if (isBetweenAux) classNames.push('range-between');
        if (isStartDate) classNames.push('start-date');
        if (isEndDate) classNames.push('end-date');

        const classNamesDay = [];
        if (!isStartDate && !isEndDate) {
            if (isBetweenAux) {
                classNamesDay.push('between');
            } else if (formData.checkin && dayHover) {
                if (openCheckin && formData.checkout && date.isBetween(dayHover, dayjs(formData.checkin, DATE_FORMAT), 'date', '()')) {
                    classNamesDay.push('preselected-day');
                } else if (openCheckout) {
                    const dateAux = formData.checkout ? dayjs(formData.checkout, DATE_FORMAT) : dayjs(formData.checkin, DATE_FORMAT);
                    formData.checkout && date.isBetween(dayjs(formData.checkout, DATE_FORMAT), dayHover, 'date', '()')
                    if (dateAux && date.isBetween(dateAux, dayHover, 'date', '()')) {
                    classNamesDay.push('preselected-day');
                    }
                }
            }
        }

        return (
            <div className={classNames.join(' ')} key={DayComponentProps.key}>
                <PickersDay
                    {...DayComponentProps}
                    className={classNamesDay.join(' ')}
                    selected={isSelected(date)}
                    disabled={isDisabled(date)}
                    onMouseOver={() => setDayHover(date)}
                    onMouseLeave={() => setDayHover(null)}
                    disableHighlightToday
                />
            </div>
        );
    };

    const handleOpenCheckin = () => {
        setOpenCheckin(true);
        setOpenCheckout(false);
    };

    const handleOpenCheckout = () => {
        setOpenCheckin(false);
        setOpenCheckout(true);
    };

    const handleChangeCheckin = (e) => {
        handleDateChange(e, 'checkin');

        const dateCheckin = dayjs(e.format('L'), DATE_FORMAT);
        const dateCheckout = dayjs(formData.checkout, DATE_FORMAT);

        if (formData.type === '1') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                checkin: dateCheckin.format(DATE_FORMAT),
                checkout: dateCheckin.add(365, 'day').format(DATE_FORMAT),
            }));
            return;
        }

        if (e && formData.checkout && dateCheckout.isSameOrBefore(dateCheckin, 'date')) {
            handleDateChange('', 'checkout');
        }

        setOpenCheckin(false);
        setOpenCheckout(true);

        const days = dateCheckout.diff(dateCheckin, 'days');

        if (days >= 1) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                days: days.toString(),
            }));
        }
    };

    const handleChangeCheckout = (e) => {
        handleDateChange(e, 'checkout');

        const dateCheckin = dayjs(formData.checkin, DATE_FORMAT);
        const dateCheckout = dayjs(e.format('L'), DATE_FORMAT);

        if (formData.type === '1') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                checkout: dateCheckin.add(364, 'day').format(DATE_FORMAT),
            }));
            return;
        }

        const days = dateCheckout.diff(dateCheckin, 'days') + 1;
        if (days >= 1) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                checkout: dateCheckout.format(DATE_FORMAT),
                days: days.toString(),
            }));
        }
        setOpenCheckout(false);
    };

    const handleDateChange = (date, field) => {
        const formattedDate = date ? date.format(DATE_FORMAT) : '';
        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: formattedDate,
        }));
        setDayHover(false);
    };

    const handleDaysChange = (newDays) => {
        newDays = Math.max(1, Math.floor(newDays));

        const updatedFormData = {
            ...formData,
            days: newDays.toString()
        }
        if (!formData.checkin) {
            const dateCheckin = dayjs();
            const dateCheckout = dayjs().add(newDays, 'day');
            updatedFormData.checkin = dateCheckin.format(DATE_FORMAT);
            updatedFormData.checkout = dateCheckout.format(DATE_FORMAT);
        } else {
            const dateCheckin = dayjs(formData.checkin, DATE_FORMAT);
            const dateCheckout = dayjs(dateCheckin).add(newDays, 'day');
            updatedFormData.checkout = dateCheckout.format(DATE_FORMAT);
        }

        if (dayjs(updatedFormData.checkout, DATE_FORMAT).isSameOrBefore(dayjs().add(18, 'month'), 'date')) {
            setFormData(updatedFormData);
        }
    };

    const handleTripTypeChange = (type) => {
        setFormData((prevFormData) => {
            const updatedFormData = {
                ...prevFormData,
                // type,
            };

            if (type === '1') {
                const tomorrow = dayjs().add(1, 'day');
                updatedFormData.type = type
                updatedFormData.destination = '2'
                updatedFormData.checkin = tomorrow.format(DATE_FORMAT);
                updatedFormData.checkout = tomorrow.add(364, 'day').format(DATE_FORMAT);
                updatedFormData.days = '365';
            } else {
                updatedFormData.type = type
                updatedFormData.destination = '2';
                updatedFormData.checkin = null;
                updatedFormData.checkout = null;
                updatedFormData.days = '';
            }
            // setFormData(updatedFormData)

            return updatedFormData;
        });
    };

    const handleChangeField = (e) => {
        const { name, value } = e.target;
        const formDataAux = {
            ...formData,
            [name]: value
        }

        setFormData(formDataAux);
    };

    const getMaxPassengers = (field) => {
        const fields = ['range1', 'range2', 'range3'];
        let count = 200;

        fields.forEach(elem => {
            if (elem !== field) {
                count -= (formData[elem] || 0);
            }
        });

        return count;
    };

    const handleClickGuests = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseGuests = () => {
        setAnchorEl(null);
    };

    const updatePassengerCount = (key, increment) => {
        setPassengers((prevPassengers) => {
            const currentValue = prevPassengers[key];
            const newValue = increment ? currentValue + 1 : currentValue - 1;

            if (newValue >= 0) {
                return {
                    ...prevPassengers,
                    [key]: newValue,
                };
            } else {
                return prevPassengers;
            }
        });
    };

    const getResumeData = () => {
        const totalPassengers = formData.passengers?.adultos + formData.passengers?.jovenes +
            formData.passengers?.seniors + formData.passengers?.olderAdult;

        if (!formData.destination && !formData.checkin && !formData.checkout && !totalPassengers) {
          return null;
        }

        return (
            <>
                {/* Destino */}
                {formData.destination && (
                <>
                    <Grid item className='row destination'>
                        <LocationOnIcon />
                        {optionsSearch[formData.destination] || formData.destination}
                    </Grid>
                </>
                )}

                {/* Fechas */}
                {formData.checkin && formData.checkout && (
                    <Grid item className='row date'>
                        <DateRangeIcon />

                        {/* NUEVA LÓGICA: “ddd, DD MMM” con dayjs.locale('es') */}
                        {dayjs(formData.checkin, 'DDMMYYYY')
                        .locale('es')
                        .format('ddd DD MMM')}
                        {' – '}
                        {dayjs(formData.checkout, 'DDMMYYYY')
                        .locale('es')
                        .format('ddd DD MMM')}
                    </Grid>
                )}

                {/* Pasajeros */}
                {totalPassengers > 0 && (
                    <Grid item className='row pax'>
                        <Person />
                        {totalPassengers > 1
                            ? `${totalPassengers} ${t('multiSearch.assists.fieldPassengers')}`
                            : `${totalPassengers} ${t('multiSearch.assists.fieldPassenger')}`
                        }
                    </Grid>
                )}
            </>
        );
    };

    return (
        <Box className='assist-search search-box-container'>
            {isResultsView && (
                <Grid className='resume-container row'>
                    <Button className='back-button' onClick={() => navigate('/asistencia')}>
                        <ArrowBackOutlined fontSize='small' />
                        {t('common.back')}
                    </Button>

                    {getResumeData()}

                    <Button className='modify-button' variant='outlined' onClick={() => setExpanded(!expanded)}>
                        {expanded ? <Close fontSize='small' /> : <Search fontSize='small' />}
                        {expanded ? t('common.cancel') : t('multiSearch.assists.modify')}
                    </Button>
                </Grid>
            )}

            <Accordion expanded={expanded}>
                <AccordionSummary />
                <AccordionDetails>
                    <form onSubmit={e => handleSubmit(e)}>
                        <Grid container className='search-box search-box-assist' spacing={1}>
                            <Grid item xs={12} className='row mb-05 buttons-container'>
                                <Grid className='trip-type-buttons'>
                                    <Button
                                        variant='outlined'
                                        name='tripType'
                                        error={errors.tripType}
                                        value={formData.type || ''}
                                        className={formData.type === '0' ? 'selected' : ''}
                                        onClick={() => handleTripTypeChange('0')}
                                    >
                                        {t('multiSearch.assists.uniqueTrip')}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        name="tripType"
                                        error={errors.tripType}
                                        value={formData.type || ''}
                                        className={formData.type === '1' ? 'selected' : ''}
                                        onClick={() => handleTripTypeChange('1')}
                                        endIcon={
                                            <Tooltip
                                                title={t('multiSearch.assists.tooltipText')}
                                                arrow
                                            >
                                                <InfoIcon fontSize="small" style={{ cursor: 'pointer' }} />
                                            </Tooltip>
                                        }
                                    >
                                        {t('multiSearch.assists.variousTrips')}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Grid container spacing={1} columns={24}>
                                    <Grid item xs={24} md={5}>
                                        <FormLabel>{t('multiSearch.assists.destination')}</FormLabel>
                                        <Select
                                            fullWidth
                                            size="small"
                                            name="destination"
                                            value={
                                                formData.destination || '2'
                                            }
                                            onChange={(e) => handleChangeField(e)}
                                            >
                                            {Object.entries(optionsSearch).map(([key, value]) => (
                                                <MenuItem key={key} value={key}>
                                                {value}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </Grid>
                                    <Grid item xs={24} md={4}>
                                        <FormLabel>{t('multiSearch.assists.checkOut')}</FormLabel>
                                        <DesktopDatePicker
                                            open={openCheckin}
                                            onClose={() => setOpenCheckin(false)}
                                            onChange={(e) => handleChangeCheckin(e, 'checkin')}
                                            renderDay={getPickerDay}
                                            closeOnSelect={false}
                                            showDaysOutsideCurrentMonth
                                            value={formData.checkin ? dayjs(formData.checkin, DATE_FORMAT) : null}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    id='checkin-input'
                                                    focused={false}
                                                    placeholder={DATE_FORMAT}
                                                    value={formData.checkin ? formData.checkin : ''}
                                                    inputProps={{ readOnly: true }}
                                                    size='small'
                                                    fullWidth
                                                    error={errors.checkin}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position='start'>
                                                                <DateRangeIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onClick={handleOpenCheckin}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={24} md={4}>
                                        <FormLabel>{t('multiSearch.assists.checkIn')}</FormLabel>
                                        <DesktopDatePicker
                                            open={openCheckout}
                                            onClose={() => setOpenCheckout(false)}
                                            onChange={(e) => handleChangeCheckout(e)}
                                            renderDay={getPickerDay}
                                            closeOnSelect={false}
                                            value={formData.checkout ? dayjs(formData.checkout, DATE_FORMAT) : null}
                                            minDate={formData.checkin ? dayjs(formData.checkin, DATE_FORMAT).add(1, 'day') : dayjs()}
                                            defaultCalendarMonth={formData.checkin ? dayjs(formData.checkin, DATE_FORMAT).add(1, 'day') : dayjs()}
                                            inputFormat="DD/MM/YYYY"
                                            renderInput={(params) => {
                                                params.inputProps.placeholder = "DD/MM/YYYY";

                                                return (
                                                <TextField
                                                    {...params}
                                                    id='checkout-input'
                                                    placeholder="DD/MM/YYYY"
                                                    value={formData.checkout || ''}
                                                    size='small'
                                                    fullWidth
                                                    error={errors.checkout}
                                                    InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position='start'>
                                                        <DateRangeIcon />
                                                        </InputAdornment>
                                                    ),
                                                    }}
                                                    onClick={handleOpenCheckout}
                                                />
                                                );
                                            }}
                                    />

                                    </Grid>
                                    <Grid item xs={24} md={3}>
                                        <FormLabel>{t('multiSearch.assists.days')}</FormLabel>
                                        <TextField
                                            type='number'
                                            variant='outlined'
                                            placeholder={t('multiSearch.assists.days')}
                                            value={formData.days}
                                            fullWidth
                                            size='small'
                                            onChange={(e) => handleDaysChange(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={24} md={6}>
                                        <FormLabel>{t('multiSearch.assists.fieldPassengers')}</FormLabel>
                                        <TextField
                                            ref={fieldPassengersRef}
                                            variant='outlined'
                                            placeholder={`${passengerLabel}: ${totalPassengers}`}
                                            fullWidth
                                            size='small'
                                            className='pointer-input'
                                            value={`${totalPassengers} ${passengerLabel}`}
                                            onClick={handleClickGuests}
                                            error={errors.passengers}
                                            InputProps={{
                                                startAdornment: (
                                                    <Person fontSize='small' style={{ marginRight: '8px', color: 'gray' }} />
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <KeyboardArrowDownIcon style={{ cursor: 'pointer', color: 'gray' }} />
                                                    </InputAdornment>
                                                ),
                                                readOnly: true
                                            }}
                                        />

                                        {errors.passengers && (
                                            <FormHelperText error  sx={{
                                                color: 'white !important',
                                                fontSize: '0.8rem',
                                            }}>
                                            {t('multiSearch.assists.errorPassengers')}
                                            </FormHelperText>
                                        )}

                                            <Popover
                                                open={Boolean(anchorEl)}
                                                anchorEl={anchorEl}
                                                onClose={handleCloseGuests}
                                                className='flights-guests-popover'
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <Grid p={2}>
                                                    <Grid className='passengers-input'>
                                                        <div>
                                                            <Typography className='title'>{t('multiSearch.assists.range2')}</Typography>
                                                        </div>

                                                        <div className='buttons-container row'>
                                                            <IconButton
                                                                onClick={() => updatePassengerCount('adultos', false)}
                                                            >
                                                                <Remove />
                                                            </IconButton>
                                                            <span>{passengers.adultos} </span>
                                                            <IconButton
                                                                disabled={formData.range2 === getMaxPassengers('range2')}
                                                                onClick={() => updatePassengerCount('adultos', true)}
                                                            >
                                                                <Add />
                                                            </IconButton>
                                                        </div>
                                                    </Grid>

                                                    <Divider />

                                                    <Grid className='passengers-input'>
                                                        <div>
                                                            <Typography className='title'>{t('multiSearch.assists.range3')}</Typography>
                                                        </div>

                                                        <div className='buttons-container row'>
                                                            <IconButton
                                                                onClick={() => updatePassengerCount('seniors', false)}
                                                            >
                                                                <Remove />
                                                            </IconButton>
                                                            <span>{passengers.seniors} </span>
                                                            <IconButton
                                                                // disabled={formData.range3 === formData.adults}
                                                                onClick={() => updatePassengerCount('seniors', true)}
                                                            >
                                                                <Add />
                                                            </IconButton>
                                                        </div>
                                                    </Grid>

                                                    <Divider />

                                                    <Grid className='passengers-input'>
                                                        <div>
                                                            <Typography className='title'>{t('multiSearch.assists.range4')}</Typography>
                                                        </div>

                                                        <div className='buttons-container row'>
                                                            <IconButton
                                                                // disabled={formData.range3 === 0}
                                                                onClick={() => updatePassengerCount('olderAdult', false)}
                                                            >
                                                                <Remove />
                                                            </IconButton>
                                                            <span>{passengers.olderAdult} </span>
                                                            <IconButton
                                                                // disabled={formData.range3 === formData.adults}
                                                                onClick={() => updatePassengerCount('olderAdult', true)}
                                                            >
                                                                <Add />
                                                            </IconButton>
                                                        </div>
                                                    </Grid>

                                                    <Grid container>
                                                        <Typography className='minor-ages-text'>
                                                            {t('multiSearch.assists.minorAges')}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid container justifyContent='flex-end'>
                                                        <Button size='small' variant='contained' color='secondary' onClick={handleCloseGuests} >
                                                            {t('multiSearch.assists.apply')}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Popover>
                                    </Grid>
                                    <Grid item xs={2} xl={2}>
                                        <Button
                                            variant='contained'
                                            color='secondary'
                                            size='small'
                                            type='submit'
                                            className='submit-button'
                                            fullWidth
                                                >
                                            {t('common.search')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default MultibuscadorAssists;
