const initialState = {
  responseQrCodePix: null,
  errorQrCodePix: false,
  responsePaymentPix: null,
  errorPaymentPix: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_QR_CODE_PIX_SUCCESS':
      return {
        ...state,
        responseQrCodePix: action.payload,
        errorQrCodePix: false
      };
    case 'GET_QR_CODE_PIX_ERROR':
      return {
        ...state,
        responseQrCodePix: null,
        errorQrCodePix: true
      };
    case 'GET_PAYMENT_STATUS_PIX_SUCCESS':
      return {
        ...state,
        responsePaymentPix: action.payload,
        errorPaymentPix: false
      };
    case 'GET_PAYMENT_STATUS_PIX_ERROR':
      return {
        ...state,
        responsePaymentPix: null,
        errorPaymentPix: true
      };
    default:
      return state;
  }
};

export default reducer;
