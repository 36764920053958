import { createContext, useContext, useState } from 'react';

const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {
  const [paymentSelected, setPaymentSelected] = useState(null);
  const [paymentError, setPaymentError] = useState(null);
  const [numberRetry, setNumberRetry] = useState(0);

  return (
    <PaymentContext.Provider value={{
      paymentSelected,
      setPaymentSelected,
      paymentError,
      setPaymentError,
      numberRetry,
      setNumberRetry
    }}>
      {children}
    </PaymentContext.Provider>
  );
};

export const usePaymentContext = () => {
  return useContext(PaymentContext);
};
