const initialState = {
  resultSearch: {},
  resultCache: null,
  filtersResult: {},
  filterSearch: {},
  resultAvailability: {},
  resultAvailabilityParallel: [],
  resultAvailabilityDetailOnly: {},
  resultCancelationPolicy: {},
  resultMoreAvailability: {},
  resultFavorites: {},
  error: null,
  errorFilter: null,
  errorAvailability: null,
  errorAvailabilityOnlyDetail: false,
  errorFavorites: null,
  apiCheckout: {},
  errorApiCheckout: false,
  errorRule: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_PRODUCT_SEARCH_GIMMONIX':
      return {
        ...state,
        resultSearch: {},
        filterSearch: {},
        error: null,
        errorFilter: null,
        errorRule: false,
        resultCache: null
      };
    case 'GET_PRODUCT_SEARCH_GIMMONIX':
      return {
        ...state,
        resultSearch: {},
        filterSearch: {},
        error: null,
        errorFilter: null,
        errorRule: false,
        resultCache: null
      };
    case 'GET_PRODUCT_SEARCH_SUCCESS_GIMMONIX':
      return {
        ...state,
        resultSearch: action.payload,
        filterSearch: {},
        error: null,
        errorFilter: null,
        errorRule: false,
        resultCache: null
      };
    case 'GET_PRODUCT_SEARCH_ERROR_GIMMONIX':
      return {
        ...state,
        error: action.payload,
        errorFilter: null,
        errorRule: false
      };
    case 'GET_CACHE_SEARCH_SUCCESS_GIMMONIX':
      return {
        ...state,
        resultCache: action.payload
      };
    case 'GET_CACHE_SEARCH_ERROR_GIMMONIX':
      return {
        ...state,
        resultCache: null
      };
    case 'GET_RULE_ERROR_GIMMONIX':
      return {
        ...state,
        errorRule: action.payload,
        resultSearch: {},
        filterSearch: {},
        resultCache: null
      };
    case 'GET_PRODUCT_FILTERS_SUCCESS_GIMMONIX':
      return {
        ...state,
        filtersResult: action.payload,
        error: null,
        errorFilter: null
      };
    case 'GET_PRODUCT_FILTERS_ERROR_GIMMONIX':
      return {
        ...state,
        error: action.payload,
        errorFilter: null
      };
    case 'GET_PRODUCT_FILTER_GIMMONIX':
      return {
        ...state,
        errorFilter: null,
      };
    case 'GET_PRODUCT_FILTER_SUCCESS_GIMMONIX':
      return {
        ...state,
        filterSearch: action.payload,
        resultSearch: {},
        filtersResult: {},
        error: null,
        errorFilter: null
      };
    case 'GET_PRODUCT_FILTER_ERROR_GIMMONIX':
      return {
        ...state,
        error: null,
        errorFilter: action.payload,
      };
    case 'GET_PRODUCT_AVAILABILITY_GIMMONIX':
      return {
        ...state,
        errorAvailability: null
      };
    case 'GET_PRODUCT_AVAILABILITY_SUCCESS_GIMMONIX':
      return {
        ...state,
        resultAvailability: action.payload,
        errorAvailability: null,
        errorAvailabilityOnlyDetail: false,
        resultMoreAvailability: {}
      };
    case 'GET_PRODUCT_AVAILABILITY_ERROR_GIMMONIX':
      return {
        ...state,
        resultAvailability: {},
        resultMoreAvailability: {},
        errorAvailability: action.payload,
      };
    case 'GET_PRODUCT_AVAILABILITY_PARALLEL_SUCCESS_GIMMONIX':
      return {
        ...state,
        resultAvailabilityParallel: action.payload,
        errorAvailability: null
      };
    case 'GET_PRODUCT_AVAILABILITY_PARALLEL_ERROR_GIMMONIX':
      return {
        ...state,
        errorAvailability: action.payload,
      };
    case 'GET_PRODUCT_AVAILABILITY_DETAIL_ONLY_SUCCESS_GIMMONIX':
      return {
        ...state,
        resultAvailabilityDetailOnly: action.payload,
        resultMoreAvailability: {},
        errorAvailabilityOnlyDetail: false
      };
    case 'GET_PRODUCT_AVAILABILITY_DETAIL_ONLY_ERROR_GIMMONIX':
      return {
        ...state,
        resultAvailabilityDetailOnly: {},
        resultMoreAvailability: {},
        errorAvailabilityOnlyDetail: true
      };
    case 'GET_CANCELATION_POLICY_SUCCESS_GIMMONIX':
      return {
        ...state,
        resultCancelationPolicy: action.payload,
        errorAvailability: null
      };
    case 'GET_CANCELATION_POLICY_ERROR_GIMMONIX':
      return {
        ...state,
        resultCancelationPolicy: {},
        errorAvailability: null
      };
    case 'GET_MORE_PRODUCT_AVAILABILITY_SUCCESS_GIMMONIX':
      return {
        ...state,
        resultMoreAvailability: action.payload
      };
    case 'GET_MORE_PRODUCT_AVAILABILITY_ERROR_GIMMONIX':
      return {
        ...state,
        resultMoreAvailability: {}
      };
    case 'RESET_FAVORITES_GIMMONIX':
      return {
        ...state,
        resultFavorites: {},
        errorFavorites: null
      };
    case 'GET_FAVORITES_GIMMONIX':
      return {
        ...state,
        resultFavorites: {},
        errorFavorites: null,
      };
    case 'GET_FAVORITES_SUCCESS_GIMMONIX':
      return {
        ...state,
        resultFavorites: action.payload,
        errorFavorites: null
      };
    case 'GET_FAVORITES_ERROR_GIMMONIX':
      return {
        ...state,
        resultFavorites: {},
        errorFavorites: action.payload,
      };
    case 'START_CHECKOUT':
      return {
        ...state,
        apiCheckout: {},
        errorApiCheckout: false
      };
    case 'GET_START_CHECKOUT_SUCCESS':
      return {
        ...state,
        apiCheckout: action.payload,
        errorApiCheckout: false
      };
    case 'GET_START_CHECKOUT_ERROR':
      let parsedPayload;
      try {
        parsedPayload = JSON.parse(action.payload);
      } catch (error) {
        parsedPayload = { error: 'Respuesta no válida de la API' };
      }
      return {
        ...state,
        apiCheckout: parsedPayload,
        errorApiCheckout: true
      };
    default:
      return state;
  }
};

export default reducer;
