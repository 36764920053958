import React, { Fragment } from 'react'
import { Paper, Typography } from '@mui/material';
import { VerifiedUser } from '@mui/icons-material';
import Image from '../../common/Image';
import Certificates from './Certificates';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function SecureSite({ client }) {
  const { t } = useTranslation();
  const params = useParams();

  return (
    <Fragment>
      <Paper className="paper secure-site">
        <div className="icon-secure-site">
          <div className="avatar">
            <VerifiedUser />
          </div>
        </div>
        <div className="text-secure-site">
          <Typography className='strong title'>
            {params.tokenIframe ? (
              t('checkout.secureSite.titleIframe')
            ) : (
              t('checkout.secureSite.title', { site: client?.nameCopy })
            )}
          </Typography>
          <Typography className='subtitle'>{t('checkout.secureSite.description')}</Typography>
          <Image className="logos-sitio-seguro" img='logos-sitio-seguro.png' alt="Sitio seguro" />
        </div>
      </Paper>

      <Certificates client={client} />
    </Fragment>
  )
}
