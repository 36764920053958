import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import { useTranslation } from 'react-i18next';

const AgeCardSelection = ({ ranges, onRangeSelect, selectedRange, selectedRate }) => {
  const { t } = useTranslation();

  const handleClick = (value) => {
    onRangeSelect(value);
  };

  const renderPassengerIcon = (quantity) => {
    return quantity > 1 ? (
      <GroupIcon fontSize="small" sx={{ mr: 0.5 }} />
    ) : (
      <PersonIcon fontSize="small" sx={{ mr: 0.5 }} />
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={0} sx={{ border: '0px solid #245CFF', borderRadius: '8px' }}>
        {ranges.map(({ range, quantity }, index) => (
          <Grid
            item xs={12}
            sm={Math.floor(12 / ranges.length)}
            key={range}
            className={`age-card-selection ${index !== 0 ? 'not-first' : ''} ${selectedRange === range ? 'selected' : ''}`}
            onClick={() => handleClick(range, index)}
          >
            <Typography variant="body1">{`De ${range.replace('-', ' a ') } ${t('results.assists.years')}`}</Typography>
            <Typography variant="body2" sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
              {renderPassengerIcon(quantity)}
              {quantity > 1 ? `${quantity} Pasajeros` : `${quantity} Pasajero`}
            </Typography>
            {selectedRate[ranges[index]?.range] && (
              <Box className="icon-check">
                <CheckCircleOutlineIcon fontSize="small" />
              </Box>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AgeCardSelection;
