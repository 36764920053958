import React from 'react';
import * as Icons from '@mui/icons-material'

const IconComponent = ({ iconName }) => {
  const Icon = Icons[iconName]

  return Icon ? <Icon /> : null;
};

export default IconComponent;
