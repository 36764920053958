import React from 'react';
import { Document, Font, /* Image,  */Page, /* Path, Svg,  */Text, View } from '@react-pdf/renderer';
//import { faCheck, faDollarSign, faLocationDot, faStar } from '@fortawesome/free-solid-svg-icons';
//import { useTranslation } from 'react-i18next';

/* const FontAwesomeIcon = ({ faIcon: { icon }, style = {} }) => {
  const duotone = Array.isArray(icon[4]);
  const paths = Array.isArray(icon[4]) ? icon[4] : [icon[4]];
  const color = style.color || 'black';
  return (
    <Svg viewBox={`0 0 ${icon[0]} ${icon[1]}`} style={style}>
      {paths &&
        paths.map((d, index) => (
          <Path
            d={d}
            key={index}
            fill={color}
            fillOpacity={duotone && index === 0 ? 0.4 : 1.0}
          />
        ))}
    </Svg>
  );
}; */

export const PDFComparation = ({ client, dataForm, optionsSearch, compareList, resultsToCompare, title, coverageTitles }) => {
  //const { t } = useTranslation();

  const styles = {
    page: {
      margin: '15px 20px'
    },
    title: {
      fontSize: 19,
      color: client.styles.brandPrimary.value,
      marginLeft: 0
    },
    searchDataContainer: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      marginTop: 10,
      marginBottom: 20,
      fontSize: 10,
      marginLeft: 0
    },
    searchData: {
      flexDirection: 'row',
      marginRight: 20,
      color: '#606060',
      marginLeft: 0
    },
    searchField: {
      color: '#000'
    },
    assistContainer: {
      flexDirection: 'row'
    },
    itemColumn: {
      width: 120
    },
    amenityContainer: {
      backgroundColor: '#e1eaff',
      padding: 8,
      color: client.styles.brandPrimary.value,
      marginBottom: 3,
      fontSize: 8
    },
    coverageTitleColumn: {
      width: 180,
      marginLeft: 10,
      flexDirection: 'column'
    },
    coverageTitleColumnValue: {
      backgroundColor: '#E1EAFF',
      color: client.styles.headings.alternative.color,
      fontSize: 8,
      marginBottom: 4,
      height: 20,
      padding: '4px'
    },
    coverageColumn: {
      width: 80,
      marginLeft: 10,
      flexDirection: 'column'
    },
    coverageValueNameEmpty:{
      color: 'white',
      fontSize: 10,
      height: 20,
      padding: '4px',
      marginBottom: 4,
    },
    coverageValueName:{
      backgroundColor: '#E1EAFF',
      color: 'black',
      fontSize: 10,
      height: 20,
      padding: '4px',
      justifyContent: 'center',
      textAlign: 'center',
    },
    coverageValue: {
      color: 'black',
      fontSize: 8,
      marginBottom: 4,
      height: 20,
      padding: '4px',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    amenityValue: {
      fontSize: 8,
      justifyContent: 'center',
      textAlign: 'center',
      marginBottom: 3,
      borderBottom: '2px solid #f0f0f0'
    },
    priceContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    priceText: {
      color: '#606060',
      fontSize: 10
    },
    price: {
      color: client.styles.brandPrimary.value,
      fontSize: 16,
      marginTop: 5
    }
  };

  const chunkSubstr = (str, size) => {
    const numChunks = Math.ceil(str.length / size);
    const chunks = new Array(numChunks);

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      chunks[i] = str.substr(o, size);
    }

    return chunks;
  };

  Font.registerHyphenationCallback((word) => {
    if (word.length > 16) {
      return chunkSubstr(word, 14);
    } else {
      return [word];
    }
  });

  function getDestinationName(destinationId, optionsSearch) {
    return optionsSearch[destinationId] || "Destino no encontrado";
  }

  const destination = getDestinationName(dataForm.destination, optionsSearch);

  const totalPassengers = Object.values(dataForm.passengers).reduce((total, num) => total + num, 0);

  const passengerLabel = totalPassengers > 1 ? "Pasajeros" : "Pasajero";

  const formatMoney = (val) => `USD ${Math.round(val)}`;

  return (
    <Document>
        <Page size='A4' style={styles.page}>
          <View>
            <Text style={styles.title}>{title}</Text>
          </View>
          <View style={styles.searchDataContainer}>
            <View style={styles.searchData}>
              <Text style={styles.searchField}>Destino: </Text>
              <Text>{destination}</Text>
            </View>
            <View style={styles.searchData}>
              <Text style={styles.searchField}>Salida: </Text>
              <Text>{dataForm.checkin}</Text>
            </View>
            <View style={styles.searchData}>
              <Text style={styles.searchField}>Regreso: </Text>
              <Text>{dataForm.checkout}</Text>
            </View>
            <View style={styles.searchData}>
              <Text style={styles.searchField}>Días: </Text>
              <Text>{dataForm.days}</Text>
            </View>
            <View style={styles.searchData}>
              <Text style={styles.searchField}>Pasajeros: </Text>
              <Text>{totalPassengers} {passengerLabel}</Text>
            </View>
          </View>

          <View style={styles.assistContainer}>

            <View style={styles.coverageTitleColumn}>

              <View style={styles.coverageValueNameEmpty}>
                  <Text>Cobertura</Text>
              </View>

              {coverageTitles.map((title, i) => {
                if (title === '-') return null;
                const capitalizedTitle = title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
                return <View key={i} style={styles.coverageTitleColumnValue}>
                    <Text>{capitalizedTitle}</Text>
                </View>
              })}
            </View>

            {resultsToCompare.map((coverage, i) =>  {

              const planFromList = compareList.find(
                (item) => String(item.rateId) === String(coverage.rateId)
              );

              const { totalPromotion = 0, } = planFromList || {};

              return <View key={i} style={styles.coverageColumn}>
                <View style={styles.coverageValueName}>
                    <Text>{coverage.name}</Text>
                </View>

                {coverage.coverageValues.map((coverageValue, i) => {
                  return <View key={i} style={styles.coverageValue}>
                    <Text>{coverageValue}</Text>
                  </View>
                })}

                <View style={styles.coverageValueName}>
                    <Text>Precio Total</Text>
                </View>
                <View style={styles.coverageValueName}>
                    <Text> {formatMoney(totalPromotion)}</Text>
                </View>

              </View>
            })}
          </View>
        </Page>
    </Document>
  );
};
