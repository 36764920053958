import React from 'react';
import BannerSingleImage from './BannerSingleImage';
import BannerSlider from './BannerSlider';
import BannerPromotions from './BannerPromotions';
import BannerOffers from './BannerOffers';
import BannerFeatured from './BannerFeatured';

const AllBanners = ({
  banners,
  titlePromotions = '',
  titleOffers = '',
  titleFeatured = ''
}) => {

  return <>
    <BannerSlider banners={banners.Slider} />
    <BannerPromotions title={titlePromotions} banners={banners.ImageCards} />
    <BannerPromotions title={titlePromotions} banners={banners.TextImageCards} />
    <BannerOffers title={titleOffers} banners={banners.ProductCards} />
    <BannerFeatured title={titleFeatured} banners={banners.HorizontalCardSlider} />
    <BannerSingleImage banners={banners.FullWidth} />
  </>
};

export default AllBanners;
