import React, { useEffect } from 'react';
import Navbar from '../../layout/Navbar';
import { useNavigate } from 'react-router';
import { checkToken } from '../../../store/services/Login';

function HomePage() {
  const navigate = useNavigate();

  useEffect(() => {
    ;(async () => {
      try {
        const token = localStorage.getItem('jwt');
        await checkToken(token);
      } catch (error) {
        navigate('/login', { state: { expiredSession: true } });
      }
    })();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="assists-home">
      <Navbar />
    </div>
  );
}

export default HomePage;
