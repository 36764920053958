import React from 'react'
import bannerCondiciones from './../../../assets/images/banner-condiciones.jpg';
import { Container } from '@mui/material';

const BannerSection = () => {
  return (
    <div>
      <img src={bannerCondiciones} alt="Condiciones" width="100%" />
    </div>
  );
};

const ContentSection = () => {
  return (
    <div>
      <div>
          <h2 align="center">
              Condiciones de uso y ventas de productos en el sitio web www.gotrenes.com
          </h2>
          <p>Estos términos de uso del sitio web son válidos a partir del 1 de diciembre de 2021.</p>
          <p>Las definiciones de palabras con letras mayúsculas se presentan en los Términos y condiciones.</p>
          <p>
              GoTravelRes, perteneciente a Tije Travel Group, comercializará a través de www.gotrenes.com, en adelante GOTRENES, billetes y pases europeos en representación de la compañía Rail Europe, bajo los términos y condiciones detallados a continuación.
          </p>
      </div>
      <div>
          <p><b>Artículo 1.- USO DEL SITIO WEB</b></p>
          <p><b>1.1 - REALIZACIÓN DE PEDIDOS EN EL SITIO WEB</b></p>
          <p><b>Generar un pedido</b></p>
          <p>La Agencia de Viajes está autorizada a solicitar los Productos disponibles en el Sitio Web.</p>
          <p>El procedimiento para realizar pedidos incluye al menos los siguientes pasos:</p>
          <p><b>1.</b> La Agencia de Viajes deberá registrarse en el sitio y aguardar la aprobación de su solicitud. En caso que la misma sea aprobada recibirá un usuario y contraseña que le permitirá loguearse en el sitio.</p>
          <p><b>2.</b> Una vez logueada, la Agencia de Viajes realiza una búsqueda.</p>
          <p><b>3.</b> Tras la solicitud de búsqueda de la Agencia de viajes, el sitio web presenta una o más ofertas de servicios.</p>
          <p><b>4.</b> La Agencia de viajes hace clic en el producto de su elección.</p>
          <p><b>5.</b> Un resumen que muestra todas las opciones de la Agencia de viajes y el precio total de los servicios permitirá a la misma verificar los detalles del Pedido.</p>
          <p><b>6.</b> La Agencia de Viajes debe asegurarse de que toda la información mostrada sea conforme con las indicaciones del Cliente (fecha, hora, origen y destino del viaje, modalidad y dirección de entrega, precio, identidad, edad de los viajeros, etc.); no se pueden modificar una vez aprobada la Reserva.</p>
          <p><b>7.</b> La Agencia de Viajes debe entonces aprobar el Pedido.</p>
          <p><b>8.</b> El Pedido es entonces válido y vinculante.</p>
          <p><b>9.</b> La Agencia de Viajes pagará el Pedido en línea en modo seguro bajo las condiciones definidas en el artículo 6.</p>
          <p><b>10.</b> GOTRENES, en nombre de RAIL EUROPE y de sus Proveedores, proporcionará a la Agencia de Viajes, tan pronto como posible y en cualquier caso dentro de los cinco (5) días siguientes al Pedido, por correo electrónico, un recibo confirmando el mismo, mostrando los elementos esenciales como la identificación del servicio solicitado, el precio, la cantidad, las Condiciones y un código de reserva específico (el ID de reserva).</p>
          <p><b>11.</b> Todas las palabras contenidas en este correo electrónico de confirmación de pedido representan la conclusión de la venta, a menos que la Agencia de Viajes los impugne por correo electrónico en un plazo de ocho (8) días. Ellas solo pueden ser impugnadas en la medida en que sean diferentes al Pedido que haya realizado la Agencia de Viajes.</p>
          <p>GOTRENES no aceptará ninguna responsabilidad por Pedidos que no se cumplan por error u omisión por parte de la Agencia de Viajes o los Clientes. GOTRENES conservará un registro de cada Pedido, los cuales podrán ser consultados si se presentara una queja.</p>
          <p><b>12.</b> En el caso de que la Agencia de Viajes no reciba la confirmación del Pedido, es responsabilidad de la agencia ponerse en contacto con GOTRENES.</p>
          <p>Es responsabilidad de la Agencia de Viajes asegurarse de que la información (identidad, dirección de correo electrónico, dirección postal, etc.) proporcionada en el Pedido es correcta, e informar inmediatamente a GOTRENES de cualquier cambio que pudiera afectarlo, con el fin de permitirle asegurar la recepción de los Productos solicitados.</p>
          <p>También es responsabilidad de la Agencia de Viajes transmitir todos los términos y condiciones de los Productos comprados a los Clientes. A menos que se especifique lo contrario, el billete de tren no puede transferirse a un tercero ni reproducirse en cualquier forma. GOTRENES se reserva el derecho de tomar todas las medidas apropiadas si se entera de un uso o descripción fraudulentos del billete o pase de tren.</p>
          <p>La Agencia de Viajes puede hacer una reserva y pagar en línea dentro del tiempo indicado en el sitio web, dependiendo de las regulaciones del Transportista para ciertos Productos (el precio, debido a tipo de cambio de moneda, puede cambiar).</p>
          <p><b>Denegar un pedido</b></p>
          <p>GOTRENES se reserva el derecho, a su sola discreción, de someter la confirmación del Pedido a otras condiciones, suspenderlo o rechazarlo, especialmente en los siguientes casos:</p>
          <p>- Información incompleta o incorrecta proporcionada en el momento de realizar el Pedido;</p>
          <p>- Incumplimiento de pago de Pedidos anteriores o denegación de autorización por parte de la Agencia de Viajes o Banco de los clientes u otra institución financiera;</p>
          <p>- Pedidos que contienen una cantidad inusualmente alta de Productos.</p>
          <p>Cuando se rechace el pedido, GOTRENES no aceptará ningún pago o reembolsará inmediatamente el pago recibido si ya ha sido procesado.</p>
          <p><b>1.2 – ENTREGA</b></p>
          <p>La Agencia de Viajes reconoce que los billetes de tren serán emitidos en forma de billetes electrónicos (“billetes electrónicos”) o bajo la modalidad Print at Station según el tipo de Producto seleccionado.</p>
          <p>Los billetes electrónicos se entregan de forma inmediata a la Agencia de Viajes dentro de mail de Emisión del Pedido y deberán ser impresos por los pasajeros para su posterior presentación en las estaciones de tren y abordaje.</p>
          <p>En el caso de los billetes emitidos bajo la modalidad Print at Station, a la Agencia de Viajes se le informará el <i>Withdrawal reference</i>, código que deberá facilitar a sus clientes para que puedan imprimir los billetes en destino antes de viajar. Estos tipos de billetes pueden ser recogidos en cualquier estación y, para evitar problemas de último momento, se recomienda realizar la recolección de los mismos con un mínimo de 30 minutos antes al horario de partida del servicio.</p>
          <p>Los pasajeros pueden recolectar sus billetes atendidos por personal, mencionando el código PNR, pudiendo serles solicitado un documento de identidad y/o en las terminales "TVM" (Ticket Vending Machine), conocidas como "Fast Ticket".</p>
          <p>Los pasajeros sólo precisarán el código PNR y el <i>Withdrawal reference</i> previamente informado por GOTRENES, insertar una tarjeta de débito/crédito para activar la pantalla (no se generará cargo alguno) y seguir las indicaciones de la pantalla de la "TVM".</p>
          </div>
    </div>
  );
};

const CondicionesGenerales = () => {
  return (
    <div className="condiciones-generales">
        <Container maxWidth='lg' >
            <BannerSection />
            <ContentSection />
        </Container>
    </div>
  )
}

export default CondicionesGenerales;
