import React from 'react';
import { Button, Grid, Hidden, Skeleton } from '@mui/material';
import { ArrowDownward, ArrowUpward, GpsFixed } from '@mui/icons-material';
import HotelCard from './HotelCard';
import { useTranslation } from 'react-i18next';

const SORTING_OPTIONS = [
  { name: 'recommended', text: 'recommended'},
  { name: 'stars', text: 'stars'}
];

const FavoritesComponent = ({
  results,
  loading,
  activeSort,
  handleChangeSort,
  handleClickHotel,
  handleSelectHotel,
  handleDeleteFavorite
}) => {
  const { t } = useTranslation();

  const handleChangeSortAux = (option) => {
    let newActiveSort = {};
    newActiveSort.name = option.name;

    if (option.name !== 'recommended') {
      newActiveSort.sort = (activeSort?.name !== option.name || activeSort?.sort === 'descending') ? 'ascending' : 'descending';
    }

    handleChangeSort && handleChangeSort(newActiveSort);
  }

  const handleClickHotelAux = (view, hotel) => {
    if (hotel) {
      handleClickHotel(view, hotel);
    }
  }

  return (
    <Grid className='results-component'>
      {results && results.length > 0 && (
        <Grid container>
          <Grid item className='sort-options row' xs={12} md={8}>
            {t('results.hotels.sortBy')}:
            {SORTING_OPTIONS.map((option, i) => (
              <Button
                key={i}
                className={`sort-button ${activeSort?.name === option.name ? 'active' : ''}`}
                onClick={() => handleChangeSortAux(option)}
              >
                {t(`results.hotels.${option.text}`)}
                {option.name !== 'recommended' && activeSort?.name === option.name && (
                  activeSort.sort === 'ascending' ? <ArrowUpward /> : <ArrowDownward />
                )}
              </Button>
            ))}
          </Grid>

          <Grid item xs={12} md={4} textAlign='right'>
            <Button
              variant='outlined'
              size='small'
              className='map-button'
              disabled={loading}
              onClick={() => handleClickHotelAux('map', results[0])}
            >
              <GpsFixed fontSize='small' /> {t('results.hotels.seeHotelsInMap')}
            </Button>
          </Grid>
        </Grid>
      )}

      <Grid>
        {loading ? (
          Array.from(Array(2).keys()).map(elem => (
            <Grid className='hotel-card skeleton' container key={elem} columnSpacing={3}>
              <Grid item xs={4} lg={3} xl={4}>
                <Skeleton height='100%' width='100%' />
              </Grid>
              <Grid container item className='row info-container' xs={8} lg={9} xl={8}>
                <Grid className='left-side' item sm={12} md={8}>
                  <Skeleton width={300} height={40} variant='rounded' />
                  <Skeleton width={150} height={30} variant='rounded' />
                  <Skeleton width={150} height={30} variant='rounded' />
                  <Skeleton width={150} height={30} variant='rounded' />
                  <Skeleton width={150} height={30} variant='rounded' />
                </Grid>
                <Hidden mdDown>
                  <Grid className='right-side' item md={4}>
                    <Skeleton width={150} height={40} variant='rounded' />
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          ))
        ) : (
          results?.map((hotel, i) => (
            <HotelCard
              key={i}
              hotel={hotel}
              handleClickShowMap={() => handleClickHotelAux('map', hotel)}
              handleClickShowDetail={() => handleClickHotelAux('details', hotel)}
              handleClickShowRooms={() => handleClickHotelAux('room', hotel)}
              handleSelectHotel={handleSelectHotel}
              handleDeleteFavorite={handleDeleteFavorite}
            />
          ))
        )}
      </Grid>
    </Grid>
  );
};

export default FavoritesComponent;
