import React, { Fragment } from 'react'
import Image from '../../common/Image'
import { Link } from '@mui/material'

export default function Certificates({ client }) {
  return (
    <div className="certificates row">
      {client.certificates?.map((item, i) =>
        <Fragment key={i}>
          {item.url ? (
            <Link href={item.url} target="_blank">
              <Image img={`sites/${client.name}/${item.name}.png`} alt={item.name} />
            </Link>
          ) : (
            <Image img={`sites/${client.name}/${item.name}.png`} alt={item.name} />
          )}
        </Fragment>
      )}
    </div>
  )
}
