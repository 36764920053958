const AMENITIES = [
  { code: 'SKIA', icon: 'downhill_skiing' },
  { code: 'ACON', icon: 'ac_unit' },
  { code: 'WIFI', icon: 'wifi' },
  { code: 'SNAC', icon: 'cookie' },
  { code: 'DRIN', icon: 'local_drink' },
  { code: 'TOIL', icon: 'wc' },
  { code: 'MAGA', icon: 'newspaper' },
  { code: 'MSYS', icon: 'movie_creation' },
  { code: 'PSOC', icon: 'power' },
  { code: 'ALRO', icon: 'airline_seat_legroom_extra' },
  { code: 'PALL', icon: 'pets' },
  { code: 'UCHA', icon: 'usb' },
  { code: 'QCOM', icon: 'do_not_disturb_on_total_silence' },
  { code: 'WSEA', icon: 'event_seat' },
  { code: 'VMOB', icon: 'point_of_sale' },
  { code: 'DRNN', icon: 'local_drink' },
  { code: 'SNAN', icon: 'cookie' },
  { code: 'SKIT', icon: 'nights_stay' },
  { code: 'REST', icon: 'restaurant' },
  { code: 'AUDIO', icon: 'headphones' },
  { code: 'BAGGAGE', icon: 'luggage' },
  { code: 'INTERNET_AVAILABLE', icon: 'wifi' }
];

const LUGGAGE_CONDITIONS = {
  ITALO: {
    STANDARD: ['results.trains.luggageConditions.italoStandard']
  },
  BRIG: {
    STANDARD: ['results.trains.luggageConditions.brightlineStandard'],
    PRIMERA: ['results.trains.luggageConditions.brightlinePrimera']
  }
};

const FOOD_CONDITIONS = {
  ITALO: {
    PRIMERA: 'results.trains.foodConditions.italoPrimera'
  }
};

export {
  AMENITIES,
  LUGGAGE_CONDITIONS,
  FOOD_CONDITIONS
}
