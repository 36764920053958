import axios from 'axios';
import { getCredentials } from '../sagas/util';

const translateCondition = async (locale, text) => {
  try {
    const headers = {
      'Content-Type': 'application/json'
    };
    const data = {
      locale,
      text
    };
    const response = await axios.post(`${getCredentials().urlTrains}translate`, data, headers);
    return response?.data?.text || '';
  } catch (error) {
    return '';
  }
}

export {
  translateCondition
}