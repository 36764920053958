import React, { useEffect, useState } from 'react';
import { Button, TextField,  Typography, Grid, Select, MenuItem, Snackbar, Alert, AlertTitle, Link,   } from '@mui/material';
import { register, removeCookie } from '../../../store/services/Login';
import { useTranslation } from 'react-i18next';
import { getCountry, getSituation, getStatesByCountry } from '../../../store/services/InfoFormServices';
import InputWrapper from '../../common/InputWrapper';
import { LoadingButton } from '@mui/lab';
import { useClientData } from '../../../context/ClientContext';
import { Add } from '@mui/icons-material';

const RegisterForm = () => {
    const { t } = useTranslation();
    const { setClientData } = useClientData();
    const [form, setForm] = useState({ taxCondition: '03' });
    const [errors, setErrors] = useState({});
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [registerResponse, setRegisterResponse] = useState();
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [newOptions, setNewOptions] = useState([]);

    useEffect(() => {
        const jwt = localStorage.getItem('jwt');
        if (jwt) {
        setClientData(null);
        localStorage.clear();
        }
        removeCookie();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeName = (name) => {
        if (!name || /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g.test(name)) {
            setForm(current => ({ ...current, name }));
            setErrors(current => ({ ...current, name: '' }));
        } else {
            setErrors(current => ({ ...current, name: t('register.onlyLetters') }));
        }
    }

    const handleChangeCountry = (country) => {
        const citiesAux = getStatesByCountry(country);
        setForm(current => ({
            ...current,
            country,
            city: citiesAux[0].name
        }));
        setInputValue('');
        setNewOptions([]);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const errorsAux = {};
        let firstError = null;

        const dataToSend = {
            ...form,
            country: getCountry().find(elem => elem.id === form.country)?.description,
            enabled: '1',
            url: 'no definido'
        }

        const fields = [
            'name',
            'fantasyName',
            'companyName',
            'telephone',
            'country',
            'city',
            'taxCondition',
            'taxIdentification',
            'email',
            'address'
        ];

        if (form.country === 11) {
            fields.push('enablingFile');
        }

        fields.forEach(field => {
            if (!dataToSend[field] || dataToSend[field].replace(/^\s+|\s+$/g, '').length === 0) {
                errorsAux[field] = t('register.enterValue');
                firstError = firstError || field;
            }
        });

        setErrors(errorsAux);

        if (firstError !== null) {
            document.forms['registerForm'][firstError].focus();
            setOpenSnackbar(true);
        } else {
            setLoading(true);
            register(dataToSend, '296451a3f969eed91125545734615a9e6cb98116').then(res => {
                setRegisterResponse(res);
                setLoading(false);
            });
        }
    };

    const handleAddNewOption = () => {
        setNewOptions(current => [...current, inputValue]);
        setInputValue('');
      }

    return (
        <Grid className='form'>
            <Typography className='title'>{t('register.title')}</Typography>

            <form name='registerForm' onSubmit={handleSubmit}>
                <Grid container columnSpacing={4} rowSpacing={1}>
                    <Grid item xs={12} sm={6}>
                        <InputWrapper
                            label={t('register.lastNameFirstNameUser')}
                            error
                            helperText={errors['name'] || ''}
                        >
                            <TextField
                                fullWidth
                                name='name'
                                placeholder={t('register.lastNameFirstName')}
                                size='small'
                                value={form.name || ''}
                                onChange={(e) => handleChangeName(e.target.value)}
                            />
                        </InputWrapper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputWrapper
                            label={t('register.agencyName')}
                            error
                            helperText={errors['fantasyName'] || ''}
                        >
                            <TextField
                                fullWidth
                                name='fantasyName'
                                placeholder={t('register.agencyName')}
                                size='small'
                                value={form.fantasyName || ''}
                                onChange={(e) => setForm(current => ({ ...current, fantasyName: e.target.value }))}
                            />
                        </InputWrapper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputWrapper
                            label={t('register.businessName')}
                            error
                            helperText={errors['companyName'] || ''}
                        >
                            <TextField
                                fullWidth
                                name='companyName'
                                placeholder={t('register.businessName')}
                                size='small'
                                value={form.companyName || ''}
                                onChange={(e) => setForm(current => ({ ...current, companyName: e.target.value }))}
                            />
                        </InputWrapper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputWrapper
                            label={t('register.phoneNumber')}
                            error
                            helperText={errors['telephone'] || ''}
                        >
                            <TextField
                                fullWidth
                                name='telephone'
                                placeholder={t('register.phoneNumber')}
                                size='small'
                                value={form.telephone || ''}
                                onChange={(e) => setForm(current => ({ ...current, telephone: e.target.value }))}
                            />
                        </InputWrapper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputWrapper
                            label={t('register.country')}
                            error
                            helperText={errors['country'] || ''}
                        >
                            <Select
                                fullWidth
                                name='country'
                                size='small'
                                displayEmpty
                                value={form.country || ''}
                                onChange={(e) => handleChangeCountry(e.target.value)}
                                className={!Boolean(form.country) ? 'empty-select' : ''}
                            >
                                <MenuItem value='' disabled>{t('register.selectCountry')}</MenuItem>
                                {getCountry()?.map(option => (
                                    <MenuItem key={option.id} value={option.id}>{option.description}</MenuItem>
                                ))}
                            </Select>
                        </InputWrapper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputWrapper
                            label={t('register.city')}
                            error
                            helperText={errors['city'] || ''}
                        >
                            <Select
                                fullWidth
                                name='city'
                                placeholder={t('register.city')}
                                size='small'
                                displayEmpty
                                value={form.city || ''}
                                onChange={(e) => setForm(current => ({ ...current, city: e.target.value }))}
                                className={!Boolean(form.city) ? 'empty-select' : ''}
                            >
                                <MenuItem value='' disabled>{t('register.selectCity')}</MenuItem>
                                {getStatesByCountry(form.country)?.map(option => (
                                    <MenuItem key={option.id} value={option.name}>{option.name}</MenuItem>
                                ))}
                                {newOptions?.map((option, i) => (
                                    <MenuItem key={i} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                                {form.country && (
                                    <div style={{ padding: '6px 16px', display: 'flex' }}>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            value={inputValue}
                                            onChange={e => {
                                                if (!e.target?.value || /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g.test(e.target.value)) {
                                                    setInputValue(e.target.value)
                                                }
                                            }}
                                            onClick={e => e.stopPropagation()}
                                            onKeyDown={(e) => e.stopPropagation()}
                                        />
                                        <Button variant='contained' disabled={!inputValue} onClick={handleAddNewOption} className='ml-05'>
                                            <Add />
                                        </Button>
                                    </div>
                                )}
                            </Select>
                        </InputWrapper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputWrapper
                            label={t('register.taxSituation')}
                            error
                            helperText={errors['taxCondition'] || ''}
                        >
                            <Select
                                fullWidth
                                name='taxCondition'
                                size='small'
                                value={form.taxCondition || ''}
                                onChange={(e) => setForm(current => ({ ...current, taxCondition: e.target.value }))}
                            >
                                {getSituation()?.map(option => (
                                    <MenuItem key={option.code} value={option.code}>{option.description}</MenuItem>
                                ))}
                            </Select>
                        </InputWrapper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputWrapper
                            label={t('register.taxId')}
                            error
                            helperText={errors['taxIdentification'] || ''}
                        >
                            <TextField
                                fullWidth
                                name='taxIdentification'
                                placeholder={t('register.taxId')}
                                size='small'
                                value={form.taxIdentification || ''}
                                onChange={(e) => setForm(current => ({ ...current, taxIdentification: e.target.value }))}
                            />
                        </InputWrapper>
                    </Grid>
                    {form.country === 11 && (
                        <Grid item xs={12} sm={6}>
                            <InputWrapper
                                label={t('register.enablingFileNumber')}
                                error
                                helperText={errors['enablingFile'] || ''}
                            >
                                <TextField
                                    fullWidth
                                    name='enablingFile'
                                    placeholder={t('register.onlyArgentines')}
                                    size='small'
                                    value={form.enablingFile || ''}
                                    onChange={(e) => setForm(current => ({ ...current, enablingFile: e.target.value }))}
                                />
                            </InputWrapper>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                        <InputWrapper
                            label={t('register.email')}
                            error
                            helperText={errors['email'] || ''}
                        >
                            <TextField
                                type='email'
                                fullWidth
                                name='email'
                                placeholder={t('register.email')}
                                size='small'
                                value={form.email || ''}
                                onChange={(e) => setForm(current => ({ ...current, email: e.target.value }))}
                            />
                        </InputWrapper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputWrapper
                            label={t('register.address')}
                            error
                            helperText={errors['address'] || ''}
                        >
                            <TextField
                                fullWidth
                                name='address'
                                placeholder={t('register.address')}
                                size='small'
                                value={form.address || ''}
                                onChange={(e) => setForm(current => ({ ...current, address: e.target.value }))}
                            />
                        </InputWrapper>
                    </Grid>

                    {registerResponse && (
                        <Grid item xs={12}>
                            {registerResponse.status ? (
                                <Grid container spacing={2}>
                                    <Grid item justifyContent='center' xs={12}>
                                    <Alert severity='success'>
                                        <AlertTitle><strong>{t('register.created')}</strong></AlertTitle>
                                        {t('register.requestSent')}
                                    </Alert>
                                    </Grid>
                                    <Grid item justifyContent='center' xs={12}>
                                        <Link href='/login' target='_self'>
                                            <Button fullWidth variant='outlined' className='go-login-button'>
                                                {t('register.goLogin')}
                                            </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Alert severity='warning'>{registerResponse.message}</Alert>
                            )}
                        </Grid>
                    )}
                </Grid>

                {!Boolean(registerResponse) && (
                    <LoadingButton
                        type='submit'
                        fullWidth
                        variant='contained'
                        className='submit-button'
                        loading={loading}
                    >
                        {t('register.create')}
                    </LoadingButton>
                )}
            </form>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={() => setOpenSnackbar(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setOpenSnackbar(null)} severity='error' sx={{ width: '100%' }}>
                    {t('register.invalidForm')}
                </Alert>
            </Snackbar>
        </Grid>
    )
}

export default RegisterForm;
