import React, { useEffect, useState } from 'react';
import { searchActionAssists, startCheckoutAction } from '../../../store/actions';
import { connect } from 'react-redux';
import Multibuscador from '../../common/Multibuscador/Multibuscador';
import { Card, CardContent, Container, Grid, Typography, Button, CardHeader, CardActions, Box, Skeleton, Alert } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import ResultsCard from './ResultsCard';
import { useClientData } from '../../../context/ClientContext';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import Image from '../../common/Image';
import AgeCardSelection from './AgeCardSelection';
import CardCoverage from './CardCoverage';
import Preloader from '../../common/Preloader';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ComparationView, { ComparationResultsView } from './ComparationView';

const optionsSearch = {
  '1': 'Dentro de mi país',
  '2': 'Europa',
  '3': 'Estados Unidos y Canadá',
  '4': 'México, Caribe y Centroamérica',
  '5': 'Sudamérica',
  '6': 'Asia',
  '7': 'Oceanía',
  '8': 'África',
  '9': 'Multidestino'
};

function ResultsView({
  isLoading,
  resultSearch,
  searchProducts,
  error,
  apiCheckout,
  errorApiCheckout,
  ...props
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const { currentProduct } = useClientData();

  const [dataForm, setDataForm] = useState(location.state?.formData || {});
  const [selectedRate, setSelectedRate] = useState({});
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState();
  const [noResults, setNoResults] = useState();
  const [loadingCheckout, setLoadingCheckout] = useState(false);

  const [selectedRange, setSelectedRange] = useState();
  const [ranges, setRanges] = useState([]);
  const [wasNoMatchingOnce, setWasNoMatchingOnce] = useState(false);
  const [openCoverage, setOpenCoverage] = useState(false);
  const [coverageRate, setCoverageRate] = useState(null);

  const [compareList, setCompareList] = useState([]);
  const [openCompareModal, setOpenCompareModal] = useState(false);
  const [compareModalRate, setCompareModalRate] = useState(null);
  const [showComparisonResults, setShowComparisonResults] = useState(false);

  const assistanceProduct = JSON.parse(localStorage.getItem('products') || '[]')
    ?.find((product) => product.short_name === 'ASISTENCIA');
  const token = assistanceProduct?.parameters?.['API-Token'];
  const accessToken = localStorage.getItem('jwt');
  const tokenCheckout =
    currentProduct?.config?.api_checkout_token ||
    currentProduct?.config_work_unit?.api_checkout_token;

  useEffect(() => {
    if (location.state?.formData) {
      setDataForm(location.state.formData);
    }

    const {
      checkin,
      checkout,
      days,
      destination,
      nationality,
      type,
      resultStringAge,
    } = params;

    const passengerCounts = resultStringAge.split('-').map((count) => parseInt(count, 10));
    const updatedPassengers = {
      jovenes: passengerCounts[0] || 0,
      adultos: passengerCounts[1] || 0,
      seniors: passengerCounts[2] || 0,
      olderAdult: passengerCounts[3] || 0,
    };
    const newFomattedCheckin = dayjs(params.checkin, 'DDMMYYYY').format('DD/MM/YYYY');
    const newFormattedCheckout = dayjs(params.checkout, 'DDMMYYYY').format('DD/MM/YYYY');

    const newDataForm = {
      ...params,
      checkin: newFomattedCheckin,
      checkout: newFormattedCheckout,
      passengers: updatedPassengers,
      type: type
    };

    if (token) {
      const formattedCheckin = dayjs(checkin, 'DDMMYYYY').format('YYYY-MM-DD');
      const formattedCheckout = dayjs(checkout, 'DDMMYYYY').format('YYYY-MM-DD');

      let finalDestination = optionsSearch[destination];

      if (destination === '3') {
        finalDestination = 'Estados Unidos';
      }
      else if (destination === '4' || destination === '5') {
        finalDestination = 'Caribe y Latinoamérica';
      }

      const dataToSend = {
        type: type,
        destination: finalDestination,
        checkin: formattedCheckin,
        checkout: formattedCheckout,
        days: days,
        passengers: resultStringAge,
        nationality,
        token,
      };

      setLoading(true);
      searchProducts(token, dataToSend);
      setDataForm(newDataForm);
      handleBackFromComparison()
    }
  }, [location]);

  useEffect(() => {
    if (loading) {
      setSelectedRate({});
      setNoResults(false);

      if (resultSearch && resultSearch.length > 0) {
        const hasValidRates = resultSearch.some(elem => elem.rates && elem.rates.length > 0);
  
        if (hasValidRates) {
          setResults(resultSearch);
          const foundRanges = resultSearch.map(option => ({
            range: (option.range === "1 - 45" || option.range === "46 - 75")
              ? "0-75"
              : option.range,
            quantity: option.quantity
          }));

          setRanges(foundRanges);
          if (foundRanges.length > 0) {

            const currentRange = foundRanges[0].range;

            setSelectedRange(currentRange);
          }
        } else {
          setNoResults(true);
        }
      } else {
        setNoResults(true);
      }
      setLoading(false);
    }
  }, [resultSearch]);

  useEffect(() => {
    if (loadingCheckout && apiCheckout && Object.keys(apiCheckout).length !== 0) {
      if (!errorApiCheckout) {
        const iframePath = params.tokenIframe ? `${params.tokenIframe}/` : '';
        if (apiCheckout.baseDomain) {
          window.location.href = apiCheckout.urlRedirect.replace(
            apiCheckout.baseDomain,
            apiCheckout.baseDomain + iframePath
          );
        } else {
          window.location.href = iframePath + `${window.location.origin}/${apiCheckout.urlRedirect}`;
        }
      } else {
        setLoadingCheckout(true);
      }
    }
  }, [loadingCheckout, apiCheckout, errorApiCheckout, params.tokenIframe]);

  const handleCompare = (rate, isCompare) => {
    if (isCompare) {
      setCompareList((prev) => {
        const exists = prev.some((item) => item.rateId === rate.rateId);
        if (exists) return prev;
        if (prev.length >= 4) {
          // alert('No puedes comparar más de 4 planes a la vez.');
          return prev;
        }

        return [...prev, rate];
      });
    } else {
      setCompareList((prev) => prev.filter((item) => item.rateId !== rate.rateId));
    }
  };

  const clearCompareList = () => {
    setCompareList([]);
  };

  const handleRemoveRate = (rateId) => {
    const newList = compareList.filter(item => String(item.rateId) !== String(rateId));

    setCompareList(newList);
  };

  const handleOpenCompareModal = () => {
    setCompareModalRate(null);

    setOpenCompareModal(true);

  };

  useEffect(() => {
    if (compareList.length > 1 && !showComparisonResults) {
      handleOpenCompareModal();
    }
  }, [compareList, showComparisonResults]);

  const handleCloseCompareModal = () => {
    setCompareModalRate(null);
    setOpenCompareModal(false);
  };

  const handleOpenCoverage = (rate) => {
    setCoverageRate(rate);
    setOpenCoverage(true);
  };

  const handleCloseCoverage = () => {
    setOpenCoverage(false);
    setCoverageRate(null);
  };

  const handleRangeSelect = (range) => {
    setSelectedRange(range);
    setCompareList([])
      // setRangeHasBeenChosen(true);
    setCompareList([]) 
  };

  const addPlanToSelectedRate = (plan, range, quantity) => {
    setSelectedRate((prevSelectedRate) => {
      return {
        ...prevSelectedRate,
        [range]: {
          ...plan,
          range,
          quantity,
        },
      };
    });
  };

  const handleSelectRate = (rate, range, quantity) => {
    addPlanToSelectedRate(rate, range, quantity);

    let selectedRateAux = {
      ...selectedRate,
      [range]: { ...rate, range, quantity }
    }

    selectedRateAux = Object.entries(selectedRateAux).reduce((acc, current) => {
      const [key, value] = current;
      if (value.product !== rate.product) {
        return acc;
      }

      return { ...acc, [key]: value };
    }, {})

    setSelectedRate(selectedRateAux)

    const currentIndex = ranges.findIndex((r) => r.range === range);
    if (currentIndex > -1 && currentIndex < ranges.length - 1) {
      const nextRange = ranges[currentIndex + 1].range;
      setSelectedRange(nextRange);
    }
  };

  const handleSelectPlanFromComparison = (selectedPlan) => {
    const range = selectedRange || '0-75';
    const quantity = 1;
    addPlanToSelectedRate(selectedPlan, range, quantity);
  };

  const unifyRange = (range) => {
    if (range === "1 - 45" || range === "46 - 75") {
      return "0-75";
    }
    return range;
  };

  const selectedResult =
    results && selectedRange
      ? results.find((r) => unifyRange(r.range) === unifyRange(selectedRange))
      : null;

  if (selectedResult) {
    selectedResult.range = unifyRange(selectedResult.range);
  }

  let filteredRates = selectedResult?.rates || [];
  let sortedRates = filteredRates.sort((a, b) => {
    let priceA = a.prices[0]?.totalPromotion ?? a.prices[0]?.totalValuePVP;
    let priceB = b.prices[0]?.totalPromotion ?? b.prices[0]?.totalValuePVP;

    return priceA - priceB;
  });

  if ( selectedRange !== ranges[0]?.range && selectedRate[ranges[0].range]?.product) {
    sortedRates = sortedRates.filter(
      (rate) => rate.product === selectedRate[ranges[0].range]?.product
    );
  }

  const noMatchingRates =
    selectedResult &&
    selectedRate[ranges[0].range]?.product &&
    sortedRates.length === 0;

  useEffect(() => {
    if (noMatchingRates && !wasNoMatchingOnce) {
      setWasNoMatchingOnce(true);
    }
  }, [noMatchingRates, wasNoMatchingOnce]);

  const noRatesSelected = Object.keys(selectedRate).length === 0;

  const noResultsForRange = sortedRates.length === 0;

  const showNoAssistsError = noMatchingRates || (noRatesSelected && noResultsForRange);

  useEffect(() => {
    if (
      props.comparationResults &&
      Object.keys(props.comparationResults).length > 0
    ) {
      setShowComparisonResults(true);
    } else {
      setShowComparisonResults(false); 
    }
  }, [props.comparationResults]);

  useEffect(() => {
    if (selectedRange && ranges.length && selectedRange === ranges[0].range && wasNoMatchingOnce) {
      setSelectedRate({});
      setWasNoMatchingOnce(false);
    }
  }, [selectedRange, ranges, wasNoMatchingOnce, selectedRate]);

  const RightMenu = ({ selectedRate }) => {
    const handleReserve = () => {
      if (!selectedRate) return;
      const clientIp = localStorage.getItem('ip') || '127.0.0.1';
      const ages = results.flatMap((option) => Array(option.quantity).fill(option.maximumAge));

      const tokenProduct = results.length > 0 ? results[0].tokenProduct : null;

      const dataProduct = {
        tokenProduct,
        idRates: Object.values(selectedRate).map(elem => elem.rateId).join('-'),
        isOffer: selectedRate.isOffer ? '1' : '0',
        urlRedirect: window.location.pathname,
        supplier: null,
        ip: clientIp,
        ages,
        loginData: {
          idClient: currentProduct.parameters.userId || 'defaultClientId',
          userName: currentProduct.parameters.username || 'defaultUserName',
        },
        officeTokenSelected: token,
        userIdSelected: currentProduct.parameters.userId || 0,
        officeId: dataForm.officeId || 0,
        codeDiscount: dataForm.promotions || null,
      };

      const requestData = {
        module: 'assistmed',
        dataProduct,
      };

      setLoadingCheckout(true);
      props.startCheckout(accessToken, tokenCheckout, requestData);
      // setLoadingCheckout(false);
    };

    const totalPrice = Object.values(selectedRate).reduce((sum, rate) => {
      const p = rate.prices?.[0];
      const rateTotal = p?.totalPromotion ?? p?.totalValuePVP ?? 0;
      return sum + rateTotal;
    }, 0);

    const formattedTotalPrice = totalPrice ? totalPrice.toFixed(0) : 'N/A';

    const days = dataForm.days || 'N/A';
    const checkin = dataForm.checkin || 'N/A';
    const checkout = dataForm.checkout || 'N/A';
    const destination = dataForm.destination || 'N/A';
    const totalPassengers = Object.values(selectedRate).reduce((acc, r) => acc + (r.quantity || 0), 0);

    const isDisabledReserveButton = () => {
      if (Object.keys(selectedRate).length > 0) {
        const totalQuantity = results.reduce((acc, o) => acc + (o.quantity || 0), 0);
        return totalQuantity !== totalPassengers;
      }

      return true;
    };

    return (
      <Grid className="right-side">
        <Card>
          <CardHeader
            title={Object.keys(selectedRate).length === 0
              ? <span>{t('results.assists.noSelectedRate')}</span>
              : <span style={{ fontSize: 15 }}>{t('results.assists.totalInsurance')}</span>
            }
            subheader={Object.keys(selectedRate).length === 0
              ? <span>{t('results.assists.selectRateToContinue')}</span>
              : <div className="column">
                <Typography>
                  <span className="price-currency">{t('results.assists.exchangeRate')}</span>
                  <span className="price"> {formattedTotalPrice}</span>
                </Typography>
              </div>
            }
          />

          <CardContent className="column">
            {Object.keys(selectedRate).length > 0 && (
              <Typography className="passengers">
                {totalPassengers}{' '}
                {totalPassengers > 1
                  ? t('results.assists.passengers')
                  : t('results.assists.passenger')}
              </Typography>
            )}

            <LoadingButton
              fullWidth
              variant="contained"
              color="secondary"
              className="submit-button"
              loading={loadingCheckout}
              disabled={isDisabledReserveButton()}
              onClick={handleReserve}
            >
              <span>{t('common.reserve')}</span>
            </LoadingButton>
                
            {Object.keys(selectedRate).length === 0 ? (
              <Image
                img="noCoverages.png"
                alt="No coverage selected"
                className='no-coverages'
              />
            ) : (
              <>
                <Box className="travel-details">
                  <Box className="travel-details__row">
                    <Typography className="travel-details__item">
                      <Typography component="span" sx={{fontWeight: 600}}>
                        {t('results.assists.exit')}:{' '}
                      </Typography>
                      <span className="travel-details__value">{checkin}</span>
                    </Typography>
                    <Typography className="travel-details__item">
                      {t('results.assists.return')}:{' '}
                      <span className="travel-details__value">{checkout}</span>
                    </Typography>
                  </Box>

                  <Box className="travel-details__row">
                    <Typography className="travel-details__item">
                      <Typography component="span" sx={{fontWeight: 600}}>
                        {t('results.assists.days')}:{' '}
                      </Typography>
                      <span className="travel-details__value">{days}</span>
                    </Typography>
                    <Typography className="travel-details__item">
                      <Typography component="span" sx={{fontWeight: 600}}>
                        {t('results.assists.destination')}:{' '}
                      </Typography>
                      <span className="travel-details__value">{optionsSearch[destination]}</span>
                    </Typography>
                  </Box>
                </Box>

                <Grid className="segment-resume">
                  {Object.values(selectedRate).map((rate, index) => (
                    <InsuranceCardResume key={index} rate={rate} />
                  ))}
                </Grid>
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const InsuranceCardResume = ({ rate }) => {
    return (
      <Card className="insurance-card-resume" sx={{mb: 2}}>
        <CardHeader
          className="insurance-card-resume__header"
          title={
            <Typography className="insurance-card-resume__title">
              <Typography component="span" sx={{fontWeight: 600}}>
                {t('results.assists.of')} {rate?.range || '1-45'} {t('results.assists.years')}:
              </Typography> {rate?.quantity}{' '}
              {rate?.quantity > 1 ? t('results.assists.passengers') : t('results.assists.passenger')}
            </Typography>
          }
        />

        <CardContent className="insurance-card-resume__content">
          <Box className="insurance-card-resume__content-box">
            <Box className="insurance-card__badge">
              <img
                src={rate.mediaImage}
                alt="Rate Icon"
                style={{  objectFit: 'cover' }}
              />
            </Box>
            <Typography className="insurance-card__name">
              {rate?.product || 'Sin Nombre'}
            </Typography>
          </Box>

          <Typography className="insurance-card-resume__link" onClick={() => handleOpenCoverage(rate)} >
            <VisibilityIcon sx={{ fontSize: 20, mb: '2px' }} />
            {t('results.assists.seeCoverage')}
          </Typography>
        </CardContent>

        <CardActions className="insurance-card-resume__footer">
          <Typography className="insurance-card-resume__footer-text">
            {t('results.assists.totalCost')}
          </Typography>
          <Typography className="insurance-card-resume__footer-price" >
            {t('results.assists.exchangeRate')}
            {(() => {
              const promotion = rate?.totalPromotion;
              const basePrice = rate?.totalLocalValuePvp ?? 0;
              const finalPrice = promotion && promotion > 0 ? promotion : basePrice;
              const rounded = Math.round(finalPrice);
              return ` ${rounded}`;
            })()}
          </Typography>
        </CardActions>
      </Card>
    );
  };

  const handleOpenComparisonTable = () => {
    setShowComparisonResults(true);
  };

  const handleBackFromComparison = () => {
    setShowComparisonResults(false);
    props.clearCompareCoverageAction()
    clearCompareList()
  };

  return (
    <Grid className="assists-results results-page">
      <Grid className="search-container">
        <Container>
          <Multibuscador module="assists" defaultData={dataForm} isResultsView />
        </Container>
      </Grid>

      {loading && (
        <Preloader
          addDots
          image={'sites/gotravelres/preloaderAssists.gif'}
          text={t('common.searchingBestPrices')}
        />
      )}

      <Container>
        {loading ? (
          <Grid style={{ padding: 10 }}>
            <Box className="row mb-1" style={{ width: '50%' }}>
              <Skeleton variant="circular" width={30} height={30} className="mr-05" />
              <Skeleton variant="rounded" height={45} style={{ flex: 1 }} />
            </Box>
            <Skeleton className="mb-1" variant="rounded" width="75%" height={25} />
            <Skeleton className="mb-1" variant="rounded" width="100%" height={80} />
            <Skeleton className="mb-1" variant="rounded" width="100%" height={80} />
            <Skeleton variant="rounded" width="100%" height={80} />
          </Grid>
        ) : noResults ? (
          <Alert severity="error" className="alert-no-results">
            <Typography className="title-noassists">{t('results.assists.noAssists')}</Typography>
            <Typography className="subtitle">{t('results.assists.otherSearch')}</Typography>
          </Alert>
        ) : showComparisonResults ? (
          <ComparationResultsView
            client={props.client}
            compareData={props.comparationResults}
            onBack={handleBackFromComparison}
            compareList={compareList}
            token={token}
            ranges={selectedRange}
            onSelectRate={handleSelectPlanFromComparison}
            handleAdd={handleCompare}
            dataForm={dataForm}
            optionsSearch={optionsSearch}
            results={results}
            handleRemove={handleRemoveRate}
          />
        ) : (
          <Grid className="results-component" container spacing={3} columns={24}>
            <Grid item xs={24} md={16} className="left-side">
              <Card>
                <AgeCardSelection
                  ranges={ranges}
                  onRangeSelect={handleRangeSelect}
                  selectedRange={selectedRange}
                  selectedRate= {selectedRate}
                />
                <CardContent sx={{ p: 2 }}>
                  <Grid className="title-container row" sx={{ mb: 2 }} >
                    <Typography className="title row" >
                      {t('results.assists.selectSegment')} de {selectedRange?.replace('-', ' a ')} {t('results.assists.years')}
                    </Typography>
                  </Grid>

                  {!loading && selectedResult && (
                    showNoAssistsError ? (
                      <Alert severity="error" className="alert-no-results">
                        <Typography className="title-noassists">
                          {t('results.assists.noAssistsForThisProduct')}
                        </Typography>
                        <Typography className="subtitle">
                          {t('results.assists.otherSearch')}
                        </Typography>
                      </Alert>
                    ) : (
                      <Grid container className="results-container" spacing={2} alignItems="stretch">
                        {sortedRates.map((rate, rateIndex) => (
                          <Grid
                            item
                            xs={10}
                            sm={6}
                            md={4}
                            sx={{ display: 'flex' }}
                            key={`${selectedRange}-${rateIndex}`}
                          >
                            <ResultsCard
                              rate={rate}
                              quantity={selectedResult.quantity}
                              selected={
                                selectedRate?.[selectedResult.range]?.rateId === rate.rateId
                              }
                              onSelect={(selectedRate) =>
                                handleSelectRate(selectedRate, selectedResult.range, selectedResult.quantity)
                              }
                              onCompare={handleCompare}
                              compareList={compareList}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    )
                  )}

                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={24} md={8} className="right-side">
              <RightMenu selectedRate={selectedRate} />
            </Grid>
          </Grid>
        )}
      </Container>

      {/* Coverage modal */}
      <CardCoverage open={openCoverage} onClose={handleCloseCoverage} rate={coverageRate} />

      {/* Vista/Modal de comparación */}
      <ComparationView
        client={props.client}
        open={openCompareModal}
        onClose={handleCloseCompareModal}
        rate={compareModalRate}
        selectedRates={compareList}
        // onCompare={handleCompare}
        onCompare={() => handleOpenComparisonTable()}
        onToggleCompare={handleCompare}
        ranges={selectedRange}
        onClearCompareList={clearCompareList}
        dataForm={dataForm}
      />
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.assistsReducer.isLoading,
  resultSearch: state.assistsReducer.resultSearch,
  apiCheckout: state.assistsReducer.apiCheckout,
  comparationResults: state.assistsReducer.compareResults,
  error: state.assistsReducer.error
});

const mapDispatchToProps = (dispatch) => ({
  searchProducts: (token, dataForm) => dispatch(searchActionAssists(token, dataForm)),
  startCheckout: (access, tokenCheckout, data) => dispatch(startCheckoutAction(access, tokenCheckout, data)),
  clearCompareCoverageAction: () => dispatch({ type: 'CLEAR_COMPARE_COVERAGE' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultsView);
