import React from 'react'
import {Typography,  Container, Box, Grid,} from "@mui/material";
import providers from "../../../assets/images/prehome/logos.jpg";
import heroBackground from "../../../assets/images/prehome/background-thankyoupage-confirmation.jpg";

function ThanksPage() {
  return (
    <div>
      <Box className="hero-thankyoupage-confirmation" sx={{ backgroundImage: `url(${heroBackground})` }}>
        <Container sx={{ display: 'flex' }}>
          <Grid container sx={{ margin: 'auto' }}>
            <Grid item xs={12} sm={4} md={6} />
            <Grid item xs={12} sm={8} md={6} sx={{ textAlign: "center" }}>
              <Typography variant="h1" component="h1" gutterBottom sx={{ color: "#ff5e52" }}>
                ¡Gracias por registrarte! <br />
                Procesaremos tu solicitud a la brevedad.
              </Typography>
              <Typography variant="h2" component="h2" sx={{color: '#155dff'}}>
                Recuerda que puedes contactarte con nosotros al siguiente correo:&nbsp;
                <a href="mailto:info@gotrenes.com" className='email'>info@gotrenes.com</a>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={{ py: 5 }} className="proveedores">
        <Container>
          <img className="img-fluid" src={providers} alt="Proveedores" />
        </Container>
      </Box>
    </div>
  )
}

export default ThanksPage
