import React from 'react'
import { Grid, Card, CardMedia, CardContent, Typography , Button, Link, Container, useTheme, useMediaQuery} from '@mui/material';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function BannerItem({ item, isMobile }) {
  const { image_url, image_url_mobile, title, description, button_text, link } = item;

  const getImage = () => {
    if (isMobile) {
        return image_url_mobile?.src || image_url_mobile;
    }

    return image_url?.src || image_url;
  }

  return (
    <Card className='banner-item banner-featured' sx={{ borderRadius: 4 }}>
      <Grid container>
        <Grid item xs={12} sm={6} sx={{ display: 'flex' }}>
          <Link href={link} style={{display: 'block', width: '100%'}}>
            <CardMedia
              image={getImage()}
              title={title}
              sx={{ height: 200, width: '100%', objectFit: 'cover' }}
            />
          </Link>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardContent>
            {title && <Typography variant="h5">{title}</Typography>}
            {description && <Typography variant="subtitle1" sx={{mb: 2}}>{description}</Typography>}
            {button_text && link && (
              <Button variant="contained" component={Link} href={link} target="_blank" rel="noopener noreferrer">{button_text}</Button>
            )}
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  )
}

export default function BannerFeatured({ title, banners }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const carouselResponsive = {
    breakpoint2: {
      breakpoint: { max: 4000, min: 1024 },
      items: 2,
    },
    breakpoint1: {
      breakpoint: { max: 1023, min: 0 },
      items: 1,
    },
  };

  const sortBanners = () => {
    return banners?.sort((a, b) => (a.order || Infinity) - (b.order || Infinity)) || [];
  }

  if (banners && banners.length > 0) {
    return (
      <section className='banner-featured-section'>
        <Container>
          <Typography variant="h4" component="h2" sx={{mb: 2}}>{title}</Typography>
        </Container>

        <Container className='container-slider'>
          <Carousel responsive={carouselResponsive}>
            {sortBanners().map((banner, index) => (
              <BannerItem key={index} item={banner} isMobile={isMobile} />
            ))}
          </Carousel>
        </Container>
      </section>
    )
  }
}
