import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import es from './spanish';
import pt from './portuguese';

i18n
  .use(initReactI18next)
  .init({
    lng: 'es',
    fallbackLng: ['es', 'pt'],
    debug: true,
    resources: {
      es: es,
      pt: pt
    }
  });

export default i18n;
