import React, { useEffect, useState } from 'react';
import { Card, Dialog, DialogContent, DialogTitle, Fab, Grid, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddCircleOutline, AirlineSeatReclineNormal, ChangeCircleOutlined, Close, CurrencyExchange } from '@mui/icons-material';
import { ReactComponent as BaggageImage } from '../../../assets/images/baggage/baggage.svg';
import { ReactComponent as SmallBaggage } from '../../../assets/images/baggage/smallBaggage.svg';
import { ReactComponent as MediumBaggage } from '../../../assets/images/baggage/mediumBaggage.svg';
import { ReactComponent as LargeBaggage } from '../../../assets/images/baggage/largeBaggage.svg';
import Carousel from 'react-multi-carousel';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useClientData } from '../../../context/ClientContext';
import { getClientIp } from '../../../store/services/IpServices';
import { LoadingButton } from '@mui/lab';
import { startCheckoutAction } from '../../../store/actions';
import Preloader from '../../common/Preloader';

function AvailabilityModal({
  recommendationID,
  flight,
  client,
  selectedFlights,
  open,
  availability,
  apiCheckout,
  errorApiCheckout,
  handleClose,
  ...props
}) {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentProduct, setClientData } = useClientData();
  const [loadingCheckout, setLoadingCheckout] = useState(false);

  const carouselResponsive = {
    breakpoint3: {
      breakpoint: { max: 4000, min: 768 },
      items: 3,
    },
    breakpoint2: {
      breakpoint: { max: 765, min: 585 },
      items: 2,
    },
    breakpoint1: {
      breakpoint: { max: 584, min: 0 },
      items: 1,
    }
  };

  useEffect(() => {
    if (loadingCheckout && apiCheckout && Object.keys(apiCheckout).length !== 0) {
      if (!errorApiCheckout) {
        const iframePath = params.tokenIframe ? `${params.tokenIframe}/` : '';
        if (apiCheckout.baseDomain) {
          window.location.href = apiCheckout.urlRedirect.replace(apiCheckout.baseDomain, apiCheckout.baseDomain + iframePath);
        } else {
          window.location.href = iframePath + `${window.location.origin}/${apiCheckout.urlRedirect}`;
        }
      } else {
        setLoadingCheckout(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorApiCheckout, apiCheckout]);

  const getCabinClass = (fare) => {
    const classes = {
      Y: t('results.flights.economy'),
      B: t('results.flights.business'),
      F: t('results.flights.firstClass'),
      P: t('results.flights.premium'),
      W: t('results.flights.economy')
    };

    const cabinClass = fare?.FareFeatures?.find(elem => elem.Code === 'CABIN_CLASS')?.Value;
    if (cabinClass && classes[cabinClass]) {
      return <Typography>{`${t('results.flights.class')} ${classes[cabinClass]}`}</Typography>;
    }
  }

  const getBaggages = (fare) => {
    const baggages = {
      small: {
        icon: <SmallBaggage />,
        title: t('results.flights.smallBaggageTitle'),
        description: t('results.flights.smallBaggageDescription')
      },
      medium: {
        icon: <MediumBaggage className='not-included' />,
        title: t('results.flights.mediumBaggageNotIncluded')
      },
      large: {
        icon: <LargeBaggage className='not-included' />,
        title: t('results.flights.largeBaggageNotIncluded')
      }
    };

    const mediumBaggageIncluded = fare?.FareFeatures?.find(elem => elem.Code === 'CABIN_BAG');
    if (mediumBaggageIncluded) {
      baggages['medium'] = {
        icon: <MediumBaggage />,
        title: t('results.flights.mediumBaggageTitle'),
        description: t('results.flights.mediumBaggageDescription')
      };
    }

    const largeBaggageIncluded = fare?.FareFeatures?.filter(elem => elem.Code === 'CHECKED_BAG');
    if (largeBaggageIncluded.length > 0) {
      const description = [];

      fare?.PaxFares?.forEach(elem => {
        const passenger = elem.PaxType === 'ADT'
          ? t('results.flights.adult')
          : elem.PaxType === 'CHD'
            ? t('results.flights.kid')
            : t('results.flights.baby');
        const pieces = largeBaggageIncluded.length;
        description.push(t(
          `results.flights.${pieces > 1 ? 'piecesPerPassenger' : 'piecePerPassenger'}`,
          { pieces, passenger: passenger.toLowerCase() }
        ));
      });

      description.push(t('results.flights.largeBaggageDescription'));

      baggages['large'] = {
        icon: <LargeBaggage />,
        title: t('results.flights.largeBaggageIncluded'),
        description: description.join(' ')
      };
    }

    return <Grid container className='baggage-container' spacing={1}>
      {Object.values(baggages).map((elem, i) => (
        <Grid item xs={12}>
          <Grid container className='row' key={i} spacing={1} columns={24}>
            <Grid item xs={3} className='icon-container'>{elem.icon}</Grid>
            <Grid item xs={21} className='column baddage-description'>
              <b>{elem.title}</b>
              {elem.description && <span>{elem.description}</span>}
            </Grid>
          </Grid>
        </ Grid>
      ))}
    </Grid>
  }

  const getBaggageIcons = (fare) => {
    const baggage = {};

    const mediumBaggageNotIncluded = !fare?.FareFeatures?.find(elem => elem.Code === 'CABIN_BAG');
    if (mediumBaggageNotIncluded) {
      baggage['medium'] = 'not-included';
    }

    const largeBaggageNotIncluded = !fare?.FareFeatures?.find(elem => elem.Code === 'CHECKED_BAG');
    if (largeBaggageNotIncluded) {
      baggage['large'] = 'not-included';
    }

    return <Tooltip title={getBaggages(fare)}>
      <SmallBaggage className='small' />
      <MediumBaggage className={`medium ${baggage['medium']}`} />
      <LargeBaggage className={`large ${baggage['large']}`} />
    </Tooltip>
  }

  const getFareFeatures = (fare) => {
    const allFeatures = {
      SEAT_SELECTION: { value: '-' },
      CHANGEABLE: { value: '-' },
      REFUNDABLE: { value: '-' },
    };

    const seatSelection = fare?.FareFeatures?.filter(elem => elem.Code === 'SEAT_SELECTION');
    if (seatSelection.length > 0) {
      allFeatures['SEAT_SELECTION'].value = t('results.flights.included');
      allFeatures['SEAT_SELECTION'].className = 'included';
    }

    const changeable = fare?.FareFeatures?.filter(elem => elem.Code === 'CHANGEABLE');
    if (changeable.length > 0) {
      allFeatures['CHANGEABLE'].value = changeable.find(elem => elem?.Description?.includes('WITH COST'))
        ? t('results.flights.withExtraCost')
        : t('results.flights.withoutExtraCost');
      allFeatures['CHANGEABLE'].className = 'included';
    }

    const refundable = fare?.FareFeatures?.filter(elem => elem.Code === 'REFUNDABLE');
    if (refundable.length > 0) {
      allFeatures['REFUNDABLE'].value = refundable.find(elem => elem?.Description?.includes('WITH COST'))
        ? t('results.flights.allowedWithPenalty')
        : t('results.flights.allowedWithoutPenalty');
      allFeatures['REFUNDABLE'].className = 'included';
    }

    return <>
      <div className='row'>
        <AirlineSeatReclineNormal className={allFeatures['SEAT_SELECTION'].className || ''} />
        <Typography><b>{t('results.flights.seatSelection')}:</b> {allFeatures['SEAT_SELECTION'].value}</Typography>
      </div>
      <div className='row'>
        <ChangeCircleOutlined className={allFeatures['CHANGEABLE'].className || ''} />
        <Typography><b>{t('results.flights.changes')}:</b> {allFeatures['CHANGEABLE'].value}</Typography>
      </div>
      <div className='row'>
        <CurrencyExchange className={allFeatures['REFUNDABLE'].className || ''} />
        <Typography><b>{t('results.flights.refund')}:</b> {allFeatures['REFUNDABLE'].value}</Typography>
      </div>
    </>
  }

  const formatNumber = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  const handleClickReserve = async (fare, index) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const accessToken = localStorage.getItem('jwt');
    const tokenCheckout = currentProduct?.config?.api_checkout_token || currentProduct?.config_work_unit?.api_checkout_token;
    const { currency } = params;
    const token = currentProduct?.config?.['API-Token']?.[currency];

    if (user && accessToken && tokenCheckout && token) {
      setLoadingCheckout(fare.FareID);

      const clientIp = await getClientIp();
      const urlRedirect = location.pathname;

      const requestData = {
        module: 'airNarrative',
        dataProduct: {
          recommendationID,
          fareId: flight.FareID,
          optionId: selectedFlights,
          brandedFare: fare.FareID,
          brandedFareIndex: index,
          adults: params.adults,
          children: params.kids,
          infants: params.babys,
          dateFrom: params.datesDeparture,
          dateTo: params.datesReturn,
          urlRedirect,
          supplier: null,
          ip: clientIp,
          utm: '',
          loginData: null,
          cabin: params.cabinClasses,
          urlMeta: '',
          token,
          currency
        },
        username: user?.username
      }

      props.startCheckout(accessToken, tokenCheckout, requestData);
    } else {
      const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
      if (iframePath) {
        localStorage.clear();
        setClientData(null);
        navigate(iframePath);
      } else {
        navigate('/login', { state: { expiredSession: true } });
      }
    }
  }

  return (
    <Dialog
      className='closable-modal flights-modal'
      open={open}
      maxWidth='md'
      fullWidth
      onClose={handleClose}
    >
      <Fab size='small' onClick={handleClose} className='close-button'>
        <Close fontSize='small' />
      </Fab>

      <DialogTitle className='row'>
        <BaggageImage className='baggage-image' />
        <Typography className='text'>{t('results.flights.improveFlight')}</Typography>
      </DialogTitle>

      <DialogContent>
        <Carousel responsive={carouselResponsive}>
          {availability?.AlternativeFares?.map((fare, i) => {
            const countPassengers = fare?.PaxFares?.reduce((acc, current) => acc + current.Count, 0);
            const minPrice = availability?.AlternativeFares?.[0]?.TotalAmount || 0;
            const differencePrice = (fare?.TotalAmount || 0) - minPrice;
            const differencePricePassenger = parseInt(differencePrice / countPassengers);
            const totalAmount = parseInt(fare?.TotalAmount || 0);

            return <Card key={i}>
              <div className='top text-center'>
                {getCabinClass(fare)}
              </div>

              <div className='column middle'>
                <div className='baggage-container-icons'>
                  {getBaggageIcons(fare)}
                </div>
                {getFareFeatures(fare)}
              </div>

              <div className='column bottom'>
                <Typography>+ <b>{fare.Currency} {formatNumber(differencePricePassenger)}</b> {t('results.flights.byPax')}</Typography>
                <Typography>{t('results.flights.totalPrice')} <b>{fare.Currency} {formatNumber(totalAmount)}</b></Typography>
                <LoadingButton
                  variant='outlined'
                  color='secondary'
                  disabled={Boolean(loadingCheckout)}
                  loading={loadingCheckout === fare.FareID}
                  onClick={() => handleClickReserve(fare, i)}
                >
                  <AddCircleOutline /> {t('common.reserve')}
                </LoadingButton>
              </div>
            </Card>
          })}
        </Carousel>
      </DialogContent>

      {loadingCheckout && (
        <Preloader
          addDots
          image={`sites/${client.client.name}/preloadAereos.gif`}
          text={t('common.processingRequest')}
        />
      )}
    </Dialog>
  );
}

const mapStateToProps = reducers => {
  return reducers.flightsReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    startCheckout: (access, tokenCheckout, data) => dispatch(startCheckoutAction(access, tokenCheckout, data))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityModal);
