import React, { Fragment } from 'react';
import { Card, CardContent, CardHeader, Grid, Icon, Rating } from '@mui/material';
import * as TrainsDetail from './utils/TrainsDetail';
import * as FlightsDetail from './utils/FlightsDetail';
import { useTranslation } from 'react-i18next';

export default function CardDetail({ cardDetail, module }) {
  const { t } = useTranslation();

  const getDefaultDetails = () => {
    return <Card>
      <CardHeader
        avatar={<Icon>{cardDetail.icon}</Icon>}
        title={cardDetail.title}
      />
      <CardContent>
        {cardDetail.detailItems?.map((item, i) => (
          <Grid key={i} className='details-container'>
            <img alt='hotel' src={item.image} className='mr-2' />
            <Grid className='details'>
              {item?.details?.detailProduct.map((detail, j) => (
                <Fragment key={j}>
                  {detail.className.includes('stars') ? (
                    <Rating value={parseInt(detail.description)} className={detail.className} readOnly />
                  ) : (
                    <span className={`row ${detail.className}`}>
                      {(detail.icon || detail.title) && (
                        <span className='row title'>
                          {detail.icon && <Icon>{detail.icon}</Icon>}
                          {detail.title}
                        </span>
                      )}
                      {detail.description}
                    </span>
                  )}
                </Fragment>
              ))}
            </Grid>
          </Grid>
        ))}
      </CardContent>
    </Card>
  }

  if (cardDetail) {
    return (
      <>
        {module === 'hotels' && getDefaultDetails()}
        {module === 'trains' && TrainsDetail.getCardDetail(cardDetail.bookingItems)}
        {module === 'flights' && FlightsDetail.getCardDetail(cardDetail, t)}
      </>
    );
  }
}
