import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getCredentials } from './util';

const decompressZlibData = (data) => {
  // Obtiene la respuesta encodeada en base64
  const b64Data = data;
  const pako = require('pako');
  // Decode base64 (convert ascii to binary)
  const strData = atob(b64Data);
  // Convierte string binario en un array caracter-number
  const charData = strData.split('').map(function (x) { return x.charCodeAt(0); });
  // Convierte array de numbers en un  byte-array
  const binData = new Uint8Array(charData);
  // Descomprime la data con Pako.
  // Tambien se le indica que los bytes descomprimidos sean convertidos a string por la misma libreria.
  const decompressData = pako.inflate(binData, { to: 'string' });

  return JSON.parse(decompressData);
}

function* startCheckoutAction(actions) {
  const headers = {
    'Content-Type': 'application/json',
    'token': actions.tokenCheckout,
    'authentication-token': actions.access
  };

  const requestOptions = {
    headers: headers
  };

  try {
    const response = yield call(axios.post, `${getCredentials().urlCheckout}startCheckout`, actions.data, requestOptions);
    yield put({ type: 'GET_START_CHECKOUT_SUCCESS', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_START_CHECKOUT_ERROR', payload: error });
  }
}

function* getPrebookingAction(actions) {
  const headers = {
    'Content-Type': 'application/json',
    'token': actions.tokenCheckout
  };

  const requestOptions = {
    headers: headers
  };

  try {
    const response = yield call(axios.post, `${getCredentials().urlCheckout}initStepsV2`, actions.data, requestOptions);
    yield put({ type: 'GET_PREBOOKING_SUCCESS', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_PREBOOKING_ERROR', payload: {
      status: error?.response?.status,
      ...error?.response?.data
    }});
  }
}

function* getPassengerAction(actions) {
  const headers = {
    'Content-Type': 'application/json',
    'token': actions.tokenCheckout
  };

  const requestOptions = {
    headers: headers
  };

  try {
    const response = yield call(axios.post, `${getCredentials().urlCheckout}initStepPassengerV2`, actions.data, requestOptions);
    yield put({ type: 'GET_PASSENGER_SUCCESS', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_PASSENGER_ERROR', payload: error });
  }
}

function* getPaymentAction(actions) {
  const headers = {
    'Content-Type': 'application/json',
    'token': actions.tokenCheckout,
    'authentication-token': actions.access
  };

  const requestOptions = {
    headers: headers
  };

  try {
    const credentials = getCredentials();
    const urlCheckout = 'urlApiGw' in credentials ? credentials.urlApiGw : credentials.urlCheckout
    const response = yield call(axios.post, `${urlCheckout}initStepPaymentV2`, actions.data, requestOptions);
    yield put({ type: 'GET_PAYMENT_SUCCESS', payload: decompressZlibData(response.data) });
  } catch (error) {
    yield put({ type: 'GET_PAYMENT_ERROR', payload: error });
  }
}

function* getBookingAction(actions) {
  const headers = {
    'Content-Type': 'application/json',
    'token': actions.tokenCheckout,
    'authentication-token': actions.access
  };

  const requestOptions = {
    headers: headers
  };

  try {
    const credentials = getCredentials();
    const urlCheckout = 'urlApiGw' in credentials ? credentials.urlApiGw : credentials.urlCheckout;
    const response = yield call(axios.post, `${urlCheckout}lastStepV2`, actions.data, requestOptions);
    yield put({ type: 'GET_BOOKING_SUCCESS', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_BOOKING_ERROR', payload: error?.response?.data });
  }
}

function* getRetrieveAction(actions) {
  const headers = {
    'Content-Type': 'application/json',
    'token': actions.tokenCheckout
  };

  const requestOptions = {
    headers: headers
  };

  try {
    const response = yield call(axios.post, `${getCredentials().urlCheckout}retrieve`, actions.data, requestOptions);
    yield put({ type: 'GET_RETRIEVE_SUCCESS', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_RETRIEVE_ERROR', payload: error });
  }
}

function* sendMetricUpdateMetricsActionGimmonix(actions) {
  const headers = {
    'Content-Type': "application/json"
  };

  const requestOptions = {
    headers: headers
  };

  if (actions.signalAbort) {
    requestOptions.signal = actions.signalAbort
  }

  try {
    const response = yield call(axios.put, `${getCredentials().urlMetrics}hotel_metrics/update_booking`, actions.data, requestOptions);
    yield put({ type: 'POST_HOTEL_METRICS_UPDATE_BOOKING_SUCCESS_GIMMONIX', payload: response.data });
  } catch (error) {
    yield put({ type: 'POST_HOTEL_METRICS_UPDATE_BOOKING_ERROR_GIMMONIX', payload: error?.message });
  }
}

export function* checkoutSaga() {
  yield takeEvery('START_CHECKOUT', startCheckoutAction);
  yield takeEvery('GET_PREBOOKING', getPrebookingAction);
  yield takeEvery('GET_PASSENGER', getPassengerAction);
  yield takeEvery('GET_PAYMENT', getPaymentAction);
  yield takeEvery('GET_BOOKING', getBookingAction);
  yield takeEvery('GET_RETRIEVE', getRetrieveAction);
  yield takeEvery('POST_HOTEL_METRICS_UPDATE_BOOKING_GIMMONIX', sendMetricUpdateMetricsActionGimmonix);
}
