import React from 'react';
import { Button, Container, Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Newsletter = (props) => {

  const [t] = useTranslation();

  return (
    <section className='newsletter'>
      <Container>
        <Grid className='row'>
          <Typography className='title'>{t('common.suscribe')}</Typography>
          <TextField size='small' placeholder={t('common.enterEmail')} />
          <Button variant='contained'>{t('common.showMore')}</Button>
        </Grid>
        <Grid>

          {props.client?.newsletter?.messages?.map((message, i) => (
            <Typography key={i}>{message.item}</Typography>
          ))}
        </Grid>
      </Container>
    </section>
  );
}

export default Newsletter;
