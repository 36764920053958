import React, { Fragment } from "react"
import ContentLoader from "react-content-loader"
import { Grid, Hidden } from '@mui/material';

export default function PreloadBooking() {
  return (
    <Fragment>
      <div className="wrapper preload preload-booking PreloadBooking">
        <div className="main">
          <Grid container spacing={2}>
            <Grid item md={8} className="col_content">
              {/* MOBILE CONTENT */}
              <Hidden mdUp>
                <div className="responsive">
                  <ContentLoader speed={2} title="" backgroundColor="#d1d1d1" foregroundColor="#e1e1e1"
                    width={360}
                    height={50}
                    viewBox="0 0 360 50" >
                    <circle cx="20" cy="20" r="20" />
                    <rect x="50" y="5" rx="15" ry="15" width="250" height="30" />
                  </ContentLoader>

                  <ContentLoader speed={2} title="" backgroundColor="#d1d1d1" foregroundColor="#e1e1e1"
                    width={385}
                    height={200}
                    viewBox="0 0 385 200">
                    <rect x="0" y="0" rx="10" ry="10" width="385" height="200" />
                  </ContentLoader>
                </div>
              </Hidden>
              {/* end MOBILE CONTENT */}

              <Hidden smDown>
                <ContentLoader speed={2} title="" backgroundColor="#d1d1d1" foregroundColor="#e1e1e1"
                  width={450}
                  height={50}
                  viewBox="0 0 450 50" >
                  <circle cx="20" cy="20" r="20" />
                  <rect x="50" y="5" rx="15" ry="15" width="395" height="30" />
                </ContentLoader>

                <div className="responsive">
                  <ContentLoader speed={2} title="" backgroundColor="#d1d1d1" foregroundColor="#e1e1e1"
                    width={385}
                    height={85}
                    viewBox="0 0 385 85">
                    <rect x="0" y="0" rx="5" ry="5" width="385" height="70" />
                  </ContentLoader>
                </div>

                <ContentLoader speed={2} title="" backgroundColor="#d1d1d1" foregroundColor="#e1e1e1"
                  width={450}
                  height={50}
                  viewBox="0 0 450 50" >
                  <rect x="0" y="0" rx="13" ry="13" width="395" height="26" />
                </ContentLoader>

                <div className="responsive">
                  <ContentLoader speed={2} title="" backgroundColor="#d1d1d1" foregroundColor="#e1e1e1"
                    width={200}
                    height={80}
                    viewBox="0 0 200 80">
                    <rect x="0" y="0" rx="3" ry="3" width="200" height="70" />
                  </ContentLoader>
                </div>
              </Hidden>

            </Grid>
            <Hidden smDown>
              <Grid item md={4} className="col_sidebar">
                <div className="responsive">
                  <ContentLoader speed={2} title="" backgroundColor="#d1d1d1" foregroundColor="#e1e1e1"
                    width={200}
                    height={100}
                    viewBox="0 0 200 100">
                    <rect x="0" y="0" rx="5" ry="5" width="200" height="90" />
                  </ContentLoader>

                  <ContentLoader speed={2} title="" backgroundColor="#d1d1d1" foregroundColor="#e1e1e1"
                    width={200}
                    height={60}
                    viewBox="0 0 200 60">
                    <rect x="0" y="0" rx="5" ry="5" width="200" height="60" />
                  </ContentLoader>
                </div>
              </Grid>
            </Hidden>
          </Grid>
        </div>
      </div>
    </Fragment>
  )
}
