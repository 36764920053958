import React, { Fragment } from 'react';
import { Document, Font, Image, Page, Path, Svg, Text, View } from '@react-pdf/renderer';
import { faPlane } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const STOPOVERS = [
  'results.flights.direct',
  'results.flights.oneScale',
  'results.flights.twoScales',
  'results.flights.threeScales'
];

const FontAwesomeIcon = ({ faIcon: { icon }, style = {} }) => {
  const duotone = Array.isArray(icon[4]);
  const paths = Array.isArray(icon[4]) ? icon[4] : [icon[4]];
  const color = style.color || 'black';
  return (
    <Svg viewBox={`0 0 ${icon[0]} ${icon[1]}`} style={style}>
      {paths &&
        paths.map((d, index) => (
          <Path
            d={d}
            key={index}
            fill={color}
            fillOpacity={duotone && index === 0 ? 0.4 : 1.0}
          />
        ))}
    </Svg>
  );
};

export const PDFComparation = ({
    client,
    destination,
    searchData,
    availabities,
    resultsToCompare,
    activeCriteria
  }) => {
  const { t } = useTranslation();

  const styles = {
    page: {
      margin: '15px 20px'
    },
    title: {
      fontSize: 19,
      color: client.styles.brandPrimary.value
    },
    searchDataContainer: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      fontSize: 10
    },
    passengersContainer: {
      marginTop: 20
    },
    legDataContainer: {
      marginTop: 20
    },
    searchData: {
      flexDirection: 'row',
      marginRight: 20,
      color: '#606060'
    },
    searchField: {
      color: '#000'
    },
    columnsContainer: {
      marginTop: 20
    },
    legContainer: {
      flexDirection: 'row'
    },
    column: {
      width: 110,
      padding: '0 5px'
    },
    flightInfoContainer: {
      backgroundColor: client?.styles?.cardsAlt?.header?.background
    },
    destinationContainer: {
      minHeight: 30,
      padding: '5px 5px 0'
    },
    tripTypeContainer: {
      backgroundColor: client?.styles.accentColor,
      color: '#fff',
      padding: '4px 8px',
      borderRadius: 16,
      marginRight: 10,
      fontSize: 14,
      flexDirection: 'row',
      alignItems: 'center'
    },
    planeIcon: {
      marginRight: 5,
      color: '#fff',
      width: 15
    },
    planeIconReturn: {
      transform: 'rotate(180deg)'
    },
    tripTypeTitle: {
      color: '#fff',
      fontSize: 10
    },
    legTitle: {
      fontSize: 12,
      color: client?.styles?.brandPrimary?.value,
      marginTop: 5
    },
    dataContainer: {
      height: 30,
      padding: '0 10px',
      justifyContent: 'center',
      backgroundColor: `${client?.styles?.cardsAlt?.header?.background}66`,
    },
    infoTitle: {
      fontSize: 10,
      color: client?.styles?.brandPrimary?.value
    },
    airports: {
      fontSize: 12,
      color: client?.styles?.brandPrimary?.value
    },
    criterion: {
      backgroundColor: '#fff',
      alignItems: 'center',
      fontSize: 10,
      borderTop: '1px solid #d3d3d3',
      color: '#606060',
      flexDirection: 'row'
    },
    flightInfoContainerOption: {
      alignItems: 'center',
      backgroundColor: client?.styles?.cardsAlt?.header?.background,
      flex: 1,
      justifyContent: 'flex-end'
    },
    priceContainer: {
      padding: 10,
      justifyContent: 'center',
      fontSize: 12,
      color: client?.styles?.brandPrimary?.value,
      borderTop: '2px solid #d3d3d3',
    }
  };

  const chunkSubstr = (str, size) => {
    const numChunks = Math.ceil(str.length / size);
    const chunks = new Array(numChunks);

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      chunks[i] = str.substr(o, size);
    }

    return chunks;
  };

  Font.registerHyphenationCallback((word) => {
    if (word.length > 16) {
      return chunkSubstr(word, 14);
    } else {
      return [word];
    }
  });

  const getLegTitle = (leg) => {
    const segmentsLength = leg?.Options?.[0]?.Segments?.length;
    const departure = leg?.Options?.[0]?.Segments?.[0]?.Departure?.CityName;
    const arrival = leg?.Options?.[0]?.Segments?.[segmentsLength - 1]?.Arrival?.CityName;
    return `${departure} - ${arrival}`
  }

  const getFirstColumn = (leg) => {
    const isReturn = resultsToCompare?.[0]?.Legs?.length === 2 && leg.LegNumber === 2;

    return <View style={styles.column}>
      <View style={styles.flightInfoContainer}>
        <View style={styles.destinationContainer}>
          <View style={styles.tripTypeContainer}>
            <FontAwesomeIcon faIcon={faPlane} style={{
              ...styles.planeIcon,
              ...(isReturn ? styles.planeIconReturn : {})
            }} />
            <Text style={styles.tripTypeTitle}>
              {resultsToCompare?.[0]?.Legs.length > 2
                ? `${t('results.flights.flight')} ${leg.LegNumber}`
                : leg.LegNumber === 2
                  ? t('results.flights.return')
                  : t('results.flights.departure')
              }
            </Text>
          </View>
          <Text style={styles.legTitle}>{getLegTitle(leg)}</Text>
        </View>
        <View style={styles.dataContainer}>
          <Text style={styles.infoTitle}>{t('results.flights.airline')}</Text>
        </View>
        <View style={styles.dataContainer}>
          <Text style={styles.infoTitle}>{t('results.flights.airports')}</Text>
        </View>
      </View>
      {activeCriteria.map((criterion, i) => (
        <View key={i} style={styles.dataContainer}>
          <Text style={styles.infoTitle}>
            {t(`results.flights.criteria.${criterion}`)}
          </Text>
        </View>
      ))}
    </View>
  };

  const getSmallBaggage = () => {
    return <Svg
      viewBox='0 0 13 17'
      width={9}
      height={13}
      style={{
        marginTop: 7,
        marginRight: 5
      }}
    >
      <Path
        d='M12.33 10.458v-5.7A2.265 2.265 0 0 0 10.07 2.5H8.272V.557A.557.557 0 0 0 7.716 0H5.544a.555.555 0 0 0-.553.556v1.942h-1.8A2.267 2.267 0 0 0 .93 4.76v5.7a.76.76 0 0 0-.647.746v4.187c0 .393.3.707.68.755.09.479.492.853.997.853h9.338c.505 0 .91-.374.997-.853a.77.77 0 0 0 .68-.755v-4.187a.765.765 0 0 0-.646-.748M6.102 1.111h1.056v1.388H6.101zm2.591 11.62a.256.256 0 0 1-.256.257H4.823a.255.255 0 0 1-.253-.258v-.509c0-.139.114-.253.253-.253h3.613c.142 0 .256.114.256.253zm1.256-5.26c0 .14-.114.255-.256.255H3.566a.255.255 0 0 1-.253-.255v-.8c0-.14.114-.255.253-.255h6.125c.142 0 .256.115.256.255v.8'
        fill='#7cbe4c'
      />
    </Svg>
  };

  const getMediumBaggage = (included = false) => {
    return <Svg
        viewBox='0 0 14 26'
        width={10}
        height={22}
        style={{ marginRight: 5 }}
      >
      <Path
        d='M.676 12.531v10.647c0 1.217.935 2.204 2.125 2.313V10.22a2.326 2.326 0 0 0-2.125 2.313m10.785-2.313h-.004v15.273a2.326 2.326 0 0 0 2.125-2.314v-5.304q0-.002.002-.003v-5.34a2.324 2.324 0 0 0-2.122-2.313zM10.5.585A.583.583 0 0 0 9.918 0H4.386a.586.586 0 0 0-.584.585v9.611h-.52v15.317h.375V26h1.07v-.488h4.849V26h1.07v-.488h.373V10.196h-.52zM9.297 12.893a.27.27 0 0 1-.27.269H5.233a.27.27 0 0 1-.27-.27v-.535a.27.27 0 0 1 .27-.27h3.796a.27.27 0 0 1 .269.27zm.035-2.697h-4.36V1.168h4.36z'
        fill={included ? '#7cbe4c' : '#bdbdbd'}
      />
    </Svg>
  };

  const getLargeBaggage = (included = false) => {
    return <Svg viewBox='0 0 18 26' width={14} height={22}>
      <Path
        d='M.588 6.578v16.343a2.537 2.537 0 0 0 2.316 2.523V4.056A2.535 2.535 0 0 0 .588 6.578m12.785-2.546V.637A.636.636 0 0 0 12.738 0H5.52a.637.637 0 0 0-.637.637v3.395H4.32V25.47h.408V26h1.167v-.532h6.47V26h1.167v-.532h.408V4.032zM6.158 1.273h5.939v2.76H6.158zm11.513 5.305a2.53 2.53 0 0 0-2.314-2.523h-.004v21.39a2.537 2.537 0 0 0 2.317-2.523V13.59l.002-.002v-7.01'
        fill={included ? '#7cbe4c' : '#bdbdbd'}
      />
    </Svg>
  };

  const getBaggageValue = (option, fare) => {
    if (fare) {
      return <Fragment>
        {getSmallBaggage()}
        {getMediumBaggage(fare?.FareFeatures?.find(elem => elem.Code === 'CABIN_BAG'))}
        {getLargeBaggage(fare?.FareFeatures?.find(elem => elem.Code === 'CHECKED_BAG'))}
      </Fragment>
    } else {
      const mediumLargeBaggageIncluded = !['0PC', '0K'].includes(option?.Segments?.[0]?.Baggage);

      return <Fragment>
        {getSmallBaggage()}
        {getMediumBaggage(mediumLargeBaggageIncluded)}
        {getLargeBaggage(mediumLargeBaggageIncluded)}
      </Fragment>
    }
  }

  const getChangeableValue = (fare) => {
    if (fare) {
      const changeable = fare?.FareFeatures?.filter(elem => elem.Code === 'CHANGEABLE');
      if (changeable.length > 0) {
        return <Text>
          {changeable.find(elem => elem?.Description?.includes('WITH COST'))
            ? t('results.flights.withExtraCost')
            : t('results.flights.withoutExtraCost')}
        </Text>
      }
    }

    return <Text>{t('results.flights.notAllowed')}</Text>;
  }

  const getRefundableValue = (fare) => {
    if (fare) {
      const refundable = fare?.FareFeatures?.filter(elem => elem.Code === 'REFUNDABLE');
      if (refundable.length > 0) {
        return <Text>
          {refundable.find(elem => elem?.Description?.includes('WITH COST'))
            ? t('results.flights.allowedWithPenalty')
            : t('results.flights.allowedWithoutPenalty')}
        </Text>
      }
    }

    return <Text>{t('results.flights.notRefundable')}</Text>;
  }

  const getSeatSelectionValue = (fare) => {
    if (fare) {
      const seatSelection = fare?.FareFeatures?.filter(elem => elem.Code === 'SEAT_SELECTION');
      if (seatSelection.length > 0) {
        return <Text>{t('results.flights.included')}</Text>;
      }
    }

    return <Text>{t('results.flights.withExtraCost')}</Text>;
  }

  const getCriterionFlight = (criterion, flight, option) => {
    const fare = availabities.find(elem => elem.FareID === flight.FareID)?.AlternativeFares?.[0];
    const criterionMap = {
      duration: <Text>{option.OptionDurationHours}h {option.OptionDurationMinutes}m</Text>,
      scales: <Text>{t(STOPOVERS[option.Segments.length - 1])}</Text>,
      baggage: getBaggageValue(option, fare),
      changeable: getChangeableValue(fare),
      refundable: getRefundableValue(fare),
      seatSelection: getSeatSelectionValue(fare)
    };

    return criterionMap[criterion] || '';
  }

  const getCriteriaFlight = (flight, option) => {
    return activeCriteria.map((criterion, i) => (
      <View
        key={i}
        style={{
          ...styles.dataContainer,
          ...styles.criterion
        }}
      >
        {getCriterionFlight(criterion, flight, option)}
      </View>
    ))
  }

  const getFlightColumn = (flight, legIndex) => {
    return <Fragment>
      <View style={styles.flightInfoContainerOption}>
        <View style={styles.dataContainer}>
          <Image
            style={{ padding: 5 }}
            src={require(`../../../assets/images/providersFlights/${flight?.Legs?.[legIndex]?.Options?.[0]?.Segments?.[0]?.Airline}.png`)}
          />
        </View>
        <View style={styles.dataContainer}>
          <Text style={styles.airports}>
            {flight?.Legs?.[legIndex]?.Options?.[0]?.Segments?.[0]?.Departure?.AirportCode} -&nbsp;
            {flight?.Legs?.[legIndex]?.Options?.[0]?.Segments?.slice(-1)?.[0]?.Arrival?.AirportCode}
          </Text>
        </View>
      </View>
      {getCriteriaFlight(flight, flight?.Legs?.[legIndex]?.Options?.[0])}
    </Fragment>
  };

  const formatNumber = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  const getPriceFlight = (flight) => {
    const alternativeFares = availabities.find(elem => elem.FareID === flight.FareID)?.AlternativeFares?.[0];
    const price = alternativeFares?.[0]?.TotalAmount || flight?.TotalAmount;

    return <View style={styles.priceContainer}>
      <Text>
        {flight.Currency} {formatNumber(parseInt(price))}
      </Text>
    </View>
  }

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View>
          <Text style={styles.title}>{t('results.flights.compareFlightsInDestination', { destination })}</Text>
        </View>

        <View style={{
          ...styles.searchDataContainer,
          marginTop: 20
        }}>
          <View style={styles.searchData}>
            <Text style={styles.searchField}>{t('multiSearch.flights.adults')}: </Text>
            <Text>{searchData.adults}</Text>
          </View>

          <View style={styles.searchData}>
            <Text style={styles.searchField}>{t('multiSearch.flights.kids')}: </Text>
            <Text>{searchData.kids}</Text>
          </View>

          <View style={styles.searchData}>
            <Text style={styles.searchField}>{t('multiSearch.flights.babys')}: </Text>
            <Text>{searchData.babys}</Text>
          </View>
        </View>

        {searchData.segments?.map((elem, i) => (
          <View key={i} style={styles.legDataContainer}>
            <View style={styles.searchDataContainer}>
              <View style={styles.searchData}>
                <Text style={styles.searchField}>{t('multiSearch.flights.section')} {i + 1}: </Text>
                <Text>{elem.origin?.label} - {elem.destination?.label}</Text>
              </View>
            </View>

            <View style={{
              ...styles.searchDataContainer,
              marginTop: 5
            }}>
              <View style={styles.searchData}>
                <Text style={styles.searchField}>{t('multiSearch.flights.departure')}: </Text>
                <Text>{elem.dateDeparture}</Text>
              </View>

              {searchData.tripType === 'roundtrip' && (
                <View style={styles.searchData}>
                  <Text style={styles.searchField}>{t('multiSearch.flights.return')}: </Text>
                  <Text>{elem.dateArrive}</Text>
                </View>
              )}
            </View>
          </View>
        ))}

        <View style={styles.columnsContainer}>
          {resultsToCompare?.[0]?.Legs?.map((leg, i) => (
            <View key={i} style={styles.legContainer} wrap={false}>
              {getFirstColumn(leg)}

              {resultsToCompare?.map((flight, j) => (
                <View style={styles.column} key={j}>
                  {getFlightColumn(flight, i)}
                </View>
              ))}
            </View>
          ))}

          <View style={styles.legContainer}>
            <View style={styles.column} />

            {resultsToCompare?.map((flight, j) => (
              <View style={styles.column} key={j}>
                {getPriceFlight(flight)}
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};
