import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Alert, Hidden, Snackbar, useTheme, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import LoginForm from './LoginForm';
import { useBannersData } from '../../../context/BannersContext';
import AllBanners from '../../common/Banners/AllBanners';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { bannersData } = useBannersData();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (location?.state?.expiredSession) {
      setOpenSnackbar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBackgroundImage = () => {
    const image = isMobile
      ? bannersData?.data?.modules?.['otros']?.flows?.login?.banners?.BackgroundLogin?.slice(-1)?.[0]?.image_url_mobile
      : bannersData?.data?.modules?.['otros']?.flows?.login?.banners?.BackgroundLogin?.slice(-1)?.[0]?.image_url;

    if (image) {
      return  { backgroundImage: `url('${image}')` };
    }

    return {};
  }

  return (
    <div className="wrapper-login login">
      <Grid className='banner-form-container' style={getBackgroundImage()}>
        <Container className='form-container'>
          <Grid container className='row'>
            <Grid item xs={12} md={5}>
              <LoginForm />
            </Grid>
            <Hidden mdDown>
              <Grid item md={7} className='title-container'>
                <Typography>
                  {bannersData?.data?.modules?.['otros']?.flows?.login?.banners?.BackgroundLogin?.[0]?.title}
                </Typography>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Grid>

      <AllBanners
        banners={bannersData?.data?.modules?.['otros']?.flows?.login?.banners || {}}
      />

      <Snackbar
        open={Boolean(openSnackbar)}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpenSnackbar(null)} severity="error" sx={{ width: '100%' }}>
          {t('common.expiredSession')}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Login;
