import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { getCredentials } from './util';

function* searchActionAssists(action) {
  try {
    const { token, dataForm } = action;

    const url = `${getCredentials().urlAssists}api/get_products_new/${dataForm.checkin}/${dataForm.checkout}/${dataForm.destination}/${dataForm.passengers}/${dataForm.type}/0/0/`;
    const headers = {
      'Content-Type': 'application/json',
      'token': token,
    };

    const response = yield call(axios.get, url, { headers });
    yield put({ type: 'SEARCH_ASSISTS_SUCCESS', payload: response.data });
  } catch (error) {
    yield put({ type: 'SEARCH_ASSISTS_FAILURE', error: error.message });
  }
}

function* compareResultsAction(actions) {
  try {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${actions.access}`,
      // 'decode': '1',
    };

    const response = yield call(
      axios.post,
      `${getCredentials().urlAssists}api/compare_coverages/`,
      actions.value,
      { headers }
    );

    if (response.data?.status?.toLowerCase() === 'error') {
      throw response.data;
    }

    yield put({ type: 'GET_COMPARE_PRODUCTS_SUCCESS', payload: response.data });
  } catch (error) {
    yield put({
      type: 'GET_COMPARE_PRODUCTS_ERROR',
      payload: error?.response?.data || error.message,
    });
  }
}

export function* searchSagaAssists() {
  yield takeEvery('SEARCH_ASSISTS_REQUEST', searchActionAssists);
}

export function* compareSagaResults() {
  yield takeEvery('GET_COMPARE_PRODUCTS_REQUEST', compareResultsAction);
}
