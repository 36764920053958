import React from 'react';
import { Container, Grid, Typography, Link, Box, } from '@mui/material';
import { useBannersData } from '../../context/BannersContext';
import Image from '../common/Image';
import IconComponent from '../common/IconComponent';

const Footer = ({ client }) => {
  const { bannersData } = useBannersData();

  const getLogo = () => {
    const businessUnit = JSON.parse(localStorage.getItem('businessUnit') || '{}');
    const workUnit = JSON.parse(localStorage.getItem('workUnit') || '{}');
    const bannerLogo = bannersData?.data?.modules?.['otros']?.flows?.footer?.banners?.Logo?.slice(-1)?.[0]?.image_url
      || bannersData?.data?.modules?.['otros']?.flows?.login?.banners?.Logo?.slice(-1)?.[0]?.image_url;
    const logo = bannerLogo || businessUnit?.logo?.src || workUnit?.logo?.src;
    if (logo) {
      return <Image img={logo} alt='logo-cliente' className='logo' isFullPath />
    }
  }

  return (
    <section className={`footer footer-${client.client.name}`}>
      {client?.footer?.containers && client?.footer?.containers?.map((container, containerIndex) => (
          <Container className={container.class?container.class:""} key={containerIndex}>
            <Grid container item columns={24} spacing={2} mb={2}>
              {container.columns.map((column, colIndex) => (
                <Grid item xs={24} md={column.lg} lg={column.lg} key={colIndex} sx={{ display: 'flex', flexDirection: 'column', alignItems: column.alignment || 'flex-start', justifyContent: column.justifyContent || 'normal' }}>
                  <div className={column.class ? column.class : ''}>
                    {column.rows.map((row, rowIndex) => {
                      return (
                      <Box key={rowIndex} className={row.class}>
                        {row.title && <Typography variant="h5" className='title' sx={{ display: 'flex'}} >{row.icon && <IconComponent iconName={row.icon} />} {row.title}</Typography>}
                        {row.subtitle && <Typography variant="h6" className='subtitle' sx={{ display: 'flex'}}>{row.icon && <IconComponent iconName={row.icon} />} {row.subtitle}</Typography>}
                        {row.content && row.content.map((text, textIndex) => ( <Typography key={textIndex} variant="body2" mb={2}>{row.icon && <IconComponent iconName={row.icon} />} {text}</Typography> ))}
                        {row.submenu &&
                          <Box className="row-submenu" mb={2}>
                            {row.submenu.map((item, itemIndex) => {
                              if (item.link) {
                                return (
                                  <Link key={itemIndex} href={item.link} target='_blank' variant="body2" display="block" gutterBottom className='row'>
                                    {item.icon && <IconComponent iconName={item.icon} />} {item.clave}
                                  </Link>
                                )
                              } else {
                                return (
                                  <Typography key={itemIndex} variant="body2" display="block" gutterBottom>
                                    {item.icon && <IconComponent iconName={item.icon} />} {item.clave}
                                  </Typography>
                                )
                              }
                            })}
                          </Box>
                        }

                        {row.images && row.class === "logo" ? (
                          getLogo()
                        )
                        : row.images && (
                          <Box className="row-images" mb={2}>
                           {row.images.map((image, imageIndex) => (
                              image.link && image.link !== "" ? (
                                <Link href={image.link} key={imageIndex} target='_blank'>
                                  <Image img={`sites/${client.client.name}/${image.file}`} alt={image.alt} />
                                </Link>
                              ) : (
                                <Image key={imageIndex} img={`sites/${client.client.name}/${image.file}`} alt={image.alt} />
                              )
                            ))}
                          </Box>
                        )}
                      </Box>
                    )})}
                  </div>
                </Grid>
              ))}
            </Grid>
         </Container>
      ))}
    </section>
  );
}

export default Footer;
