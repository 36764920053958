import React, { useEffect, useState } from 'react';
import {
    Grid,
    Container,
    Button,
    Card,
    CardContent,
    Tabs,
    CardActions,
    CardMedia,
    Link,
    Tab,
    Box,
    Typography,
    Icon,
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    CardActionArea
} from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import dataInfoPage from './data.json';
import ReactPlayer from 'react-player/lazy';
import Image from '../../common/Image';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3} style={{backgroundColor: 'white', borderRadius: '0 0 16px 16px'}}>
            {children}
          </Box>
        )}
      </div>
    );
  }

const a11yProps = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export default function InfoPage(props) {
    const [openModal, setOpenModal] = useState(false);
    const [dataModalVideo, setDataModalVideo] = useState([]);

    useEffect(() => {
        document.title = 'GoTrenes';
        window.scrollTo(0,0)
    }, []);

    const handleModalVideoClose = () => {
        setOpenModal(false);
    }

    const handleOpenModal = (value) => {
        setOpenModal(true);
        setDataModalVideo(value);
    }

    const CarouselItem = (props) => {
        const carouselImageUrl = require(`../../../assets/images/infoTrenes/${props.item.image}`);

        return (
            <div className='slider-infopage' style={{ backgroundImage: `url(${carouselImageUrl})` }}>
                <Container maxWidth='lg' >
                    <div style={{paddingRight: '45%', paddingLeft: 80 }}>
                        <p className='category'>Webinar</p>
                        <h1 className='title'>{props.item.title}</h1>
                        <h2 className='subtitle'>{props.item.subtitle}</h2>

                        <div className='buttons d-flex'>
                            <Button
                                className='button'
                                variant='contained'
                                color='secondary'
                                onClick={() => handleOpenModal(props)}
                            >
                                Conocé más
                            </Button>
                            {props.item.susbcribe && (
                                <Button className='button-white' size='small' variant='outlined' color='secondary' href={props.item.susbcribe} target='_blank'>Inscríbete aquí</Button>
                            )}
                        </div>
                    </div>
                </Container>
            </div>
        )
    }

    const ModalVideo = (props) => {
        return (
            <Dialog onClose={handleModalVideoClose} open={openModal} className={`dialog-video`}>
                <DialogTitle
                    id={props.id}
                    onClose={handleModalVideoClose}
                    disableTypography
                    className='dialogTitle_root'
                    sx={{ display: 'flex', alignItems: 'start' }}
                >
                    <Typography className='title-modal' component='h2' variant='h6' style={{ flexGrow: 1 }}>{dataModalVideo.item.title}</Typography>
                    <IconButton sx={{ marginTop: '-8px', marginLeft: 'auto' }} aria-label='close' className='dialogTitle_closeButton' onClick={handleModalVideoClose}>
                        <Icon>close</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogContent className='dialogContent_root'>
                    {dataModalVideo.item.url ?
                        <div className='player-wrapper' style={{ height: 300, marginBottom: 10 }}>
                            <ReactPlayer
                                url={dataModalVideo.item.url}
                                className='react-player'
                                width='100%'
                                height='100%'
                            />
                        </div>
                    : dataModalVideo.item.altVideoImage && (
                        <Image className='alt-video-image' img={`infoTrenes/${dataModalVideo.item.altVideoImage}`} alt={dataModalVideo.item.title} />
                    )}

                    {dataModalVideo.item.availability && (
                        <Typography className='caption' variant='caption'><Icon>date_range</Icon> {dataModalVideo.item.availability}</Typography>
                    )}

                    {dataModalVideo.item.description && (
                        <Typography className='description' variant='body2'>{dataModalVideo.item.description}</Typography>
                    )}
                    {dataModalVideo.item.susbcribe && (
                        <Button style={{marginTop: 12}} variant='outlined' color='primary' href={dataModalVideo.item.susbcribe} target='_blank'>Inscríbete aquí</Button>
                    )}
                </DialogContent>
            </Dialog>
        )
    }

    const WebinarCardItem = (props) => {
        const cartImageUrl = require(`../../../assets/images/infoTrenes/${props.item.image}`);

        return (
            <Grid item md={3}>
                <Card className='card-info'>
                    <CardActionArea>
                        {props.item.availability && <Typography className='caption' variant='caption'><Icon>date_range</Icon> {props.item.availability}</Typography>}
                        <CardMedia className={'card-media'} image={cartImageUrl} title={props.item.title} onClick={() => handleOpenModal(props)} />
                    </CardActionArea>

                    <CardContent>
                        {props.item.title && <Typography className='title' variant='h3'>{props.item.title}</Typography>}
                        {props.item.description && <Typography className='description multiLineEllipsis-x2' variant='body2'>{props.item.description}</Typography> }
                    </CardContent>

                    <CardActions className='card-footer'>
                        <Button size='small' endIcon={<Icon>arrow_forward</Icon>}  variant='text' color='primary' onClick={() => handleOpenModal(props)}>Conocé más</Button>
                        {props.item.susbcribe && <Button size='small' variant='outlined' color='primary' href={props.item.susbcribe} target='_blank'>Inscríbete aquí</Button>}
                    </CardActions>
                </Card>
            </Grid>
        )
    }

    const DocumentsCardItem = (props) => {

        const linkFile= require(`../../../assets/documents/${props.item.file}`)

        return (
            <Grid item md={4}>
                <Card className='card-info document'>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <Link href={linkFile} target='_blank' >
                                    <Image className='img-landing-pdf' img={`infoTrenes/${props.item.image}`} alt={props.item.title} />
                                </Link>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography className='title' variant='h3'><Link href={linkFile} target='_blank' >{props.item.title}</Link> </Typography>
                                <Typography className='description' variant='body2'>{props.item.description}</Typography>
                                <Button variant='text' size='small' style={{marginTop: 12}} href={linkFile} target='_blank' startIcon={<Icon>open_in_new</Icon>} color='primary'>Ver PDF</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        )
    }

    const ScrollableTabs = () => {
        const [value, setValue] = React.useState(0);

        const handleChange = (event, newValue) => {
          setValue(newValue);
        };

        return (
          <div className={'tabs-landing-info'}>
            <Tabs
                value={value}
                onChange={handleChange}
                variant='scrollable'
                scrollButtons='auto'
                aria-label='scrollable auto tabs example'
            >
                <Tab label={<> <Icon>smart_display</Icon><span>Webinars</span></>} {...a11yProps(0)} />
                <Tab label={<> <Icon>description</Icon><span>PRODUCTOS</span></>} {...a11yProps(1)} />
                <Tab label={<> <Icon>description</Icon><span>INSTRUCTIVOS</span></>} {...a11yProps(2)} />
                <Tab label={<> <Icon>description</Icon><span>Rutas con Encanto</span></>} {...a11yProps(2)} />
                <Tab label={<> <Icon>description</Icon><span>TRENES DE LUJO</span></>} {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Grid container spacing={3}>
                    {dataInfoPage.webinarItems.map( (item, i) => <WebinarCardItem key={i} item={item} id={i} /> )}
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Grid container spacing={3}>
                    {dataInfoPage.productsItems.map( (item, i) => <DocumentsCardItem key={i} item={item} id={i} /> )}
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Grid container spacing={3}>
                    {dataInfoPage.instructionsItems.map( (item, i) => <DocumentsCardItem key={i} item={item} id={i} /> )}
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Grid container spacing={3}>
                    {dataInfoPage.rutas.map( (item, i) => <DocumentsCardItem key={i} item={item} id={i} /> )}
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Grid container spacing={3}>
                    {dataInfoPage.luxurytrains.map( (item, i) => <DocumentsCardItem key={i} item={item} id={i} /> )}
                </Grid>
            </TabPanel>
          </div>
        );
    }

    return (
        <div className='info-page-trenes'>

            {openModal && <ModalVideo />}

            <div className='carousel-container'>
                <Carousel interval={6000} animation='slide' navButtonsAlwaysVisible='true'>
                    {dataInfoPage.items.map( (item, i) => <CarouselItem key={i} item={item} /> )}
                </Carousel>
            </div>

            <section className='landingPage' id='content'>
                <Container maxWidth='lg'>
                    <ScrollableTabs />
                </Container>
            </section>
        </div>
    )
}
