const Dots = ({ number }) => {
  return (
    <span className='dots-container'>
      {Array.from(Array(number).keys()).map(i => (
        <span key={i} className="dots" style={{ '--total': number, '--i': i }}>.</span>
      ))}
    </span>
  )
}

export default Dots;
