import React from "react";
import { Typography } from '@mui/material';
import Image from './Image';
import Dots from './Dots';

const Preloader = ({ image, text, addDots }) => {
  return (
    <div className="preloader">
      <div className="backdrop">
        <div className='content'>
          {image && (
            <Image img={image} alt='Preload' />
          )}
          {text && (
            <Typography className='text'>
              {text}
              {addDots && <Dots number={3} />}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default Preloader;
