import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Checkbox,
  Box,
  FormControlLabel
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CardCoverage from './CardCoverage';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

/**
 * @param {object} props
 * @param {object} props.rate
 * @param {number} props.quantity
 * @param {function} props.onSelect -
 * @param {boolean} props.selected
 *
 * // NUEVOS:
 * @param {function} [props.onCompare]
 * @param {Array} [props.compareList]
 */
const ResultsCard = ({
  rate,
  quantity,
  onSelect,
  selected,
  onCompare,
  compareList = [],
  isCompareView
}) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  // Determinamos el precio a mostrar
  const priceToShow = rate.prices[0]?.totalPromotion
    ? rate.prices[0].totalPromotion
    : rate.prices[0]?.totalValuePVP;

  return (
    <Card
      className={`insurance-card ${selected ? 'selected' : ''}`}
      key={rate.rateId}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: selected ? '4px solid #2f4fff' : '1px solid #ccc',
        borderRadius: '8px'
      }}
    >
      {/* HEADER (fondo azul claro) */}
      <CardContent className="insurance-card__header" >
        <Box  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
          {/* Ícono + Nombre */}
          <Box className="insurance-card__name_icon" >
            <Box className="insurance-card__badge" sx={{ mr: 1}}>
              <img src={rate.mediaImage} alt="Rate Icon" style={{ objectFit: 'cover' }} />
            </Box>
            <Typography className="insurance-card__name" variant="h6" >
              {rate.name}
            </Typography>
          </Box>

          {/* Checkbox "Comparar" */}
          {!isCompareView && (
            <FormControlLabel
              className="compare-checkbox"
              control={
                <Checkbox
                  size="small"
                  style={{ padding: 0, marginRight: '1px' }}
                  checked={compareList.some(item => item.rateId === rate.rateId)}
                  onChange={(ev) => {
                    if (onCompare) {
                      onCompare(rate, ev.target.checked);
                    }
                  }}
                />
              }
              label={t('results.flights.compare')}
              style={{
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                gap: '1px',
              }}
            />
          )}

        </Box>
      </CardContent>

      {/* DETALLES */}
      <CardContent className="insurance-card__details" >
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ mb: 0.5 }}>
            {t('results.assists.comprehensiveCoverage')}{' '}
            {rate.coverage[0]?.value || 'N/A'}
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            {t('results.assists.preexistenceAssistance')}{' '}
            {rate.coverage.find(cov => cov.name.includes('PRE-EXISTENCIA'))?.value || 'N/A'}
          </Typography>

          <Button
            variant="text"
            onClick={handleOpen}
            sx={{
              p: 0,
              minWidth: 'auto',
              textDecoration: 'underline',
              display: 'block',
              mx: 'auto',
              fontWeight: 'bold',
              mb: 1
            }}
          >
            {t('results.assists.seeDetail')} +
          </Button>

          {/* Badge de promoción o precio regular */}
          {rate.promotion ? (
            <Box className="insurance-card__rate-promotion" >
              <LocalOfferOutlinedIcon
                sx={{
                  mr: 1,
                  fontSize: '1.1rem',
                  color: 'inherit'
                }}
              />
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 'bold', m: 0, p: 0 }}
              >
                {rate.promotion}
              </Typography>
            </Box>
          ) : (
            <Typography
              variant="subtitle2"
              sx={{
                mt: 1,
                fontWeight: 'bold',
                textAlign: 'center',
                color: '#00529B',
                backgroundColor: '#EAF2FF',
                display: 'inline-block',
                px: 2,
                py: 0.5,
                borderRadius: '16px',
              }}
            >
              {t('results.assists.regularPrice')}
            </Typography>
          )}
        </Box>

        {/* BLOQUE DE PRECIOS */}
        <Box className="insurance-card__price" sx={{ mb: 0 }}>
          <Typography className="insurance-card__price-title" variant="subtitle2" >
            {t('results.assists.totalCost')}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 1 }}>
            {/* Precio anterior (tachado) si existe discount */}
              {rate.prices[0]?.totalDiscount && (
                <Typography className="insurance-card__price-old" variant="body2"  >
                  {t('results.assists.beforeUSD')}{' '}
                  {((rate.prices[0]?.totalValuePVP ?? 0)).toFixed(0)}
                </Typography>
              )}

            {/* Precio principal */}
            <Typography  variant="h5" className="insurance-card__price-amount" >
              {t('results.assists.exchangeRate')}{' '}
              {(priceToShow ?? 0).toFixed(0)}
            </Typography>
          </Box>

          {/* Precio por persona */}
          <Typography className="insurance-card__price-per-person" variant="body2" sx={{ mb: 0.5 }} >
            {t('results.assists.exchangeRate')}{' '}
            {
              // Verificamos si existe totalPromotion
              rate.prices?.[0]?.totalPromotion
                ? (
                    // Si existe, revisamos si quantity > 1
                    quantity > 1
                      ? (rate.prices[0].totalPromotion / quantity).toFixed(0)
                      // Si quantity === 1, usamos directamente totalPromotion
                      : rate.prices[0].totalPromotion.toFixed(0)
                  )
                : rate.prices?.[0]?.individualValuePVP?.toFixed(0)
            }{' '}

            {t('results.assists.perPerson')}
          </Typography>

          {/* Cantidad de pasajeros */}
          <Typography className="insurance-card__people-count" variant="body2" >
            {quantity} {quantity > 1 ? t('results.assists.persons') : t('results.assists.person')}
          </Typography>
        </Box>
      </CardContent>

      {/* BOTÓN "SELECT" */}
      <CardActions className="insurance-card__actions" sx={{  p: 0.5, }} >
        <Button
          className={`insurance-card__button ${selected ? 'selected' : ''}`}
          variant="contained"
          color='secondary'
          startIcon={selected ? <CheckCircleOutlineIcon /> : null}
          onClick={() => onSelect(rate)}
        >
          {t('results.assists.select')}
        </Button>
      </CardActions>

      {/* MODAL DE COBERTURA */}
      <CardCoverage open={openModal} onClose={handleClose} rate={rate} />
    </Card>
  );
};

export default ResultsCard;
