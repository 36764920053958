import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { getBanners } from '../store/services/Login';
import { useClientData } from './ClientContext';

const BannersContext = createContext();

export const BannersProvider = ({ value, allowedIframes, children }) => {
  const navigate = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();
  const { setClientData } = useClientData();
  const [bannersData, setBannersData] = useState(null);

  useEffect(() => {
    if (bannersData === null) {
      setBannersData(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    const banners = JSON.parse(localStorage.getItem('banners') || '{}');
    let refreshBanners = false;

    if (banners && Object.keys(banners).length > 0) {
      setBannersData(banners);

      if (dayjs().diff(dayjs.unix(banners.time), 'hour') >= 1) {
        refreshBanners = true;
      }
    } else {
      refreshBanners = true;
    }

    if (refreshBanners && token) {
      getBanners(token).then(res => {
        if (res?.data) {
          const newBanners = {
            ...res,
            time: dayjs().unix()
          };
          localStorage.setItem('banners', JSON.stringify(newBanners));
          setBannersData(newBanners);
        }
      }).catch(() => {
        const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
        if (iframePath) {
          localStorage.clear();
          setClientData(null);
          navigate(iframePath);
        } else {
          navigate('/login', { state: { expiredSession: true } });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (bannersData) {
      getFavicon();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannersData]);

  useEffect(() => {
    updateSEO();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const getFavicon = () => {
    const favicon = bannersData?.data?.modules?.['otros']?.flows?.login?.banners?.Favicon?.slice(-1)?.[0]?.image_url;

    if (favicon) {
      const link = document.querySelector("link[rel~='icon']") || document.createElement('link');
      link.rel = 'icon';
      link.href = favicon;
      document.head.appendChild(link);
    }
  };

  const updateSEO = () => {
    const client = JSON.parse(localStorage.getItem('clientData') || '{}');
    const isIframe = allowedIframes.includes(window.location.host);
    let index = isIframe ? 2 : 1;
    if (pathname.split('/')[index] === 'checkout') {
      index += 1;
    }
    const path = pathname.split('/')[index];
    const module = client?.modules?.find(elem => elem.module === path || elem.path === `/${path}`);
    const modulePath = module?.path?.split('/')[1];
    const seoData = client?.seo?.[modulePath];

    if (seoData && typeof seoData === 'object') {
      const { title, metaDescription } = seoData;
      if (title) {
        document.title = title;
      }
      if (metaDescription) {
        const metaTag = document.querySelector("meta[name='description']");
        if (metaTag) {
          metaTag.setAttribute("content", metaDescription);
        } else {
          const newMetaTag = document.createElement("meta");
          newMetaTag.setAttribute("name", "description");
          newMetaTag.setAttribute("content", metaDescription);
          document.head.appendChild(newMetaTag);
        }
      }
    }
  };

  return (
    <BannersContext.Provider value={{ bannersData, setBannersData }}>
      {children}
    </BannersContext.Provider>
  );
};

// Hook personalizado para acceder al contexto fácilmente
export const useBannersData = () => {
  const context = useContext(BannersContext);

  if (context === undefined) {
    throw new Error('useBannersData debe ser usado dentro de un BannersProvider');
  }

  return context;
};
