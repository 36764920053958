import React from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Grid,
  Box
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const CardCoverage = ({ open, onClose, rate }) => {
  const { t } = useTranslation();
  if (!rate) return null;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: '16px',
          overflow: 'visible',
          position: 'relative'
        }
      }}
    >
      {/* Botón para cerrar en la esquina superior derecha, superpuesto */}
      <IconButton
        onClick={onClose}
        sx={{
          position:'absolute',
          top:'-12px',
          right:'-12px',
          backgroundColor:'#ffffff',
          width:'32px',
          height:'32px',
          borderRadius:'50%',
          boxShadow:'0 2px 4px rgba(0,0,0,0.2)',
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          '&:hover': { backgroundColor:'#f0f0f0' }
        }}
      >
        <CloseIcon sx={{ fontSize:'20px', color:'#000000' }}/>
      </IconButton>

      {/* Encabezado principal */}
      <Box sx={{ padding:'16px', borderBottom:'1px solid #e0e0e0' }}>
        <Typography variant="h6" sx={{ fontWeight:'600', color:'#2f4fff' }}>
        {t('results.assists.coverageSummary')}
        </Typography>
      </Box>

      <DialogContent
        sx={{
          width: '100%',
          background: '#FFFFFF',
          boxShadow: '0px 3px 5px -2px rgba(0, 0, 0, 0.2), 0px 2px 3px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
          borderRadius: '16px',
          overflowY: 'auto',
          padding: '24px',
          maxHeight: '500px',

          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
            width: 0,
            background: 'transparent', },
        }}
      >
        <Grid container spacing={4} alignItems="flex-start">
          {/* Columna izquierda: ícono circular y nombre del producto */}
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              gap: '5px',
            }}
          >
            <Box
              sx={{
                width: 70,
                height: 70,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontSize: 14,
                overflow: 'hidden',
                flexShrink: 0,
              }}
            >
              <img
                src={rate.mediaImage}
                alt="Rate Icon"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </Box>

            <Typography
              variant="h7"
              sx={{mt:3,
                fontWeight: 600,
                textAlign: 'left',
                color: '#000',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
              }}
            >
              {rate.name}
            </Typography>
          </Grid>
          {/* Columna derecha: Tabla de coberturas */}
          <Grid item xs={12} sm={8} md={9}>
            <Grid container sx={{ borderRadius:'8px', overflow:'hidden' }}>
              {rate.coverage.map((item, index) => {
                const coverageName = item.name.toLowerCase();
                const capitalizedName = coverageName.charAt(0).toUpperCase() + coverageName.slice(1);

                return (
                  <Grid
                    container
                    key={index}
                    columns={24}
                    sx={{
                      borderBottom: index !== rate.coverage.length - 1
                        ? '1px solid #e0e0e0'
                        : 'none',
                      alignItems: 'center'
                    }}
                  >
                    {/* Columna de la cobertura con fondo azul claro */}
                    <Grid
                      item
                      xs={16}
                      sx={{
                        py:'12px',
                        px:'12px',
                        borderRight:'1px solid #e0e0e0',
                        backgroundColor:'#e6f0ff'
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          // fontWeight: item.is_highlighted ? 'bold' : 'normal',
                          color:'#2f4fff',
                          whiteSpace:'normal',
                          overflow:'visible'
                        }}
                      >
                        {capitalizedName}
                      </Typography>
                    </Grid>

                    {/* Columna del valor con fondo blanco */}
                    <Grid
                      item
                      xs={8}
                      sx={{
                        py:'12px',
                        px:'12px',
                        textAlign: 'center',
                        backgroundColor:'#ffffff',
                      }}
                    >
                      <Typography variant="body2" sx={{ color:'#000000' }}>
                        {item.value}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CardCoverage;
