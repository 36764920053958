import React, { useState } from 'react';
import { Button, Rating, Grid, Typography, Hidden, Tooltip, Box, FormControlLabel, Checkbox, Collapse, TextField, IconButton, Autocomplete } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import pinLocator from '../../../../assets/images/pinLocator.png';
import { RoomService, Search } from '@mui/icons-material';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';

function MapView({ hotels, selectedHotel, isFavoritePage, pointInterest, changeView, ...props }) {
    useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDmcY_yesxBlDAAnSaP0lWgKzXT3iQLZ0E',
        language: 'es',
        region: 'ES'
    })
    const { t } = useTranslation();
    const [map, setMap] = useState();
    const [filteredHotels, setFilteredHotels] = useState(hotels);
    const [activeFilters, setActiveFilters] = useState({
        stars: [],
        recommended: false,
        hotelName: '',
        price: {}
    });
    const [showStarsCollapse, setShowStarsCollapse] = useState(false);

    const getBounds = (hotels) => {
        const bounds = new window.google.maps.LatLngBounds();
        hotels.forEach((marker) => {
          bounds.extend(new window.google.maps.LatLng(marker.latitude, marker.longitude));
        });
        return bounds;
    }

    const onReadyMap = (mapAux) => {
        setMap(mapAux);
        mapAux.fitBounds(getBounds(hotels));
    }

    const handleMarkerClick = (hotel) => {
        props.onHotelSelect(hotel);
    };

    const applyFilters = (filters, applyPrice = false) => {
        let filteredHotelsAux = hotels;
        if (filters.stars.length > 0) {
            filteredHotelsAux = filteredHotelsAux.filter(elem => {
                const stars = parseInt(elem.stars || 0);
                return filters.stars.includes(stars);
            })
        }

        if (filters.recommended) {
            filteredHotelsAux = filteredHotelsAux.filter(elem => elem.recommended);
        }

        if (filters.hotelName && filters.hotelName.length >= 2) {
            filteredHotelsAux = filteredHotelsAux.filter(elem => (
                elem.name.toLowerCase().includes(filters.hotelName.toLowerCase())
            ));
        }

        if (applyPrice) {
            const min = filters.price?.min || 0;
            const max = filters.price?.max || Infinity;
            filteredHotelsAux = filteredHotelsAux.filter(elem => (
                elem.totalPrice > min && elem.totalPrice < max
            ));
        }

        setFilteredHotels(filteredHotelsAux);

        if (filteredHotelsAux.length > 0) {
            map?.fitBounds(getBounds(filteredHotelsAux));
        }

        if (selectedHotel && !filteredHotelsAux.find(elem => elem.id === selectedHotel.id)) {
            props.onHotelSelect(null);
        }
    }

    const handleChangeFilter = (filter, value, applyPrice = false) => {
        const activeFiltersAux = {
            ...activeFilters,
            [filter]: value
        };

        setActiveFilters(activeFiltersAux);

        if (filter !== 'price') {
           applyFilters(activeFiltersAux, applyPrice);
        }
    }

    const handleChangeStars = (value, checked = true) => {
        let stars = [];

        if (value !== 'all') {
            if (checked) {
                stars = [...activeFilters.stars, value];
            } else {
                stars = activeFilters.stars.filter(elem => elem !== value);
            }
        }

        handleChangeFilter('stars', stars);
    }

    const handleChangePrice = (field, value) => {
        const price = {
            ...activeFilters.price,
            [field]: value
        };

        handleChangeFilter('price', price);
    }

    const filterOptions = (options, { inputValue }) => {
        if (!inputValue || inputValue.length < 2) {
            return [];
        }

        let filteredOptions = options.filter(option =>
            option.name.toLowerCase().includes(inputValue.toLowerCase())
        );

        return filteredOptions.slice(0, 6);
    };

    return (
        <>
            <Grid container className='map-filters' columnSpacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography className='title'>Categoría</Typography>
                    <Box className='stars-filter'>
                        <FormControlLabel
                            label="Todas las estrellas"
                            control={
                                <>
                                    <Checkbox
                                        checked={!activeFilters.stars || activeFilters.stars?.length === 0}
                                        onChange={() => handleChangeStars('all')}
                                    />
                                    <Collapse className='collapse' in={showStarsCollapse}>
                                        {Array.from(Array(5).keys())?.map(elem => (
                                            <Grid key={elem}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={activeFilters.stars?.includes(elem + 1) ? true : false}
                                                            onChange={(e, checked) => handleChangeStars(elem + 1, checked)}
                                                        />
                                                    }
                                                    label={<Rating value={elem + 1} readOnly size='small' />}
                                                />
                                            </Grid>
                                        ))}
                                    </Collapse>
                                </>
                            }
                            onMouseEnter={() => setShowStarsCollapse(true)}
                            onMouseLeave={() => setShowStarsCollapse(false)}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography className='title'>Recomendados</Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={activeFilters.recommended}
                                onChange={(e, checked) => handleChangeFilter('recommended', checked)}
                            />
                        }
                        label="Hoteles recomendados"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography className='title'>Hotel</Typography>
                    <Autocomplete
                        variant="outlined"
                        fullWidth
                        freeSolo
                        size='small'
                        options={hotels || []}
                        getOptionLabel={option => option?.name || ''}
                        filterOptions={filterOptions}
                        onInputChange={(e, value) => {
                            handleChangeFilter('hotelName', value);
                        }}
                        inputValue={activeFilters.hotelName || ''}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                placeholder="Buscar por nombre"
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} className='price-filter'>
                    <Typography className='title'>Precio</Typography>
                    <Grid className='row'>
                        <TextField
                            fullWidth
                            size='small'
                            placeholder="Mínimo..."
                            className='left-input'
                            type='number'
                            value={activeFilters.price.min || ''}
                            onChange={(e) => handleChangePrice('min', e.target.value)}
                        />
                        <TextField
                            fullWidth
                            size='small'
                            placeholder="Máximo..."
                            className='right-input'
                            type='number'
                            value={activeFilters.price.max || ''}
                            onChange={(e) => handleChangePrice('max', e.target.value)}
                        />
                        <IconButton className='search-button' onClick={() => applyFilters(activeFilters, true)}>
                            <Search fontSize='small' />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>

            <GoogleMap
                mapContainerClassName='map'
                onLoad={onReadyMap}
            >
                {pointInterest && (
                    <Marker
                        position={{
                            lat: Number(pointInterest.location.lat),
                            lng: Number(pointInterest.location.lng),
                        }}
                        icon={{
                            url: pinLocator,
                            scaledSize: new window.google.maps.Size(58, 58)
                        }}
                        zIndex={11}
                        clickable={false}
                    />
                )}
                {filteredHotels.map((hotel) => (
                    <Marker
                        key={hotel.id}
                        position={{
                            lat: Number(hotel.latitude),
                            lng: Number(hotel.longitude),
                        }}
                        onClick={() => handleMarkerClick(hotel)}
                        icon={{
                            url: selectedHotel === hotel
                                ? require(`../../../../assets/images/sites/${props.client.client.name}/hotelOn.png`)
                                : require(`../../../../assets/images/sites/${props.client.client.name}/hotelOff.png`),
                            scaledSize: selectedHotel === hotel
                                ? new window.google.maps.Size(58, 58)
                                : new window.google.maps.Size(48, 48)
                        }}
                        zIndex={selectedHotel === hotel ? 10 : 1}
                    />
                ))}

                <Hidden smDown>
                    {selectedHotel && (
                        <Grid className='hotel-card'>
                            {selectedHotel.recommended &&
                                <Grid className='row recommended-container'>
                                    <RoomService />
                                    {t('results.hotels.hotelRecommended')}
                                </Grid>
                            }
                            <Grid className='hotel-info'>
                                <Grid className='row'>
                                    <img
                                        src={selectedHotel.image}
                                        alt={selectedHotel.name}
                                        style={{
                                            height: '100px',
                                            width: '100px',
                                            objectFit: 'cover',
                                            marginRight: '10px',
                                            borderRadius: '12px',
                                        }}
                                    />
                                    <Grid style={{ maxWidth: 'calc(100% - 110px)' }}>
                                        <Hidden mdDown>
                                            <Typography className='property'>
                                                {t(`filters.hotels.properties.${selectedHotel?.property?.toLowerCase() || 'other'}`)}
                                            </Typography>
                                        </Hidden>
                                        <Tooltip title={selectedHotel.name}>
                                            <Typography className='name'>{selectedHotel.name}</Typography>
                                        </Tooltip>
                                        <Typography className='row location'>
                                            <LocationOnIcon fontSize='small' /><span>{`${selectedHotel.address}`}</span>
                                        </Typography>
                                        <Rating size="small" value={parseInt(selectedHotel.stars)} readOnly />
                                    </Grid>
                                </Grid>

                                {!isFavoritePage && (
                                    <>
                                        <Grid className='price-container'>
                                            <Typography>{t('results.hotels.totalPriceFrom')}</Typography>
                                            <Typography className='price'>
                                                {`${selectedHotel.currency} ${parseInt(selectedHotel.totalPrice)}`}
                                            </Typography>
                                        </Grid>
                                        <Button variant="contained" color="secondary" onClick={() => changeView('room')}>
                                            {t('results.hotels.moreRates')}
                                        </Button>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Hidden>
            </GoogleMap>
        </>
    );
}

export default MapView;
