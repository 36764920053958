const initialState = {
  isLoading: false,
  resultSearch: [],
  error: null,
  apiCheckout: {},
  compareResults: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SEARCH_ASSISTS_REQUEST':
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case 'SEARCH_ASSISTS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        resultSearch: action.payload.results || [],
        error: null,
      };
    case 'SEARCH_ASSISTS_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case 'START_CHECKOUT':
      return {
        ...state,
        apiCheckout: {},
        errorApiCheckout: false,
      };
    case 'GET_START_CHECKOUT_SUCCESS':
      return {
        ...state,
        apiCheckout: action.payload,
        errorApiCheckout: false,
      };
    case 'GET_START_CHECKOUT_ERROR':
      let parsedPayload;
      try {
        parsedPayload = JSON.parse(action.payload);
      } catch (error) {
        parsedPayload = { error: 'Respuesta no válida de la API' };
      }
      return {
        ...state,
        apiCheckout: parsedPayload,
        errorApiCheckout: true,
      };
    case 'GET_COMPARE_PRODUCTS_REQUEST':
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case 'GET_COMPARE_PRODUCTS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        compareResults: action.payload,
        error: null,
      };
    case 'GET_COMPARE_PRODUCTS_ERROR':
      return {
        ...state,
        isLoading: false,
        compareResults: null,
        error: action.payload,
      };
      case 'CLEAR_COMPARE_COVERAGE':
      return {
        ...state,
        compareResults: {},
      };

    default:
      return state;
  }
};

export default reducer;
