import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, IconButton, Button, Menu, MenuItem, Grid, Typography, Box, Icon, List, ListItem, Tooltip, Collapse, ClickAwayListener,  Hidden, Drawer, Popper, Fade, Badge, TextField, CircularProgress,  } from '@mui/material';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCurrenciesAction, getReservationAction, getReservationsAction, resetReservationAction } from '../../store/actions';
import dayjs from 'dayjs';
import { AccountCircle, AddCircle, Cancel, Category, KeyboardArrowDown, Search, Close  } from '@mui/icons-material';
import IconCustom from '../common/IconCustom';
import { useLayoutContext } from '../../context/LayoutContext';
import { useBannersData } from '../../context/BannersContext';
import Image from '../common/Image';
import { useClientData } from '../../context/ClientContext';
import { useTranslation } from 'react-i18next';
import IconComponent from '../common/IconComponent';

const MenuList = ({ user, client, showMenu, showButtonModules, isLoginPage, handleClickLogIn, handleClickMenu }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentSubmenu, setCurrentSubmenu] = useState([]);

    const handleOpenMenu = (event, submenu) => {
        if (submenu && submenu.length > 0) {
            setAnchorEl(event.currentTarget);
            setCurrentSubmenu(submenu);
        }
    };

    const handleCloseMenu = () => {
      setAnchorEl(null);
      setCurrentSubmenu([]);
    };

    return (
        <>
            {showButtonModules && (
                <IconButton onClick={handleClickMenu} color="inherit">
                    <Icon>menu</Icon>
                </IconButton>
            )}

            <Box sx={{ flexGrow: 1 }} />

            {showMenu && (
                <List className='menu-list'>
                    {client.header?.menu?.map((menuItem, index) => {
                        if (user || (!user && !menuItem.isLogged)) {
                            return (
                                <ListItem key={index} disablePadding>
                                    <Button
                                        onClick={(event) => handleOpenMenu(event, menuItem.submenu)}
                                        sx={{ whiteSpace: 'nowrap' }}
                                        startIcon={menuItem.buttonStartIcon ? <Icon>{menuItem.buttonStartIcon}</Icon> : null}
                                        endIcon={menuItem.buttonEndIcon ? <Icon>{menuItem.buttonEndIcon}</Icon> : null}
                                        color={menuItem.buttonColor ? menuItem.buttonColor : "primary"}
                                        variant={menuItem.buttonVariant ? menuItem.buttonVariant : "text"}
                                    >
                                        {menuItem.clave}
                                        {menuItem.submenu && menuItem.submenu.length > 0 && <KeyboardArrowDown />}
                                    </Button>
                                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu} className='popover-menu'>
                                        {currentSubmenu.map((submenuItem, subIndex) => (
                                            <MenuItem
                                                key={subIndex}
                                                component={Link}
                                                to={submenuItem.link || null}
                                                target={submenuItem.newTab ? '_blank' : ''}
                                                onClose={handleCloseMenu}
                                            >
                                                {submenuItem.icon && <IconComponent iconName={submenuItem.icon} />} {submenuItem.clave}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </ListItem>
                            )
                        }

                        return null
                    })}
                    {!user && (
                        <ListItem disablePadding>
                            <Button
                                onClick={handleClickLogIn}
                                startIcon={<Icon>account_circle</Icon>}
                                color="default"
                                variant="outlined"
                            >
                                {isLoginPage ? t('common.signIn') : t('common.logIn')}
                            </Button>
                        </ListItem>
                    )}
                </List>
            )}
        </>
    );
};

const MobileMenuList = ({ user, client, isLoginPage, handleClickLogIn }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentSubmenu, setCurrentSubmenu] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleOpenMenu = (event, submenu) => {
        if (submenu && submenu.length > 0) {
            setAnchorEl(event.currentTarget);
            setCurrentSubmenu(submenu);
        }
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setCurrentSubmenu([]);
    };

    const toggleMainMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <>
            <Box sx={{ flexGrow: 1 }} />

            <IconButton onClick={toggleMainMenu}>
                {menuOpen ? <Icon>close</Icon> :<Icon>menu</Icon>}
            </IconButton>

            <Drawer
                variant="temporary"
                open={menuOpen}
                onClose={toggleMainMenu}
            >
                <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.8)', zIndex: 10 }}>
                        <List className='mobile-menu-list'>
                            {client.header?.menu?.map((menuItem, index) => (
                                <ListItem key={index}>
                                    <Button
                                        onClick={(e) => handleOpenMenu(e, menuItem.submenu)}
                                        sx={{ fontSize: '1.5rem', marginTop: '1rem', color: '#fff' }}
                                    >
                                        {menuItem.clave}
                                        {menuItem.submenu && menuItem.submenu.length > 0 && <KeyboardArrowDown />}
                                    </Button>
                                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                                        {currentSubmenu.map((submenuItem, subIndex) => (
                                            <MenuItem key={subIndex} href={submenuItem.link} onClick={handleCloseMenu}>
                                                {submenuItem.clave}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </ListItem>
                            ))}
                            {!user && (
                                <ListItem>
                                    <Button
                                        onClick={handleClickLogIn}
                                        startIcon={<Icon>account_circle</Icon>}
                                        sx={{ fontSize: '1.5rem', marginTop: '1rem', color: '#fff' }}
                                    >
                                        {isLoginPage ? t('common.signIn') : t('common.logIn')}
                                    </Button>
                                </ListItem>
                            )}
                        </List>
                    </div>
            </Drawer>

        </>
    );
};

const DekstopAppBarTop = (props) => {
    const { t } = useTranslation();
    const params = useParams();
    const location = useLocation();
    const currentModule = props.modules?.find(elem => location.pathname.includes(elem.path));

    const [isVisible, setIsVisible] = useState(false);

    const getInfoTrenes = () => {
        if (currentModule && props.client?.header?.showInfoTrenes?.[currentModule.module]) {
            return <Button
                href='/trenes/info'
                target='_blank'
                startIcon={<Icon>train</Icon>}
                color="secondary"
                variant='contained'
                sx={{ marginLeft: '16px' }}
            >
                Info Trenes
            </Button>
        }
    }

    const getContinueReservation = (reservation, isSelected) => {

        const showButton = reservation.status === "OPEN" || reservation.status === "CANCELLED";
        if (showButton) {
            return  isSelected ? (
                <Button
                    className='cancel-button'
                    variant='outlined'
                    size='small'
                    color='error'
                    onClick={() => props.handleSelectReservation()}
                >
                    {t('common.inProgress')} <Cancel fontSize='small' />
                </Button>
            ) : (
                <Button
                    className='continue-button'
                    variant='contained'
                    size='small'
                    onClick={() => props.handleSelectReservation(reservation)}
                >
                    <AddCircle fontSize='small' /> {t('common.continueBooking')}
                </Button>
            )

        }
    }

    const getReservations = () => {
        return props.reservations.map(reservation => {
            const isSelected = reservation.number === props.selectedReservation?.number;
            const products = {};
            reservation?.items?.forEach(item => {
                products[item.product.ref_table_detail] = products[item.product.ref_table_detail] ? products[item.product.ref_table_detail] + 1 : 1;
            });
            reservation.products = products;
            const status = reservation.status ;

            return <Grid className={`reservation ${isSelected ? 'selected' : ''}`} key={reservation.id}>
                <div className='row reservation-title' style={{ justifyContent: 'space-between' }}>
                    <Typography className='reservation-number'>{t('common.reservation')} {reservation.number}</Typography>
                    <Typography className={`status ${status.toLowerCase()}`}>{t(`common.${status.toLowerCase()}`)}</Typography>
                </div>
                <div className='row' style={{ justifyContent: 'space-between' }}>
                    <Typography className='title'>{t('common.products')}:</Typography>
                    <div>
                        {Object.entries(products).map(([key, value], i) => (
                            <Badge
                                badgeContent={value > 1 ? value : 0}
                                overlap='circular'
                                key={i}
                            >
                                {props.getIcon(key)}
                            </Badge>
                        ))}
                    </div>
                </div>
                <div className='row'>
                    <Typography className='title'>{t('common.passenger')}:</Typography>
                    <Typography>{reservation.passenger}</Typography>
                </div>
                <div className='row' style={{ justifyContent: 'space-between' }}>
                    <div className='row'>
                        <Typography className='title'>{t('common.date')}:</Typography>
                        <Typography>{reservation.date_in ? dayjs(reservation.date_in).format('DD-MM-YYYY') : ''}</Typography>
                    </div>
                    {getContinueReservation(reservation, isSelected)}
                </div>
            </Grid>
        });
    }

    const getCurrency = () => {
        const currency = props.currentProduct?.config?.CURRENCY_TO || props.currentProduct?.config?.currency_to;
        return currency || props.client?.client?.defaultCurrency || 'USD';
    }

    const formatPrice = (price) => {
        return price ? price.toFixed(2) : 0;
    }

    const getFavoritesIcon = () => {
        const hotelsModuleExists  = (props?.modules && Array.isArray(props?.modules) && props?.modules?.find(elem => elem.module === 'hotels'));
        const showFavorite = !props.client?.header.showFavorites || props.client?.header.showFavorites?.[currentModule?.module];
        if (hotelsModuleExists && showFavorite) {
            const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
            return <Link to={iframePath + '/hoteles/favoritos'} color='inherit'>
                <Button
                    startIcon={<Icon className='icon-favorite'>favorite</Icon>}
                    sx={{ marginLeft: '8px' }}
                >
                    {t('common.favorites')}
                </Button>
            </Link>
        }
    }

    const getCurrencies = () => {
        const currencies = props.getCurrencies();
        if (currencies) {
            return <>
                <strong>{t('common.exchangeTypeTtle')}</strong> {currencies}

                {!props.client?.header?.exchangeRates?.hideCurrenciesInfo && (
                    <Tooltip title={t('common.exchangeTypeDescription')}>
                        <IconButton color="inherit" size="small">
                            <Icon>info</Icon>
                        </IconButton>
                    </Tooltip>
                )}
            </>
        }
    }

    const getMissingMessage = () => {
        if (props.loadingReservation) {
            return <CircularProgress style={{ margin: '0 auto 15px' }} />;
        } else if (
            (!props.reservation || (Array.isArray(props.reservation) && props.reservation.length === 0)) &&
            (!props.reservations || (Array.isArray(props.reservations) && props.reservations.length === 0))
        ) {
            return (
                <span style={{ margin: '0 auto 15px' }}>Reserva no encontrada</span>
            );
        }

        return null;
    };

    const getPopperReservations = () => {
        return (
            <Popper open={props.openReservations} anchorEl={props.anchorEl} placement='bottom-end' transition style={{ zIndex: 20 }}>
            {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={props.handleCloseReservations}>
                        <Fade {...TransitionProps} timeout={500}>
                        {props?.client?.client?.nameCopy === "GoTravelRes Uruguay"
                            ? getPopperContentUy()
                            : getPopperContent()}
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        )
    }

    const getPopperContent = () => {
        return (
            <Grid className='reservation-popper'>
                <Grid className='row mb-1'>
                    <TextField
                        fullWidth
                        size='small'
                        placeholder={`${t('common.search')}...`}
                        value={props.searchInput}
                        onChange={(event) => {
                        props.handleChangeSearchInput(event.target.value);
                        }}
                    />
                    <IconButton className='ml-05' onClick={props.handleSearchReservations}>
                    <Search/>
                    </IconButton>
                </Grid>
                {getMissingMessage()}
                {getReservations()}
                <Button
                    variant='outlined'
                    className='more-button'
                    fullWidth
                    onClick={props.handleClickMoreReservations}
                >
                    {t('common.seeMoreBookings')}
                </Button>
            </Grid>
        )
    }
    const getPopperContentUy = () => {
        
        return (
            <Grid className='reservation-popper'>
                {/* <Button
                    variant='outlined'
                    className='more-button'
                    fullWidth
                    component={Link}
                    to="https://adminpro.gotravelres.com.ar/admin/login"
                    sx={{mb: 1}}
                >
                    Viejo Sistema
                </Button>  */}
                <Button
                    variant="outlined"
                    className="more-button"
                    fullWidth
                    component="a"
                    href="https://adminpro.gotravelres.com.ar/admin/login"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{mb: 1}}
                >
                    Viejo Sistema
                </Button>

                {!isVisible && (
                        <Button
                        variant='outlined'
                        className='more-button'
                        fullWidth
                        onClick={() => setIsVisible(!isVisible)}
                        sx={{mb: 1}}
                    >
                        Nuevo Sistema
                    </Button>
                )}

                {isVisible && ( 
                <Box sx={{position: 'relative', mt: 1, pt: 2, borderTop: '1px solid #e5e5e5'}}>
                    <Typography className='title' sx={{mb:2, fontSize: '12px'}}> Nuevo Sistema </Typography>
                    <IconButton
                        aria-label="Cerrar"
                        onClick={() => setIsVisible(false)}
                        sx={{ position: "absolute", top: 4, right: 0 }}
                    >
                        <Close />
                    </IconButton>
                    
                    <Grid className='row mb-1'>
                        <TextField
                            fullWidth
                            size='small'
                            placeholder={`${t('common.search')}...`}
                            value={props.searchInput}
                            onChange={(event) => {
                            props.handleChangeSearchInput(event.target.value);
                            }}
                        />
                        <IconButton className='ml-05' onClick={props.handleSearchReservations}>
                        <Search/>
                        </IconButton>
                    </Grid>
                    {getMissingMessage()}
                    {getReservations()}
                    <Button
                        variant='outlined'
                        className='more-button'
                        fullWidth
                        onClick={props.handleClickMoreReservations}
                        sx={{mb: 1}}
                    >
                        Ver más reservas
                    </Button>
                    </Box>
                )}

                
                
                
                
            </Grid>
        )
    }

    return (
        <AppBar position='static' className='site-header-logged'>
            <Toolbar>
                <Typography variant='body1' color='inherit' component='span' sx={{ flexGrow: 1 }}>
                    {getCurrencies()}
                </Typography>

                <Typography className='row' style={{ fontSize: 13 }}>
                    <AccountCircle />&nbsp;
                    {t('common.hello')},&nbsp;<strong>{props.user.name} {props.user.surname}</strong>
                </Typography>

                {Boolean(props.payment?.config?.credit?.remaining_balance && props.payment?.config?.credit?.remaining_balance > 0) && (
                    <Typography className='row' style={{ fontSize: 13 }}>
                        &nbsp;|&nbsp;{t('common.availableCredit')}: {getCurrency()} {formatPrice(props.payment?.config?.credit?.remaining_balance)}
                    </Typography>
                )}

                {getFavoritesIcon()}

                {getInfoTrenes()}

                <Button
                    startIcon={<Icon>receipt</Icon>}
                    color="default"
                    variant='contained'
                    sx={{ marginLeft: '16px' }}
                    onClick={props.handleOpenReservations}
                >
                    {t('common.myBookings')} {props.selectedReservation ? `(${props.selectedReservation.number})` : ''}
                </Button>

                {getPopperReservations()} 

                <Button
                    startIcon={<Icon>logout</Icon>}
                    color="default"
                    variant="outlined"
                    sx={{ marginLeft: '16px' }}
                    onClick={props.handleClickLogIn}
                >
                    {t('common.logOut')}
                </Button>
            </Toolbar>
        </AppBar>
    )
}

const MobileAppBarTop = (props) => {
    const { t } = useTranslation();
    const params = useParams();
    const location = useLocation();
    const [open, setOpen] = useState(false);

    const handleToggle = () => setOpen(!open);

    const handleClose = () =>  setOpen(false);

    const getContinueReservation = (reservation, isSelected) => {

        const showButton = reservation.status === "OPEN" || reservation.status === "CANCELLED";
        if (showButton) {
            return  isSelected ? (
                <Button
                    className='cancel-button'
                    variant='outlined'
                    size='small'
                    color='error'
                    onClick={() => props.handleSelectReservation()}
                >
                    {t('common.inProgress')} <Cancel fontSize='small' />
                </Button>
            ) : (
                <Button
                    className='continue-button'
                    variant='contained'
                    size='small'
                    onClick={() => props.handleSelectReservation(reservation)}
                >
                    <AddCircle fontSize='small' /> {t('common.continueBooking')}
                </Button>
            )

        }
    }

    const getReservations = () => {
        return props.reservations.map(reservation => {
            const isSelected = reservation.number === props.selectedReservation?.number;
            const products = {};
            reservation?.items?.forEach(item => {
                products[item.product.ref_table_detail] = products[item.product.ref_table_detail] ? products[item.product.ref_table_detail] + 1 : 1;
            });
            reservation.products = products;
            const status = reservation.status ;

            return <Grid className={`reservation ${isSelected ? 'selected' : ''}`} key={reservation.id}>
                <div className='row reservation-title' style={{ justifyContent: 'space-between' }}>
                    <Typography className='reservation-number'>{t('common.reservation')} {reservation.number}</Typography>
                    <Typography className={`status ${status.toLowerCase()}`}>{t(`common.${status.toLowerCase()}`)}</Typography>
                </div>
                <div className='row' style={{ justifyContent: 'space-between' }}>
                    <Typography className='title'>{t('common.products')}:</Typography>
                    <div>
                        {Object.entries(products).map(([key, value], i) => (
                            <Badge
                                badgeContent={value > 1 ? value : 0}
                                overlap='circular'
                                key={i}
                            >
                                {props.getIcon(key)}
                            </Badge>
                        ))}
                    </div>
                </div>
                <div className='row'>
                    <Typography className='title'>{t('common.passenger')}:</Typography>
                    <Typography>{reservation.passenger}</Typography>
                </div>
                <div className='row' style={{ justifyContent: 'space-between' }}>
                    <Typography className='title'>{t('common.date')}:</Typography>
                    <Typography>{reservation.date_in ? dayjs(reservation.date_in).format('DD-MM-YYYY') : ''}</Typography>
                </div>

                {getContinueReservation(reservation, isSelected)}
            </Grid>
        });
    }

    const getFavoritesIcon = () => {
        const currentModule = props.modules?.find(elem => location.pathname.includes(elem.path));
        const hotelsModuleExists  = (props?.modules && Array.isArray(props?.modules) && props?.modules?.find(elem => elem.module === 'hotels'));
        const showFavorite = !props.client?.header.showFavorites || props.client?.header.showFavorites?.[currentModule?.module];
        if (hotelsModuleExists && showFavorite) {
            const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
            return <Link to={iframePath + '/hoteles/favoritos'} color="inherit">
                <IconButton>
                    <Icon className='icon-favorite'>favorite</Icon>
                </IconButton>
            </Link>
        }
    }

    const getCurrencies = () => {
        const currencies = props.getCurrencies();
        if (currencies) {
            return <Grid item xs={12}>
                <ClickAwayListener onClickAway={handleClose}>
                    <>
                        <Typography variant="body1" color="inherit" component="span">
                            <strong>{t('common.exchangeTypeTtle')}:</strong> {props.getCurrencies()}
                            {!props.client?.header?.exchangeRates?.hideCurrenciesInfo && (
                                <IconButton color="inherit" size="small" onClick={handleToggle}>
                                    <Icon>expand_more</Icon>
                                </IconButton>
                            )}
                        </Typography>

                        <Collapse in={open}>{t('common.exchangeTypeDescription')}</Collapse>
                    </>
                </ClickAwayListener>
            </Grid>
        }
    }

    const getMissingMessage = () => {
        if (props.loadingReservation) {
            return <CircularProgress style={{ margin: '0 auto 15px' }} />;
        } else if (
            (!props.reservation || (Array.isArray(props.reservation) && props.reservation.length === 0)) &&
            (!props.reservations || (Array.isArray(props.reservations) && props.reservations.length === 0))
        ) {
            return (
                <span style={{ margin: '0 auto 15px' }}>Reserva no encontrada</span>
            );
        }

        return null;
    };

    return (
        <AppBar position="static" className="site-header-logged">
            <Toolbar>
                <Grid container columnSpacing={2} alignItems="center">
                    {getCurrencies()}

                    <Grid item xs={7} alignItems="center" display="flex" justifyContent="flex-start">
                        <Typography variant="body1" color="inherit" component="span">
                            {t('common.hello')},&nbsp;<strong>{props.user.name} {props.user.surname}</strong>
                        </Typography>
                    </Grid>

                    <Grid item xs={5} display="flex" justifyContent="flex-end">
                        {getFavoritesIcon()}
                        <IconButton color="inherit" onClick={props.handleOpenReservations}>
                            <Icon>receipt</Icon>
                        </IconButton>
                        <IconButton color="inherit" onClick={props.handleClickLogIn}>
                            <Icon>logout</Icon>
                        </IconButton>
                    </Grid>

                    <Popper open={props.openReservations} anchorEl={props.anchorEl} placement='bottom-start' transition style={{ zIndex: 20 }}>
                        {({ TransitionProps }) => (
                            <ClickAwayListener onClickAway={props.handleCloseReservations}>
                                <Fade {...TransitionProps} timeout={500}>
                                    <Grid className='reservation-popper'>
                                            <Grid className='row mb-1'>
                                            <TextField
                                            fullWidth
                                            size='small'
                                            placeholder={`${t('common.search')}...`}
                                            value={props.searchInput}
                                            onChange={(event) => {
                                                props.handleChangeSearchInput(event.target.value);
                                            }}
                                            />
                                            <IconButton className='ml-05' onClick={props.handleSearchReservations}>
                                            <Search/>
                                            </IconButton>
                                            </Grid>
                                            {getMissingMessage()}
                                        {getReservations()}
                                        <Button variant='outlined' className='more-button' fullWidth>{t('common.seeMoreBookings')}</Button>
                                    </Grid>
                                </Fade>
                            </ClickAwayListener>
                        )}
                    </Popper>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

const AppBarTop = ({
    user,
    reservations,
    client,
    payment,
    currentProduct,
    selectedReservation,
    modules,
    loadingReservation,
    searchInput,
    getCurrencies,
    handleClickLogIn,
    handleSelectReservation,
    handleChangeSearchInput,
    handleSearchReservations

}) => {
    const [anchorEl, setAnchorEl] = useState();
    const [openReservations, setOpenReservation] = useState(false);

    const handleOpenReservations = (event) => {
        if (!anchorEl) {
            setOpenReservation(true);
            setAnchorEl(event.currentTarget);
        }
    }

    const handleCloseReservations = () => {
        setOpenReservation(false);
        setAnchorEl(null);
    }

    const getIcon = (product) => {
        if (modules && Array.isArray(modules)) {
            let item = '';

            if (product === 'hotel_detail') {
                item = modules?.find(elem => elem.module === 'hotels');
            } else if (product === 'car_detail') {
                item = modules?.find(elem => elem.module === 'renting_carz');
            } else if (product === 'air_detail') {
                item = modules?.find(elem => elem.module === 'flights');
            } else if (product === 'assistance_detail') {
                item = modules?.find(elem => elem.module === 'assists');
            } else if (product.includes('train')) {
                item = modules?.find(elem => elem.module === 'trains');
            } else if (product === 'transfer_detail') {
                item = modules?.find(elem => elem.module === 'transfers');
            } else if (product === 'other_detail') {
                return <Category />;
            }

            return item
                ? <IconCustom icon={item.icon} alt={item?.moduleName} className="custom-icon" />
                : null;
        }
    }

    const handleClickMoreReservations = () => {
        let adminUrl = 'https://admin-b2.e2etravelsolutions.com/#/reservation';
        const token = localStorage.getItem('jwt');
        if (token) {
            adminUrl += `?token=${token}`;
        }
        window.open(adminUrl);
        // const businessUnitId = JSON.parse(localStorage.getItem('user') || '{}')?.business_unit_id;
        // if (businessUnitId) {
        //     let adminUrl = 'https://admin-b2.e2etravelsolutions.com/#/reservation?';  // PROD
        //     // let adminUrl = 'https://dev-b2-admin.dev001.api-services-group.com/#/reservation?';  // DEV
        //     const params = {
        //         displayedFilters: {
        //             business_unit_id__in: true,
        //             status__in: true
        //         },
        //         filter: {
        //             business_unit_id__in: [businessUnitId],
        //             status__in: ['OPEN']
        //         },
        //         order: 'DESC',
        //         page: '1',
        //         perPage: '10',
        //         sort: 'id',
        //     };
        //     adminUrl += Object.entries(params).map(([key, value]) => {
        //         const valueAux = typeof value === 'object' ? JSON.stringify(value) : value;
        //         return `${key}=${encodeURIComponent(valueAux)}`;
        //     }).join('&');
        //     window.open(adminUrl, '__blank');
        // }
    }

    return (
        <>
            <Hidden smDown>
                <DekstopAppBarTop
                    user={user}
                    client={client}
                    payment={payment}
                    currentProduct={currentProduct}
                    reservations={reservations}
                    selectedReservation={selectedReservation}
                    modules={modules}
                    anchorEl={anchorEl}
                    openReservations={openReservations}
                    searchInput={searchInput}
                    loadingReservation={loadingReservation}
                    handleClickLogIn={handleClickLogIn}
                    getIcon={getIcon}
                    getCurrencies={getCurrencies}
                    handleSelectReservation={handleSelectReservation}
                    handleOpenReservations={handleOpenReservations}
                    handleCloseReservations={handleCloseReservations}
                    handleClickMoreReservations={handleClickMoreReservations}
                    handleChangeSearchInput={handleChangeSearchInput}
                    handleSearchReservations={handleSearchReservations}
                />
            </Hidden>
            <Hidden smUp>
                <MobileAppBarTop
                    user={user}
                    client={client}
                    reservations={reservations}
                    selectedReservation={selectedReservation}
                    modules={modules}
                    anchorEl={anchorEl}
                    openReservations={openReservations}
                    searchInput={searchInput}
                    loadingReservation={loadingReservation}
                    handleClickLogIn={handleClickLogIn}
                    getIcon={getIcon}
                    getCurrencies={getCurrencies}
                    handleSelectReservation={handleSelectReservation}
                    handleOpenReservations={handleOpenReservations}
                    handleCloseReservations={handleCloseReservations}
                    handleClickMoreReservations={handleClickMoreReservations}
                    handleChangeSearchInput={handleChangeSearchInput}
                    handleSearchReservations={handleSearchReservations}
                />
            </Hidden>
        </>
    )
}

function Header({
    user,
    client,
    reservations,
    selectedReservation,
    isIframe,
    landing,
    handleSelectReservation,
    setReservations,
    responseReservations,
    responseReservation,
    responseCurrencies,
    ...props
}) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const { showModules, setShowModules } = useLayoutContext();
    const { payment, currentProduct, setClientData } = useClientData();
    const { bannersData } = useBannersData();
    const [showButtonModules, setShowButtonMenu] = useState(true);
    const [abortControllerReservation, setAbortControllerReservation] = useState();
    const [ searchInput, setSearchInput] = useState('');
    const [ loadingReservation, setLoadingReservation] = useState(false);

    useEffect(() => {
        if (user) {
            const accessToken = localStorage.getItem('jwt');
            const businessUnitId = JSON.parse(localStorage.getItem('businessUnit') || '{}')?.id;
            const selectedReservationAux = localStorage.getItem('selectedReservation');
            handleSelectReservation(selectedReservationAux ? JSON.parse(selectedReservationAux) : null);

            if (accessToken && businessUnitId) {
                const query = `?skip=0&limit=3&order_by=-id&status__in=OPEN&business_unit_id__in=${businessUnitId}`;
                props.getReservations(accessToken, query);
                setLoadingReservation(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        setReservations(responseReservations || []);

        if (responseReservations !== null) {
            setLoadingReservation(false);

            if (selectedReservation) {
                if (!responseReservations.find(elem => elem.number === selectedReservation?.number)) {
                    handleSelectReservation(null);
                    localStorage.removeItem('selectedReservation');
                } else {
                    const accessToken = localStorage.getItem('jwt');
                    if (accessToken) {
                        abortControllerReservation && abortControllerReservation.abort();
                        const controller = new AbortController();
                        setAbortControllerReservation(controller);
                        props.getReservation(accessToken, selectedReservation.id, controller.signal);
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseReservations]);

    useEffect(() => {
        if (responseReservation !== null) {
            if (Object.keys(responseReservation).length > 0) {
                localStorage.setItem('selectedReservation', JSON.stringify(responseReservation));
                handleSelectReservation(responseReservation);
            } else {
                localStorage.removeItem('selectedReservation');
                handleSelectReservation(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseReservation]);

    useEffect(() => {
        setShowButtonMenu(
            !location.pathname.includes('login') &&
            !location.pathname.includes('resultados') &&
            !location.pathname.includes('favoritos') &&
            (!landing || landing.form_visible)
        );
    }, [location, landing]);

    const handleClickLogIn = () => {
        setClientData(null);
        localStorage.clear();

        const registerLink = client?.client?.register?.link;
        if (isLoginPage() && registerLink) {
            window.location.href = registerLink;
        } else {
            navigate('/login');
        }
    }

    const handleClickMenu = () => {
        setShowModules(!showModules);
    }

    const handleSelectReservationAux = (reservation) => {
        handleSelectReservation(reservation);
        if (reservation?.id) {
            const accessToken = localStorage.getItem('jwt');
            if (accessToken) {
                abortControllerReservation && abortControllerReservation.abort();
                const controller = new AbortController();
                setAbortControllerReservation(controller);
                localStorage.setItem('selectedReservation', JSON.stringify(reservation));
                props.getReservation(accessToken, reservation.id, controller.signal);
            }
        } else {
            props.resetReservation();
            localStorage.removeItem('selectedReservation');
            abortControllerReservation && abortControllerReservation.abort();
        }
    }

    const getCurrencies = () => {
        if (client.header?.showCurrencies || client.header?.exchangeRates?.showExchangeRates) {
            const currencyTo = currentProduct?.config?.CURRENCY_TO || 'ARS';
           let filteredCurrencies = currentProduct?.currencies?.filter(elem => (
                elem.currency_to === currencyTo
            )) || [];
            if (client?.header?.exchangeRates?.currenciesToShow && !client?.header?.exchangeRates?.currenciesToShow?.find(elem => elem === 'all')) {
                filteredCurrencies = filteredCurrencies.filter(elem => (
                    client.header?.exchangeRates?.currenciesToShow?.includes(elem.currency)
                ));
            }
            if (filteredCurrencies.length > 0) {
                const sortedCurrencies = filteredCurrencies.sort((a, b) => a.currency === 'USD' ? -1 : 1);
                const currenciesText = sortedCurrencies.map(elem => (
                    `${elem.currency} 1 = ${currencyTo} ${elem.changeAmount.replace(` ${elem.currency_to}`, '')}`
                ));

                if (client.header?.exchangeRates?.showOnlyCurrencies) {
                    return currenciesText.join(' ');
                } else {
                    return `Terrestre ${currenciesText.join(' ')}`;
                }
            }

            return '';
        }
    }

    const getLogo = () => {
        const businessUnit = JSON.parse(localStorage.getItem('businessUnit') || '{}');
        const workUnit = JSON.parse(localStorage.getItem('workUnit') || '{}');
        const bannerLogo = bannersData?.data?.modules?.['otros']?.flows?.header?.banners?.Logo?.slice(-1)?.[0]?.image_url
            || bannersData?.data?.modules?.['otros']?.flows?.login?.banners?.Logo?.slice(-1)?.[0]?.image_url;
        const logo = bannerLogo || businessUnit?.logo?.src || workUnit?.logo?.src;
        if (logo) {
            const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
            const link = iframePath + (client?.modules?.[0]?.path || '/');
            return <Link to={link}>
                <Image img={logo} alt='logo-cliente' isFullPath />
            </Link>
        }
    }

    const isLoginPage = () => {
        return location?.pathname?.includes('login') && client?.client?.register?.link;
    }

    const handleChangeSearchInput = (value) => {
        setSearchInput(value);
    }

    const handleSearchReservations = () => {
        if (user) {
            const accessToken = localStorage.getItem('jwt');
            const businessUnitId = JSON.parse(localStorage.getItem('businessUnit') || '{}')?.id;

            if (accessToken && businessUnitId) {
                const selectedReservationAux = localStorage.getItem('selectedReservation');
                handleSelectReservation(selectedReservationAux ? JSON.parse(selectedReservationAux) : null);
                const query = `?skip=0&limit=3&order_by=-id&business_unit_id__in=${businessUnitId}&number__ilike_in=${searchInput}`;
                props.getReservations(accessToken, query);
                setLoadingReservation(true);
            }
        }
    }

    //console.log('Header', props, client)

    return (
        <div className={`header-${client.client.name}`}>
            {user && !isIframe && (
                <AppBarTop
                    user={user}
                    client={client}
                    payment={payment}
                    currentProduct={currentProduct}
                    reservations={reservations}
                    selectedReservation={selectedReservation}
                    modules={client?.modules}
                    searchInput={searchInput}
                    loadingReservation={loadingReservation}
                    getCurrencies={getCurrencies}
                    handleClickLogIn={handleClickLogIn}
                    handleSelectReservation={handleSelectReservationAux}
                    handleChangeSearchInput={handleChangeSearchInput}
                    handleSearchReservations={handleSearchReservations}
                />
            )}

            <AppBar position="static" className="site-header">
                <Toolbar>
                    {getLogo()}

                    <Hidden mdDown>
                        <MenuList
                            user={user}
                            client={client}
                            showMenu={!landing || landing.menu_visible}
                            showButtonModules={showButtonModules && client?.modules?.length > 1}
                            isLoginPage={isLoginPage()}
                            handleClickMenu={handleClickMenu}
                            handleClickLogIn={handleClickLogIn}
                        />
                    </Hidden>

                    <Hidden mdUp>
                        <MobileMenuList
                            user={user}
                            client={client}
                            isLoginPage={isLoginPage()}
                            handleClickLogIn={handleClickLogIn}
                        />
                    </Hidden>
                </Toolbar>
            </AppBar>
        </div>
    );
}

const mapStateToProps = reducers => {
    return reducers.reservationReducer;
};

const mapDispatchToProps = dispatch => {
    return {
        getReservations: (access, query) => dispatch(getReservationsAction(access, query)),
        resetReservation: () => dispatch(resetReservationAction()),
        getReservation: (access, reservationId, signalAbort) => dispatch(getReservationAction(access, reservationId, signalAbort)),
        getCurrencies: (access, productToken) => dispatch(getCurrenciesAction(access, productToken))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
