import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getCredentials } from './util';

function* getQrCodePixAction(actions) {
  const headers = {
    'Content-Type': 'application/json',
    'token': actions.tokenCheckout,
    'authentication-token': actions.access
  };

  const requestOptions = {
    headers: headers
  };

  try {
    const credentials = getCredentials();
    const response = yield call(axios.post, `${credentials.urlCheckout}payment/pix/create`, actions.data, requestOptions);
    yield put({ type: 'GET_QR_CODE_PIX_SUCCESS', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_QR_CODE_PIX_ERROR', payload: error });
  }
}

function* getPaymentStatusPixAction(actions) {
  const headers = {
    'Content-Type': 'application/json',
    'token': actions.tokenCheckout,
    'authentication-token': actions.access
  };

  const requestOptions = {
    headers: headers
  };

  try {
    const credentials = getCredentials();
    const response = yield call(axios.post, `${credentials.urlCheckout}payment/pix/get_info`, actions.data, requestOptions);
    yield put({ type: 'GET_PAYMENT_STATUS_PIX_SUCCESS', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_PAYMENT_STATUS_PIX_ERROR', payload: error });
  }
}

export function* pixSaga() {
  yield takeEvery('GET_QR_CODE_PIX', getQrCodePixAction);
  yield takeEvery('GET_PAYMENT_STATUS_PIX', getPaymentStatusPixAction);
}