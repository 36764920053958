import React, { useEffect, useRef, useState } from 'react';
import { Box, TextField, Button, InputAdornment, FormLabel, Select, MenuItem, Typography, Autocomplete, Grid, Divider, Tooltip, Icon, FormControlLabel, Switch, IconButton, Hidden } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Person from '@mui/icons-material/Person';
import DateRangeIcon from '@mui/icons-material/DateRange';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import dayjs from 'dayjs';
import { Popover } from '@mui/material';
import { DesktopDatePicker, PickersDay } from '@mui/x-date-pickers';
import { getAutocompleteGimmonix } from '../../../store/services/Autocomplete';
import { Clear, Hotel, Info } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useClientData } from '../../../context/ClientContext';
import { getCountry } from '../../../store/services/InfoFormServices';

const DATE_FORMAT = 'DD/MM/YYYY';

const MultibuscadorGimmonix = ({ defaultData, defaultDestination, selectedReservation, ...props }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const placesInputRef = useRef();
    const { setClientData } = useClientData();
    const [formData, setFormData] = useState({
        destination: { label: '' },
        checkin: null,
        checkout: null,
        nights: '',
        guests: {
            rooms: 1, // Número inicial de habitaciones
            roomInfo: [
                // Información sobre cada habitación
                {
                    adults: 2, // Número inicial de adultos por habitación
                    minors: 0, // Número inicial de menores por habitación
                    minorAges: []
                },
            ],
        },
    });
    const [openCheckin, setOpenCheckin] = useState(false);
    const [openCheckout, setOpenCheckout] = useState(false);
    const [anchorEl, setAnchorEl] = useState();
    const [dayHover, setDayHover] = useState();
    const [timeoutAutocomplete, setTimeoutAutocomplete] = useState();
    const [abortController, setAbortController] = useState();
    const [loadingDestinations, setLoadingDestinations] = useState(false);
    const [destinations, setDestinations] = useState([]);
    const [selectedDestination, setSelectedDestination] = useState();
    const [selectedPlace, setSelectedPlace] = useState();
    const [errors, setErrors] = useState({});
    const [isPlacesSwitchSelected, setIsPlacesSwitchSelected] = useState(false);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        const countries = getCountry();
        const businessUnit = JSON.parse(localStorage.getItem('businessUnit') || '{}')?.country;
        const nationality = countries.find(elem => elem.code_2 === businessUnit?.short_name || elem.description === businessUnit?.long_name)?.code || '';
        setFormData(current => ({
            ...current,
            nationality
        }))
        const autocomplete = new window.google.maps.places.Autocomplete(
            placesInputRef.current.querySelector('input'),
        { fields: ['formatted_address', 'address_components', 'geometry'] }
        );

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            handlePlaceSelect(place)
          });

        return () => {
            window.google.maps.event.clearInstanceListeners(autocomplete);
        };
    }, [])

    useEffect(() => {
        if (defaultData) {
            setFormData(defaultData);
            setIsPlacesSwitchSelected(defaultData.pointInterest);
            if (defaultData.pointInterest) {
                setSelectedPlace(defaultData.destination);
                setSearchText(defaultData.destination.label);
            } else {
                setSelectedDestination(defaultData.destination);
            }
        }
    }, [defaultData]);

    useEffect(() => {
        if (defaultDestination) {
            setFormData(current => ({
                ...current,
                destination: defaultDestination
            }));
            setSelectedDestination(defaultDestination);
            setOpenCheckin(true);
            setIsPlacesSwitchSelected(false);
            document.getElementById('checkin-input')?.focus({ preventScroll: true });
        }
    }, [defaultDestination]);

    useEffect(() => {
        if (selectedReservation) {
            const hotelProduct = selectedReservation.items?.find(elem => elem.product.ref_table_detail === 'hotel_detail');
            if (hotelProduct && hotelProduct.rooms) {
                const formDataAux = {...formData};
                const roomInfo = hotelProduct.rooms.map(room => {
                    const passengers = room.paxes.map(pax => (
                        selectedReservation.passengers.find(elem => `${elem.name}, ${elem.surname}` === pax.name)
                    )).filter(elem => elem);
                    const minors = passengers.filter(elem => elem.type === 'CHD');
                    return {
                        adults: passengers.filter(elem => elem.type === 'ADT').length,
                        minors: minors.length,
                        minorAges: minors.map(elem => elem.age)
                    }
                });
                formDataAux.guests = {
                    rooms: roomInfo.length,
                    roomInfo
                };
                setFormData(formDataAux);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedReservation]);

    const handlePlaceSelect = (place) => {
        const label = place.address_components
            .filter(elem => !elem.types.includes('administrative_area_level_2'))
            .map(elem => elem.long_name)
            .join(' ');

        const formattedPlace = {
            location: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
                radius: 2000
            },
            label
        };

        setSelectedPlace(formattedPlace);
        setSearchText(place.formatted_address);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const user = localStorage.getItem('user');
        if (user) {
            const form = {
                pointInterest: isPlacesSwitchSelected ? 1 : 0,
                filters: {
                    nombreHotel: null,
                    categoria: null,
                    precioMin: null,
                    precioMax: null,
                    order: "asc"
                },
            };
            const errorsAux = {};
            let errorsCount = 0;

            const destination = isPlacesSwitchSelected ? selectedPlace : selectedDestination;
            if (destination) {
                if (destination.location) {
                    form.code = `${destination.location.lat},${destination.location.lng},${destination.location.radius || 20000}`;
                    form.type = 'geo';
                } else if (destination.gimmonix_id) {
                    form.code = destination.gimmonix_id;
                    form.type = 'hotel';
                }
                form.label = destination.label.replaceAll(',', '').replaceAll(' ', '-').toLowerCase();
            } else {
                errorsCount += 1;
                errorsAux.destination = true;
            }

            if (formData.checkin) {
                form.checkin = dayjs(formData.checkin, DATE_FORMAT).format('DDMMYY');
            } else {
                errorsCount += 1;
                errorsAux.checkin = true;
            }

            if (formData.checkout) {
                form.checkout = dayjs(formData.checkout, DATE_FORMAT).format('DDMMYY');
            } else {
                errorsCount += 1;
                errorsAux.checkout = true;
            }

            if (formData.nationality) {
                form.nationality = formData.nationality;
            } else {
                errorsCount += 1;
                errorsAux.nationality = true;
            }

            form.rooms = formData.guests.roomInfo.map(room => {
                let roomText = `${room.adults}-${room.minors}`;
                if (room.minors > 0) {
                    roomText += `!${room.minorAges.join('-')}`;
                }
                return roomText
            }).join(';');

            setErrors(errorsAux);

            if (errorsCount === 0) {
                const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
                const url = iframePath + `/hoteles/resultados/${form.label}/${form.type}/${form.pointInterest}/${form.code}/${form.checkin}/${form.checkout}/0/0/${form.rooms}/${form.nationality}`;
                navigate(url, { state: { destination } });
            }
        } else {
            const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
            if (iframePath) {
                localStorage.clear();
                setClientData(null);
                navigate(iframePath);
            } else {
                navigate('/login', { state: { expiredSession: true } });
            }
        }
    };

    const handleChangeDestination = (e) => {
        const destination = e.target.value;
        setFormData({ ...formData, destination: { label: destination } });
        if (destination && destination.length > 2) {
            clearTimeout(timeoutAutocomplete)
            const timeout = setTimeout(async () => {
                abortController && abortController.abort();
                const controller = new AbortController();
                setAbortController(controller);
                setLoadingDestinations(true);
                const response = await getAutocompleteGimmonix(destination, controller.signal);
                response?.data?.forEach(data => {
                    data.data = data.data.map(elem => ({
                        ...elem,
                        category: data.category.toLowerCase() === 'ciudades' ? 'cities' : data.category,
                    }))
                })
                setDestinations(response.data?.flatMap(group => group.data) || [])
                setAbortController(null);
                setLoadingDestinations(false);
            }, 500);
            setTimeoutAutocomplete(timeout);
        }
    }

    const handleChangeSelectedDestination = (value) => {
        setSelectedDestination(value);
        setFormData({ ...formData, destination: { label: value?.label || '' } });
        setOpenCheckin(value ? true : false);
    }

    const handleChangePlaceSwitch = (checked) => {
        setIsPlacesSwitchSelected(checked);
        setFormData({ ...formData, destination: { label: '' } });
        setSelectedDestination(null);
        setSelectedPlace(null);
        setSearchText('');
    }

    const handleOpenCheckin = () => {
        setOpenCheckin(true);
        setOpenCheckout(false);
    }

    const handleOpenCheckout = () => {
        setOpenCheckin(false);
        setOpenCheckout(true);
    }

    const handleChangeCheckin = (e) => {
        handleDateChange(e, 'checkin');
        const dateCheckin = dayjs(e.format('L'), DATE_FORMAT);
        const dateCheckout = dayjs(formData.checkout, DATE_FORMAT);
        if (e && formData.checkout && dateCheckout.isSameOrBefore(e, 'date')) {
            handleDateChange('', 'checkout');
        }
        setOpenCheckin(false);
        setOpenCheckout(true);

        // Calcula la diferencia de días entre las fechas
        const nights = dateCheckout.diff(dateCheckin, 'days');

        // Asegúrate de que la cantidad de noches sea mayor o igual a 1
        if (nights >= 1) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                nights: nights.toString(),
            }));
        }
    };

    const handleChangeCheckout = (e) => {
        handleDateChange(e, 'checkout');
        const dateCheckin = dayjs(formData.checkin, DATE_FORMAT);
        const dateCheckout = dayjs(e.format('L'), DATE_FORMAT);

        // Calcula la diferencia de días entre las fechas
        const nights = dateCheckout.diff(dateCheckin, 'days');

        // Asegúrate de que la cantidad de noches sea mayor o igual a 1
        if (nights >= 1) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                nights: nights.toString(),
            }));
        }
    };

    const handleDateChange = (date, field) => {
        const formattedDate = date ? date.format(DATE_FORMAT) : '';
        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: formattedDate,
        }));
        setDayHover(false);
    };

    const handleNightsChange = (newNights) => {
        newNights = Math.max(1, Math.floor(newNights));

        const updatedFormData = {
            ...formData,
            nights: newNights.toString()
        }
        if (!formData.checkin) {
            // Si no se eligió fecha de entrada, comenzar a contar desde hoy
            const dateCheckin = dayjs();
            const dateCheckout = dayjs().add(newNights, "day");
            updatedFormData.checkin = dateCheckin.format(DATE_FORMAT);
            updatedFormData.checkout = dateCheckout.format(DATE_FORMAT);
        } else {
            // Si no se eligió fecha de salida o se eligieron ambas fechas, ajustar la fecha de salida en función de las noches
            const dateCheckin = dayjs(formData.checkin, DATE_FORMAT);
            const dateCheckout = dayjs(dateCheckin).add(newNights, "day");
            updatedFormData.checkout = dateCheckout.format(DATE_FORMAT);
        }

        if (dayjs(updatedFormData.checkout, DATE_FORMAT).isSameOrBefore(dayjs().add(18, 'month'), 'date')) {
            setFormData(updatedFormData);
        }
    };

    const handleClickGuests = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseGuests = () => {
        setAnchorEl(null);
    };

    const handleGuestsChange = (e, field, index, indexMinor) => {
        const newRoomInfo = [...formData.guests.roomInfo];
        const value =  e.target.value;

        if (field === 'minorAges') {
            newRoomInfo[index][field].splice(indexMinor, 1, value)
        } else {
            newRoomInfo[index] = {
                ...newRoomInfo[index],
                [field]: value,
            };

            if (field === 'minors') {
                const minorAges = newRoomInfo[index].minorAges.slice(0, value);

                while (minorAges.length < value) {
                    minorAges.push(0)
                }

                newRoomInfo[index].minorAges = minorAges;
            }
        }

        setFormData({
            ...formData,
            guests: {
                ...formData.guests,
                roomInfo: newRoomInfo,
            },
        });
    };

    const handleRoomsChange = (event) => {
        const numRooms = event.target.value;

        let roomInfo = formData.guests.roomInfo.slice(0, numRooms);
        while (roomInfo.length < numRooms) {
            roomInfo.push({
                adults: 1, // Establecer el número inicial de adultos
                minors: 0, // Establecer el número inicial de menores
                minorAges: []
            })
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            guests: {
                ...prevFormData.guests,
                rooms: numRooms,
                roomInfo
            },
        }));
    };

    const isSelected = (day) => {
        const startDate = formData.checkin && dayjs(formData.checkin, DATE_FORMAT);
        const endDate = formData.checkout && dayjs(formData.checkout, DATE_FORMAT);

        return (startDate ? startDate.isSame(day, 'date') : false)
            || (endDate ? endDate.isSame(day, 'date') : false);
    }

    const isBetween = (day) => {
        if (formData.checkin && formData.checkout) {
            return day.isBetween(dayjs(formData.checkin, DATE_FORMAT), dayjs(formData.checkout, DATE_FORMAT), 'date', '[]');
        }

        return false
    }

    const isDisabled = (day) => {
        let flag = false;

        if (day.isBefore(dayjs(), 'date')) {
            flag = flag || true;
        }
        if (openCheckin) {
            flag = flag || day.isAfter(dayjs().add(18, 'month').subtract(1, 'day'), 'date')
        }
        if (openCheckout) {
            if (formData.checkin) {
                const startDate = dayjs(formData.checkin, DATE_FORMAT);
                flag = flag || day.isSameOrBefore(startDate, 'date');
            }

            flag = flag || day.isAfter(dayjs().add(18, 'month'), 'date')
        }

        return flag;
    }

    const getPickerDay = (day, _value, DayComponentProps) => {
        const date = dayjs(day.$d);
        const isBetweenAux = isBetween(date);
        const isStartDate = formData.checkin === date.format(DATE_FORMAT);
        const isEndDate = formData.checkout === date.format(DATE_FORMAT);

        const classNames = [];
        if (isBetweenAux) classNames.push('range-between');
        if (isStartDate) classNames.push('start-date');
        if (isEndDate) classNames.push('end-date');

        const classNamesDay = [];
        if (!isStartDate && !isEndDate) {
            if (isBetweenAux) {
                classNamesDay.push('between');
            } else if (formData.checkin && dayHover) {
                if (openCheckin && formData.checkout && date.isBetween(dayHover, dayjs(formData.checkin, DATE_FORMAT), 'date', '()')) {
                    classNamesDay.push('preselected-day');
                } else if (openCheckout) {
                    const dateAux = formData.checkout ? dayjs(formData.checkout, DATE_FORMAT) : dayjs(formData.checkin, DATE_FORMAT);
                    formData.checkout && date.isBetween(dayjs(formData.checkout, DATE_FORMAT), dayHover, 'date', '()')
                    if (dateAux && date.isBetween(dateAux, dayHover, 'date', '()')) {
                    classNamesDay.push('preselected-day');
                    }
                }
            }
        }

        return (
            <div className={classNames.join(' ')} key={DayComponentProps.key}>
                <PickersDay
                    {...DayComponentProps}
                    className={classNamesDay.join(' ')}
                    selected={isSelected(date)}
                    disabled={isDisabled(date)}
                    onMouseOver={() => setDayHover(date)}
                    onMouseLeave={() => setDayHover(null)}
                    disableHighlightToday
                />
            </div>
        );
    }

    const getSearchIcon = (category) => {
        if (category.toLowerCase() === 'cities' || category.toLowerCase() === 'hoteles') {
            return 'place';
        } if (category.toLowerCase() === 'aeropuertos') {
            return 'local_airport';
        } if (category.toLowerCase() === 'puertos') {
            return 'directions_boat';
        }
    }

    const handleChangeField = (e) => {
        const { name, value } = e.target;
        const formDataAux = {
            ...formData,
            [name]: value
        }

        setFormData(formDataAux);
    };

    const getSearchByInteresPointField = () =>{
        return <FormControlLabel
            className='places-switch'
            label={t('multiSearch.hotels.searchByInterestPoint')}
            control={
                <Switch
                    size='small'
                    color='secondary'
                    checked={isPlacesSwitchSelected}
                    onChange={(e, checked) => handleChangePlaceSwitch(checked)}
                />
            }
        />
    }

    const getNationalityField = () =>{
        return < Select
            fullWidth
            size='small'
            name='nationality'
            error={errors.nationality}
            value={formData?.['nationality'] || ''}
            onChange={e => handleChangeField(e)}
        >
            {getCountry()?.map((option, i) => (
                <MenuItem key={i} value={option.code}>{option.description}</MenuItem>
            ))}
        </Select>
    }

    return (
        <Box className="search-box-container">
            <form onSubmit={handleSubmit}>
                <Grid container className="search-box" spacing={1} columns={24}>
                    <Grid item xs={24} sm={8} md={7} lg={6} >
                        <FormLabel>{t('multiSearch.hotels.destination')}</FormLabel>
                        <Autocomplete
                            className={isPlacesSwitchSelected ? 'hide-input' : ''}
                            variant="outlined"
                            fullWidth
                            size="small"
                            options={destinations}
                            getOptionLabel={option => option.label}
                            groupBy={(option) => option.category}
                            value={selectedDestination || formData.destination || ''}
                            onChange={(e, value) => handleChangeSelectedDestination(value)}
                            noOptionsText={t('common.noOptions')}
                            loadingText={`${t('common.loading')}...`}
                            loading={loadingDestinations}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    placeholder={t('multiSearch.hotels.selectDestination')}
                                    onChange={(e) => handleChangeDestination(e)}
                                    error={errors.destination}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <SearchIcon color="action" />
                                        ),
                                    }}
                                />
                            }
                            renderGroup={(params) => (
                                <li {...params}>
                                    <span className='group-title'>
                                        <Icon fontSize='small'>{getSearchIcon(params.group)}</Icon> {t(`multiSearch.hotels.${params.group}`)}
                                    </span>
                                    <ul className='group-option'>{params.children}</ul>
                                </li>
                            )}
                        />
                        <TextField
                            ref={placesInputRef}
                            fullWidth
                            size='small'
                            className={isPlacesSwitchSelected ? 'places-input' : 'hide-input places-input'}
                            placeholder={t('multiSearch.hotels.selectDestination')}
                            error={errors.destination}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <SearchIcon color="action" />
                                ),
                                endAdornment: (
                                    <IconButton onClick={() => setSearchText('')} edge="end" size='small'>
                                        <Clear fontSize='small' />
                                    </IconButton>
                                ),
                            }}
                        />
                        <Hidden mdUp> {getSearchByInteresPointField()} </Hidden>
                    </Grid>
                    <Grid item xs={24} sm={5} md={4} lg={4}>
                        <FormLabel>{t('multiSearch.hotels.checkIn')}</FormLabel>
                        <DesktopDatePicker
                            open={openCheckin}
                            onClose={() => setOpenCheckin(false)}
                            onChange={(e) => handleChangeCheckin(e, 'checkin')}
                            closeOnSelect={false}
                            showDaysOutsideCurrentMonth
                            value={formData.checkin ? dayjs(formData.checkin, DATE_FORMAT) : null}
                            renderDay={getPickerDay}
                            className='pointer-input'
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    id='checkin-input'
                                    focused={false}
                                    placeholder={DATE_FORMAT}
                                    value={formData.checkin ? formData.checkin : ''}
                                    inputProps={{ readOnly: true }}
                                    size='small'
                                    fullWidth
                                    error={errors.checkin}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <DateRangeIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                    onClick={handleOpenCheckin}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={24} sm={5} md={4} lg={4}>
                        <FormLabel>{t('multiSearch.hotels.checkOut')}</FormLabel>
                        <DesktopDatePicker
                            open={openCheckout}
                            onClose={() => setOpenCheckout(false)}
                            onChange={(e) => handleChangeCheckout(e, 'checkout')}
                            showDaysOutsideCurrentMonth
                            value={formData.checkout ? dayjs(formData.checkout, DATE_FORMAT) : formData.checkin ? dayjs(formData.checkin, DATE_FORMAT) : null}
                            renderDay={getPickerDay}
                            className='pointer-input'
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    focused={false}
                                    placeholder={DATE_FORMAT}
                                    value={formData.checkout ? formData.checkout : ''}
                                    inputProps={{ readOnly: true }}
                                    size='small'
                                    fullWidth
                                    error={errors.checkout}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <DateRangeIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                    onClick={handleOpenCheckout}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={24} sm={4} md={3} lg={3} >
                        <FormLabel>{t('multiSearch.hotels.nights')}</FormLabel>
                        <TextField
                            type='number'
                            variant="outlined"
                            placeholder={t('multiSearch.hotels.nights')}
                            value={formData.nights}
                            fullWidth
                            size="small"
                            onChange={(e) => handleNightsChange(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <NightsStayIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={24} sm={12} md={4} lg={5}>
                        <FormLabel>{t('multiSearch.hotels.guests')}</FormLabel>
                        <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            onClick={handleClickGuests}
                            className='pointer-input'
                            value={`${formData.guests.roomInfo.reduce(
                                (totalAdults, room) => totalAdults + room.adults,
                                0
                            )} ${t('multiSearch.hotels.adults')}, ${formData.guests.roomInfo.reduce(
                                (totalMinors, room) => totalMinors + room.minors,
                                0
                            )} ${t('multiSearch.hotels.minors')}, ${formData.guests.rooms} ${t('multiSearch.hotels.roomsShort')}`}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Person />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {Boolean(anchorEl) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                    </InputAdornment>
                                ),
                                readOnly: true
                            }}
                        />
                        <Popover
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handleCloseGuests}
                            className='rooms-popover'
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <Grid p={2}>
                                <Grid className='rooms-input'>
                                    <Typography variant="subtitle1">{t('multiSearch.hotels.rooms')}</Typography>
                                    <Select
                                        variant="outlined"
                                        size="small"
                                        value={formData.guests.rooms}
                                        onChange={handleRoomsChange}
                                    >
                                        {Array.from({ length: 4 }, (_, index) => (
                                            <MenuItem key={index + 1} value={index + 1}>
                                                {index + 1}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>

                                {Array.from({ length: formData.guests.rooms }, (_, index) => (
                                    <Grid key={index} className='room-info'>
                                        <Typography component='div' className='subtitle'>
                                            <Hotel fontSize='small' />{`${t('multiSearch.hotels.room')} ${index + 1}`}
                                        </Typography>
                                        <Grid display='flex' justifyContent='space-between' alignItems='center'>
                                            <FormLabel>{t('multiSearch.hotels.adults')}</FormLabel>
                                            <Select
                                                variant="outlined"
                                                size="small"
                                                value={formData.guests.roomInfo[index]?.adults || 1}
                                                onChange={(e) => handleGuestsChange(e, 'adults', index)}
                                                style={{ marginRight: '10px' }}
                                            >
                                                {Array.from({ length: 8 }, (_, index) => (
                                                    <MenuItem key={index + 1} value={index + 1}>
                                                        {index + 1}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormLabel>{t('multiSearch.hotels.minors')}</FormLabel>
                                            <Select
                                                variant="outlined"
                                                size="small"
                                                value={formData.guests.roomInfo[index]?.minors}
                                                onChange={(e) => handleGuestsChange(e, 'minors', index)}
                                            >
                                                {Array.from({ length: 7 }, (_, index) => (
                                                    <MenuItem key={index + 1} value={index}>
                                                        {index}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        {formData.guests.roomInfo[index].minors > 0 && (
                                            <Grid className='ages-container'>
                                                <Divider />
                                                <Grid className='row'>
                                                    <Typography variant="subtitle1">{t('multiSearch.hotels.ages')}</Typography>
                                                    <Tooltip title={t('multiSearch.hotels.agesTooltip')} placement='top'>
                                                        <Info fontSize='small' />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid className='ages-inputs'>
                                                    {Array.from({ length: formData.guests.roomInfo[index].minors }, (_, indexMinor) => (
                                                        <Select
                                                            key={indexMinor}
                                                            variant="outlined"
                                                            size="small"
                                                            value={formData.guests.roomInfo[index]?.minorAges[indexMinor]}
                                                            onChange={(e) => handleGuestsChange(e, 'minorAges', index, indexMinor)}
                                                        >
                                                            {Array.from({ length: 18 }, (_, ageIndex) => (
                                                                <MenuItem key={ageIndex} value={ageIndex}>
                                                                    {ageIndex}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                ))}
                                <Grid container justifyContent="flex-end">
                                    <Button size='small' variant="contained" color="secondary" onClick={handleCloseGuests} >
                                        {t('multiSearch.hotels.apply')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Popover>
                    </Grid>
                    <Hidden mdUp>
                        <Grid item xs={24} sm={8} md={4} lg={4} >
                        <FormLabel>{t('multiSearch.hotels.nationality')}</FormLabel>
                        {getNationalityField()}
                        </Grid>
                     </Hidden>
                    <Grid item xs={24} sm={2} md={2} lg={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            type='submit'
                            className='submit-button'
                            fullWidth
                        >
                            {t('common.search')}
                        </Button>
                    </Grid>
                    <Hidden mdDown>
                        <Grid item xs={24} sm={8} md={7} lg={6} >
                        {getSearchByInteresPointField()}
                        </Grid>
                     </Hidden>
                     <Hidden mdDown>
                        <Grid item xs={24} sm={4} md={4} lg={4} >
                            <FormLabel>{t('multiSearch.hotels.nationality')}</FormLabel>
                            {getNationalityField()}
                        </Grid>
                    </Hidden>
                </Grid>
            </form>
        </Box>
    );
};

export default MultibuscadorGimmonix;
