import React, { useEffect, useState, Fragment }  from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Header from '../../layoutCheckout/Header';
import Footer from '../../layoutCheckout/Footer';
import imgError from '../../../assets/images/img-error.svg';
import { Button, Container, Grid, Hidden, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useClientData } from '../../../context/ClientContext';

function Error404({ client, handleSelectReservation, ...props }) {
  const { t } = useTranslation();
  const { clientData } = useClientData();
  const params = useParams();
  const location = useLocation();
  const [user, setUser] = useState();
  const messages = [
    {
      position: 'titleError',
      message: [{
        type: 'p',
        value: t('checkout.error.title')
      }]
    },
    {
      position: 'descriptionError',
      message: [{
        type: 'p',
        value: t('checkout.error.description')
      }]
    }
  ]

  useEffect(() => {
    const user = localStorage.getItem('user');
    setUser(user ? JSON.parse(user) : null);
  }, [location]);

  const getRedirectUrl = () => {
    const module = clientData?.modules && Array.isArray(clientData?.modules) && clientData?.modules?.filter(elem => elem.module === params.module)?.slice(-1)?.[0];
    return module?.path || '/';
  }

  return (
    <div className={`wrapper checkout site-${client.client.name}`}>
      <Header
        client={client}
        user={user}
        isIframe={props.isIframe}
        reservations={props.reservations}
        setReservations={props.setReservations}
        handleSelectReservation={handleSelectReservation}
      />

      <div className='error-page'>
        <Container>
          <Grid container spacing={2}>
            <Grid item sm={7}>
              {messages.map((item, i) => (
                <Fragment key={i}>
                  {item.position === 'titleError' &&
                    item.message.map((message, j) => (
                      <div className='header-step' key={j}>
                        <Typography variant='h1' component='h2' gutterBottom className='title-step'>
                          {message.value}
                        </Typography>
                      </div>
                    ))
                  }
                </Fragment>
              ))}
              <Hidden smUp>
                <img className='img-error' src={imgError} alt='Página no encontrada' />
              </Hidden>

              {messages.map((item, i) => <Fragment key={i}>
                {item.position === 'descriptionError' &&
                  item.message.map((message, j) => (
                    <Typography key={j} className='description'>{message.value}</Typography>
                  ))
                }
              </Fragment>)}
              <Button href={getRedirectUrl()} color='primary' variant='contained'>
                {t('checkout.error.returnHome')}
              </Button>
            </Grid>
            <Hidden xsDown>
              <Grid item sm={5}>
                <img className='img-error' src={imgError} alt='Página no encontrada' />
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </div>

      <Footer client={client} />
    </div>
  );
}

export default Error404;
