import React, { useState } from 'react';
import { Button, Grid, Hidden, Rating, Skeleton, Typography } from '@mui/material';
import ImagesSlider from '../../../common/ImagesSlider';
import { AttachMoney, CalendarToday, Check, GpsFixed, LocationOn } from '@mui/icons-material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

function DetailsView({
  selectedHotel,
  checkin,
  checkout,
  isFavoritePage,
  availability,
  loadingAvailability,
  changeView
}) {
  const { t } = useTranslation();
  const [currentImage, setCurrentImage] = useState(0);

  const getImagesSlider = (isMobile) => {
    return <ImagesSlider
      images={selectedHotel?.images || []}
      infinite={false}
      showDots={false}
      height={isMobile ? 250 : 350}
      currentIndex={currentImage}
      showLoader={false}
      isModal
      onChange={index => setCurrentImage(index)}
    />
  }

  const getPreviewImages = () => {
    if (selectedHotel) {
      if (currentImage === 0) {
        return selectedHotel.images.slice(0, 4)
          .map((image, index) => ({ image, index }));
      } else if (currentImage === selectedHotel.images.length - 1) {
        return selectedHotel.images.slice(selectedHotel.images.length - 4, selectedHotel.images.length)
          .map((image, index) => ({ image, index: (selectedHotel.images.length - 4 + index) }));
      } else {
        return selectedHotel.images.slice(currentImage - 1, currentImage + 3)
          .map((image, index) => ({ image, index: (currentImage - 1 + index) }));
      }
    }

    return [];
  }

  const getImagesContainer = () => {
    return <>
      <Hidden mdDown>{getImagesSlider(false)}</Hidden>
      <Hidden mdUp>{getImagesSlider(true)}</Hidden>
      <Hidden smDown>
        <Grid container className='preview-container' columnSpacing={2}>
          {getPreviewImages().map(elem => (
            <Grid item sm={3} key={elem.index}>
              <img src={elem.image} key={elem.index} alt={`Imagen ${elem.index}`} onClick={() => setCurrentImage(elem.index)} />
            </Grid>
          ))}
        </Grid>
      </Hidden>
    </>
  }

  const getButtons = () => {
    return <>
      <Button variant='contained' color='primary' onClick={() => changeView('map')}>
        <GpsFixed fontSize='small' />{t('results.hotels.seeMap')}
      </Button>
      {!isFavoritePage && <Button variant='contained' color='primary' onClick={() => changeView('room')}>
        <AttachMoney fontSize='small' />{t('results.hotels.seeRates')}
      </Button>}
    </>
  }

  const getDescription = () => {
    if (loadingAvailability) {
      return Array.from(Array(8).keys()).map(elem => (
        <Skeleton width='100%' height='30px' key={elem} />
      ));
    } else {
      const description = availability?.details?.description || selectedHotel?.description;
      if (description) {
        return description?.map((elem, i) => (
          <p key={i} style={{ marginBottom: 10, marginTop: 30 }}>{elem.paragraph}</p>
        ));
      }
    }
  }

  return (
    <>
      <Grid container spacing={3}>
        <Hidden lgDown>
          <Grid item lg={5}>
            {getImagesContainer()}
          </Grid>
        </Hidden>

        <Grid item xs={12} lg={7}>
          <Grid container spacing={2} columns={24}>
            <Hidden mdUp>
              <Grid item xs={24} className='buttons-container'>
                {getButtons()}
              </Grid>
            </Hidden>

            <Grid item xs={24} md={14} className='column hotel-info'>
              <Typography className='title'>{selectedHotel?.name}</Typography>
              <Grid className='bottom-info'>
                <Hidden smUp>
                  <Rating value={parseInt(selectedHotel?.stars || 0)} readOnly />
                </Hidden>
                <Grid className='row'>
                  <Grid className='row address'>
                    <LocationOn fontSize='small' /><span>{selectedHotel?.address}</span>
                  </Grid>
                  <Hidden smDown>
                    <Rating value={parseInt(selectedHotel?.stars || 0)} readOnly />
                  </Hidden>
                </Grid>
                <Grid className='row date'>
                  <CalendarToday fontSize='small' />
                  <span>{dayjs(checkin, 'DD/MM/YYYY').format('DD MMM YY')} - {dayjs(checkout, 'DD/MM/YYYY').format('DD MMM YY')}</span>
                </Grid>
              </Grid>
            </Grid>

            <Hidden mdDown>
              <Grid item md={10} className='buttons-container'>
                {getButtons()}
              </Grid>
            </Hidden>
          </Grid>

          <Hidden lgUp>
            <Grid container className='mt-2'>
              <Grid item xs={12}>
                {getImagesContainer()}
              </Grid>
            </Grid>
          </Hidden>

          <Grid className='amenities-container'>
            <Typography className='title' gutterBottom>
              {t('results.hotels.amenities')}
            </Typography>
            <Grid container>
              {selectedHotel?.amenities.map(amenity => (
                <Grid item key={amenity.code} xs={12} sm={6} md={4} className='row'>
                  <Check fontSize='small' />
                  {amenity.name}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid className='description-container' item xs={12}>
          {getDescription()}
        </Grid>
      </Grid>
    </>
  );
}

export default DetailsView;
